import React, { useEffect } from 'react';
import { useAuthenticationContext } from '@xylexa/xylexa-app';

import { Login } from './components/Login';
import mixpanel from 'mixpanel-browser';

import App from './App';

mixpanel.init(process.env.MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: false,
  persistence: 'localStorage',
  autocapture: false,
});

export const XylexaLoginPage = props => {
  const { currentServerConfigs } = useAuthenticationContext();

  const config = {
    ...window.config,
    dataSources: [
      {
        ...window.config?.dataSources?.[0],
        configuration: currentServerConfigs,
      },
    ],
  };

  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      'clarity',
      'script',
      process.env.MS_CLARITY_PROJECT_ID ? process.env.MS_CLARITY_PROJECT_ID : 'p937irhft2'
    );
  }, []);

  return currentServerConfigs ? (
    <App
      config={config}
      defaultExtensions={props.defaultExtensions}
      defaultModes={props.defaultModes}
    />
  ) : (
    <Login />
  );
};
