import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';

import { useUpsertAnnotationData, annotationService } from '@xylexa/xylexa-app';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import {
  CreateReportModal,
  REPORT_IDS_KEY,
  useAuthenticationContext,
  useXylexaAppContext,
  UserInfo,
  useToast,
} from '@xylexa/xylexa-app';
import { useSearchParams } from '@ohif/app/src/hooks';
import secureLocalStorage from 'react-secure-storage';

import { createPortal } from 'react-dom';
import { Button, Select, Tooltip } from '@ohif/ui';

export type ServerOption = { id: 0 | 1; value: 'local' | 'cloud'; label: 'Local' | 'Cloud' };

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { showToast } = useToast();
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);

  const { setIsInsideViewer, isChangeInAnnotationViewPort, setIsChangeInAnnotationViewPort } =
    useXylexaAppContext();

  const {
    selectedServer,
    serverOptions,
    userInfo,
    setCurrentServerConfigs,
    localServerConfigs,
    cloudServerConfigs,
    setSelectedServer,
  } = useAuthenticationContext();

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const searchParams = useSearchParams();
  const studyInstanceUidFromUrl = searchParams.get('StudyInstanceUIDs');

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      setIsInsideViewer(false);
      onClickReturnButton();
    }
  };

  const { getAnnotationDataArray } = annotationService();

  const { mutate: handleUpsertAnnotationData, isPending: isAnnotationSavePending } =
    useUpsertAnnotationData();

  async function handleSaveAnnotationClick() {
    const { measurementService } = servicesManager.services;
    const annotationData = await getAnnotationDataArray(measurementService.annotationsDataArr);
    const body = {
      User: userInfo.id,
      study_instance_id: studyInstanceUidFromUrl,
      annotation_data: annotationData,
      measurement_data: measurementService.annotationsMeasurementDataArr,
    };
    handleUpsertAnnotationData(body, {
      onSuccess: res => {
        showToast({
          content: 'Annotations Saved!',
          type: 'success',
        });
        setIsChangeInAnnotationViewPort(false);

        if (showAnnotationModal) {
          navigate('/');
        }
      },
      onError() {
        showToast({
          content: 'Annotations Submission Failed!',
          type: 'error',
        });
      },
    });
  }

  const navigate = useNavigate();

  const reportIds = secureLocalStorage.getItem(REPORT_IDS_KEY);

  const isReported = studyId => {
    const response = reportIds?.data.study_ids.find(id => {
      return id === studyId;
    });

    return response;
  };

  const [showModal, setShowModal] = useState(false);

  const onSelectServer = (selectedServer: ServerOption) => {
    setSelectedServer(selectedServer);
    const serverConfigs =
      selectedServer.value === 'cloud' ? cloudServerConfigs : localServerConfigs;

    if (serverConfigs) {
      setCurrentServerConfigs(serverConfigs);
      showToast({
        content: `Connected to ${selectedServer.value === 'cloud' ? 'Cloud' : 'Local'}`,
        type: 'success',
      });
    } else {
      showToast({
        content: `Unable to Connect with ${selectedServer.value === 'cloud' ? 'Cloud' : 'Local Server'}`,
        type: 'error',
      });
    }
  };

  function handleBackWithSaveAnnotations() {
    handleSaveAnnotationClick();
  }
  function handleBackWithoutSaveAnnotations() {
    onClickReturn();
  }
  const handleOpenAnnotationModal = () => {
    setShowAnnotationModal(true);
  };
  const handleCloseAnnotationModal = () => {
    setShowAnnotationModal(false);
  };

  return (
    <NavBar
      className="bg-secondary-dark flex flex-row items-center justify-between border-black p-4"
      isSticky={isSticky}
      {...props}
    >
      <div
        className={classNames('mr-3 inline-flex items-center', isReturnEnabled && 'cursor-pointer')}
        onClick={isChangeInAnnotationViewPort ? handleOpenAnnotationModal : onClickReturn}
        data-cy="return-to-work-list"
      >
        {isReturnEnabled && (
          <Icon
            name="chevron-left"
            className="text-secondary-main w-12"
          />
        )}
        {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
          <Link to={'/'}>
            <Svg
              name="logo-xylexa"
              className="w-40 sm:w-24 md:w-32 lg:w-36"
            />
          </Link>
        )}
      </div>

      {Secondary}

      <div className="flex flex-row items-center gap-3">
        {children}

        {isReturnEnabled && (
          <div>
            <div className="flex flex-row gap-5">
              <div
                onClick={() => {
                  isReported(studyInstanceUidFromUrl)
                    ? navigate(`/viewer/view-report/${studyInstanceUidFromUrl}`)
                    : setShowModal(true);
                }}
              >
                <Tooltip content={'Patient Report'}>
                  <Icon
                    name="write-report"
                    className="h-[1.5rem] w-[1.5rem]"
                  />
                </Tooltip>
              </div>
              {isAnnotationSavePending ? (
                <svg
                  aria-hidden="true"
                  className="mx-auto h-6 w-6 animate-spin text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <Tooltip content={'Save Annotations'}>
                  <button
                    id="save-annotation-button"
                    onClick={handleSaveAnnotationClick}
                  >
                    {' '}
                    <Icon
                      name="save-icon"
                      className="fill-wh h-[1.5rem] w-[1.5rem]"
                    />
                  </button>
                </Tooltip>
              )}
            </div>

            {showModal &&
              createPortal(
                <CreateReportModal setShowModal={setShowModal} />,
                document.body,
                'createReportModal'
              )}
            <Modal
              open={showAnnotationModal}
              onClose={handleCloseAnnotationModal}
            >
              <h1 className="text-xl font-semibold text-black">Do you want to save changes?</h1>
              <p className="text-lg text-black">
                If you choose &quot;No&quot;, the content will be deleted permanently, else the
                annotations will be saved.
              </p>
              <br />
              <div className="flex justify-end gap-2">
                <Button onClick={handleBackWithoutSaveAnnotations}>No</Button>
                {isAnnotationSavePending ? (
                  <div className="bg-primary-main w-12 rounded">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mt-1 h-5 w-5 animate-spin text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <Button onClick={handleBackWithSaveAnnotations}>Yes</Button>
                )}
              </div>
            </Modal>
          </div>
        )}
      </div>

      <div className="flex flex-row items-center">
        {showPatientInfo !== PatientInfoVisibility.DISABLED && (
          <HeaderPatientInfo
            servicesManager={servicesManager}
            appConfig={appConfig}
          />
        )}
        {!isReturnEnabled && (
          <div className="d-flex">
            {cloudServerConfigs && localServerConfigs ? (
              <Select
                id="select-server"
                className="border-primary-main min-w-28 relative mr-3 w-28 bg-transparent text-white"
                value={selectedServer}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                options={serverOptions}
                onChange={onSelectServer}
              />
            ) : (
              <div className="text-primary-active mr-4 self-center bg-black pt-1 pb-1 pl-3 pr-3 text-lg">
                {cloudServerConfigs && 'Cloud'}
                {localServerConfigs && 'Local'}
              </div>
            )}
          </div>
        )}

        {!isReturnEnabled && <UserInfo />}

        <Dropdown
          id="options"
          showDropdownIcon={false}
          list={menuOptions}
          alignment="right"
        >
          <IconButton
            id={'options-settings-icon'}
            variant="text"
            color="inherit"
            size="initial"
            className="text-primary-active hover:bg-primary-dark h-full w-full"
          >
            <Icon name="icon-settings" />
          </IconButton>
        </Dropdown>
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

export default Header;
