import axios from 'axios';
import { useSecureLocalStorageContext } from '../../hooks';
import { useQuery } from '@tanstack/react-query';
import {
  patientAiReportQueryKey,
  patientReportIdsQueryKey,
  patientReportQueryKey,
} from './queryKeys';
import { API_URL } from '../../constants';
import { useGetRequestOptions } from '../../hooks';
export type StudyId = string;

export const useGetStudyReport = (studyId: StudyId) => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientReportQueryKey,
    queryFn: async () => await axios.get(`${API_URL}v1/report/?study_id=${studyId}`, options),
  });
};

export const useGetAiReport = (studyId: StudyId) => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientAiReportQueryKey,
    queryFn: async () => await axios.get(`${API_URL}v1/aireport/?study_id=${studyId}`, options),
  });
};

export const useGetReportIds = () => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientReportIdsQueryKey,
    queryFn: async () => await axios.get('v1/reportIds/', options),
  });
};
