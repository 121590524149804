import axios, { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { API_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useSecureLocalStorage } from '../../hooks';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError;
  }
}

export type Credentials = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export type Auth = {
  data: {
    access: string;
  };
  response: {
    data: {
      detail: string;
    };
  };
};

export const useLogin = () => {
  return useMutation({
    mutationFn: (creds: Credentials) => axios.post(`${API_URL}token/`, creds),
  });
};

export const useLogout = () => {
  const { clearStorage } = useSecureLocalStorage();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => axios.post(`${API_URL}logout/`),
    onSuccess() {
      clearStorage();
      navigate('/login');
    },
  });
};
