// import logo from "../clientLogos/CMH.png"
// const logo = "CMH.png"
import xylexaMono from './../clientLogos';
const logo = '../clientLogos/AFIRI.jpg';
const title = 'Xylexa Patient Report';
const contactInfo =
  'Location: NSTP, NUST, H-12, Islamabad<br>Phone:&nbsp;051-5582980&nbsp;|&nbsp;051-5564704 ';
function getHeader(userInfo, logo) {
  return `<table style="border-collapse:collapse; font-family: Arial, Helvetica, sans-serif; width: 100% ; text-align: center;"><tbody> <tr> <td style="width: 19.2956%;"><img src=${
    require(`../clientLogos/xylexaMono.png`).default
  } style="width: 90px; height:auto;"><br></td><td style="width: 100%; text-align: center; line-height: 1;"><span style="font-size: 30px;"><strong style=""><span style="font-size: 30px;">${title}</span><br><span style="font-size: 16px;">${contactInfo}</span></strong></span><br></td></tr></tbody></table><p><br></p><hr><br><table style="border-collapse:collapse;width: 100%;"><tbody> <tr> <td style="width: 50%;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>PCN:</strong> <input style="background: transparent;" type="text" id="mrn" disabled value="${
    userInfo?.mrn ? userInfo?.mrn : ''
  }"></span> <br></span></td> <td style="width: 50%; text-align: right;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong style=""><span style="font-size: 16px;">Mode of Admission: </span></strong>Emergency</span><br></span></td></tr> <tr> <td style="width: 50%;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Name:</strong><input style="background: transparent;" type="text" id="patientName" value="${userInfo?.patientName ? userInfo?.patientName : ''}" disabled> </span> <br></span></td> <td style="width: 50%; text-align: right;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Admission Date:</strong> 00-00-0000</span><br></span></td></tr><tr> <td style="width: 50%;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Age: </strong>${
    userInfo?.PAge ? userInfo?.PAge : ''
  }</span></span><br></td> <td style="width: 50%; text-align: right;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Report Date:</strong> 00-00-000</span></span><br></td></tr><tr> <td style="width: 50%;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Gender: </strong>${
    userInfo?.patientSex ? userInfo?.patientSex : ''
  }</span></span> <br></td> <td style="width: 50%; text-align: right;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 16px;"><strong>Duration of Hospital Stay:</strong>${userInfo?.durationOfHospitalStay ? userInfo?.durationOfHospitalStay : '00-00-000'} </span></span> </td></tr></tbody></table><br/><hr/>`;
}
export default getHeader;
