import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingIndicatorProgress } from '@ohif/ui';

import { useReactToPrint } from 'react-to-print';

import { useXylexaAppContext } from './../context';
import { useGetAiReport, useGetStudyReport } from '../api-client';

import { Appbar } from './components';
import { getAiReportBody, getFooter } from './helpers';

export const ViewReport = () => {
  const pdfRef = useRef();
  const navigate = useNavigate();

  const [isAIReportPreview, setIsAIReportPreview] = useState<boolean>(false);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const {
    setSelectedModality,
    setSelectedTemplate,
    selectedStudy,
    setIsNewReport,
    getStudyReportKey,
    isInsideViewer,
  } = useXylexaAppContext();

  const { data: studyReportData, isLoading: isGetStudyReportLoading } = useGetStudyReport(
    selectedStudy?.studyInstanceUid,
    getStudyReportKey
  );

  const { data: aiReportData } = useGetAiReport(selectedStudy?.studyInstanceUid);

  //splitting report to extract the header
  const splittedReport = studyReportData?.data.description.split('<hr>');

  const handleBack = e => {
    e.preventDefault();
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setIsNewReport(false);

    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  };

  // This will remain true until the component is mounted. Just to conditionally render some options on navBar.
  const ViewReportPreview = true;

  return (
    <>
      {isGetStudyReportLoading ? (
        <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
      ) : (
        <div className="min-h-screen bg-white">
          <Appbar
            handleOpenDialog={handleBack}
            ViewReportPreview={ViewReportPreview}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
            handlePrint={handlePrint}
          />

          <div className="w-3/2 mx-auto flex h-screen justify-center overflow-auto !text-[black]">
            <div
              style={{
                marginTop: '100px',
                width: '60%',
              }}
            >
              {studyReportData?.data?.description !== undefined ||
              aiReportData?.data.description ? (
                <div
                  ref={pdfRef}
                  style={{
                    padding: '50px',
                  }}
                >
                  {!isAIReportPreview && (
                    <div dangerouslySetInnerHTML={{ __html: studyReportData?.data?.description }} />
                  )}
                  {isAIReportPreview && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${splittedReport[0]}${splittedReport[1]}<br/>${getAiReportBody(aiReportData?.data)}${getFooter()}`,
                      }}
                    />
                  )}
                </div>
              ) : (
                <div>Something went wrong. Nothing to display!</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewReport;
