import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { config } from './config/config';
import JoditEditor from 'jodit-react';
import { Appbar } from './components';
import { templates, templateModalities } from './templates/templates';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { LoadingIndicatorProgress } from '@ohif/ui';
import { Button } from '@ohif/ui';
import { useAuthenticationContext, useXylexaAppContext } from './../context';
import { useToast } from '../hooks';
import { useGetStudyReport, useUpdateStudyReport } from '../api-client';
import mixpanel from 'mixpanel-browser';

export const EditReport = () => {
  const editor = useRef(null);

  const { userInfo } = useAuthenticationContext();
  const {
    selectedTemplate,
    setSelectedTemplate,
    selectedModality,
    setSelectedModality,
    changeInReportDetected,
    setChangeInReportDetected,
    selectedStudy,
    setIsNewReport,
    getStudyReportKey,
    isInsideViewer,
  } = useXylexaAppContext();

  useEffect(() => {
    performance.mark('edit-report-mounted');
    return () => {
      performance.mark('edit-report-unmounted');
      performance.measure('edit-report-timespend', 'edit-report-mounted', 'edit-report-unmounted');
      const timeTaken = (
        performance.getEntriesByName('edit-report-timespend')[0].duration / 1000
      ).toFixed(2);

      mixpanel.track('Update Report (Time)', {
        pageUrl: window.location.href,
        page_name: 'Edit Report',
        userId: userInfo?.id,
        studyId: selectedStudy?.studyInstanceUid,
        pageViewDuration: timeTaken,
        username: userInfo?.username,
      });
    };
  }, [selectedStudy?.studyInstanceUid, userInfo?.id]);

  const [isNew, setIsNew] = useState<boolean>(true);
  const { showToast } = useToast();

  const [isAIReportPreview, setIsAIReportPreview] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editedReportContent, setEditedReportContent] = useState<string>('');

  const navigate = useNavigate();

  const { data: studyReportData, isFetching: isStudyReportFetching } = useGetStudyReport(
    selectedStudy?.studyInstanceUid,
    getStudyReportKey
  );

  const { mutate: handleUpdateStudyReport } = useUpdateStudyReport();

  useEffect(() => {
    setEditedReportContent(studyReportData?.data.description);
  }, []);

  useEffect(() => {
    const handleUnload = e => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  // Report Submission

  // handle report updates
  const handleUpdate = () => {
    const reportBody = {
      description: editedReportContent,
    };

    const studyId = selectedStudy?.studyInstanceUid;

    handleUpdateStudyReport(
      { studyId, reportBody },
      {
        onSuccess: () => {
          setChangeInReportDetected(false);
          setIsNewReport(false);
          showToast({
            content: 'Report Updated.',
            type: 'success',
          });
          navigate(`/viewer/view-report/${studyId}`);
        },
        onError() {
          showToast({
            content: 'Report Update Failed.',
            type: 'error',
          });
        },
      }
    );
  };

  const handleBackWithSave = () => {
    const reportBody = {
      description: editedReportContent,
    };

    const studyId = selectedStudy?.studyInstanceUid;

    handleUpdateStudyReport(
      { studyId, reportBody },
      {
        onSuccess: res => {
          setChangeInReportDetected(false);
          setSelectedModality('Default');
          setSelectedTemplate(null);
          setIsNewReport(false);
          showToast({
            content: 'Report Updated.',
            type: 'success',
          });
          if (isInsideViewer) {
            navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
            return;
          } else {
            navigate('/');
            return;
          }
        },
        onError() {
          showToast({
            content: 'Report Update Failed.',
            type: 'error',
          });
        },
      }
    );
  };

  const handleBackWithoutSave = () => {
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);
    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  };

  // Confirmation Dialogue
  function handleBackWithoutSaveNoChangeDetected() {
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);
    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  }
  const handleOpenDialog = () => {
    if (!changeInReportDetected) {
      handleBackWithoutSaveNoChangeDetected();
      return;
    }
    if (changeInReportDetected) {
      setOpenDialog(true);
      return;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {isStudyReportFetching ? (
        <div className="flex flex-col items-center justify-center pt-48">
          <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
        </div>
      ) : (
        <div className="min-h-screen bg-white">
          <Appbar
            flag={flag}
            setFlag={setFlag}
            isNew={isNew}
            setIsNew={setIsNew}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templateOptions={templates[selectedModality?.value]}
            templateModalities={templateModalities}
            signatures={[]}
            selectedModality={selectedModality}
            setSelectedModality={setSelectedModality}
            handleOpenDialog={handleOpenDialog}
            handleUpdate={handleUpdate}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
          />

          <div style={{ marginTop: '80px', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
            <JoditEditor
              ref={editor}
              value={editedReportContent}
              config={config}
              onChange={newContent => {
                setChangeInReportDetected(true);
                setEditedReportContent(newContent);
              }}
            />
          </div>
        </div>
      )}
      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <h1 className="text-xl font-semibold text-black">Do you want to save changes?</h1>
        <p className="text-lg text-black">
          If you choose &quot;No&quot;, the content will be deleted permanently, else the report
          will be saved.
        </p>
        <br />
        <div className="flex justify-end gap-2">
          <Button onClick={handleBackWithoutSave}>No</Button>
          <Button onClick={handleBackWithSave}>Yes</Button>
        </div>
      </Modal>
    </>
  );
};
export default EditReport;
