import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';

import { Link, useNavigate } from 'react-router-dom';

import { CreateReportModal, REPORT_IDS_KEY, useXylexaAppContext } from '@xylexa/xylexa-app';
import { useSearchParams } from '@ohif/app/src/hooks';
import secureLocalStorage from 'react-secure-storage';

import { createPortal } from 'react-dom';
import { Tooltip } from '@ohif/ui';

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { t } = useTranslation('Header');

  const { setIsInsideViewer } = useXylexaAppContext();

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      setIsInsideViewer(false);
      onClickReturnButton();
    }
  };

  const navigate = useNavigate();

  const reportIds = secureLocalStorage.getItem(REPORT_IDS_KEY);

  const isReported = studyId => {
    const response = reportIds?.data.study_ids.find(id => {
      return id === studyId;
    });

    return response;
  };

  const searchParams = useSearchParams();
  const studyInstanceUidFromUrl = searchParams.get('StudyInstanceUIDs');

  const [showModal, setShowModal] = useState(false);

  return (
    <NavBar
      className="bg-secondary-dark flex flex-row items-center justify-between border-black p-4"
      isSticky={isSticky}
      {...props}
    >
      <div
        className={classNames('mr-3 inline-flex items-center', isReturnEnabled && 'cursor-pointer')}
        onClick={onClickReturn}
        data-cy="return-to-work-list"
      >
        {isReturnEnabled && (
          <Icon
            name="chevron-left"
            className="text-secondary-main w-12"
          />
        )}
        {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
          <Link to={'/'}>
            <Svg
              name="logo-xylexa"
              className="w-40 sm:w-24 md:w-32 lg:w-36"
            />
          </Link>
        )}
      </div>

      {Secondary}
      <div className="flex flex-row items-center gap-3">
        {children}

        {isReturnEnabled && (
          <div>
            <div
              onClick={() => {
                isReported(studyInstanceUidFromUrl)
                  ? navigate(`/viewer/view-report/${studyInstanceUidFromUrl}`)
                  : setShowModal(true);
              }}
            >
              <Tooltip content={'Patient Report'}>
                <Icon
                  name="write-report"
                  className="h-[1.5rem] w-[1.5rem]"
                />
              </Tooltip>
            </div>
            {showModal &&
              createPortal(
                <CreateReportModal setShowModal={setShowModal} />,
                document.body,
                'createReportModal'
              )}
          </div>
        )}
      </div>

      <div className="flex flex-row">
        {showPatientInfo !== PatientInfoVisibility.DISABLED && (
          <HeaderPatientInfo
            servicesManager={servicesManager}
            appConfig={appConfig}
          />
        )}

        <Dropdown
          id="options"
          showDropdownIcon={false}
          list={menuOptions}
          alignment="right"
        >
          <IconButton
            id={'options-settings-icon'}
            variant="text"
            color="inherit"
            size="initial"
            className="text-primary-active hover:bg-primary-dark h-full w-full"
          >
            <Icon name="icon-settings" />
          </IconButton>
        </Dropdown>
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

export default Header;
