import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import Typography from '../Typography';
import InputGroup from '../InputGroup';

const StudyListFilter = ({
  filtersMeta,
  filterValues,
  onChange,
  clearFilters,
  isFiltering,
  isTriaged,
  triageFilters,
  numOfStudies,
  onUploadClick,
  getDataSourceConfigurationComponent,
}) => {
  const { t } = useTranslation('StudyList');
  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };
  const isSortingEnabled = numOfStudies > 0 && numOfStudies <= 100;

  return (
    <React.Fragment>
      <div>
        <div className="bg-secondary-light">
          <div className="relative m-auto flex flex-col pt-5">
            <div className="mb-5 flex flex-row justify-between px-12">
              <div className="flex flex-row">
                <Typography
                  variant="h4"
                  className="text-white"
                >
                  {t('StudyList')}
                </Typography>
              </div>
              <div className="flex h-[34px] flex-row items-center">
                {/* TODO revisit the completely rounded style of button used for clearing the study list filter - for now use LegacyButton*/}
                {isFiltering && (
                  <LegacyButton
                    className="bg-primary-light min-w-md mr-4 inline-flex items-center justify-center gap-2 rounded-md py-2 px-2 text-center font-sans text-lg text-[13px] leading-none text-black outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                    onClick={clearFilters}
                  >
                    <Icon
                      className="!h-[16px] !w-[16px] text-black"
                      name="clear-filter-icon"
                    />

                    <span className="font-medium">{t('ClearFilters')}</span>
                  </LegacyButton>
                )}
                {!isTriaged && (
                  <LegacyButton
                    className="bg-primary-light min-w-md mr-4 inline-flex items-center justify-center gap-2 rounded-md py-2 px-2 text-center font-sans text-lg text-[13px] leading-none text-black outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                    startIcon={<Icon name="dotted-circle" />}
                    onClick={triageFilters}
                  >
                    {t('Triage')}
                  </LegacyButton>
                )}
                {getDataSourceConfigurationComponent && getDataSourceConfigurationComponent()}
                {onUploadClick && (
                  <LegacyButton
                    className="bg-primary-light min-w-md mr-4 inline-flex items-center justify-center gap-2 rounded-md py-2 px-2 text-center font-sans text-lg text-[13px] leading-none text-black outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                    onClick={onUploadClick}
                  >
                    <Icon
                      className="!h-[16px] !w-[16px] text-black"
                      name="icon-upload-Dicom"
                    />
                    <span className="font-medium">{t('Upload')}</span>
                  </LegacyButton>
                )}

                <Typography
                  variant="h6"
                  className="text-primary-light"
                >
                  {`${t('Number of studies')}: `}
                </Typography>
                <Typography
                  variant="h6"
                  className="text-common-light"
                  data-cy={'num-studies'}
                >
                  {numOfStudies}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky top-1 z-10 border-b-4 border-black">
        <div className="bg-secondary-light pr-4 pt-3 pb-3">
          <InputGroup
            inputMeta={filtersMeta}
            values={filterValues}
            onValuesChange={onChange}
            sorting={filterSorting}
            onSortingChange={setFilterSorting}
            isSortingEnabled={isSortingEnabled}
          />
        </div>
        {numOfStudies > 100 && (
          <div className="m-auto">
            <div className="bg-primary-light py-2 text-center text-base">
              <p className="text-lg text-black">
                {t('Filter list to 100 studies or less to enable sorting')}
              </p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

StudyListFilter.propTypes = {
  filtersMeta: PropTypes.arrayOf(
    PropTypes.shape({
      /** Identifier used to map a field to it's value in `filterValues` */
      name: PropTypes.string.isRequired,
      /** Friendly label for filter field */
      displayName: PropTypes.string.isRequired,
      /** One of the supported filter field input types */
      inputType: PropTypes.oneOf(['Text', 'MultiSelect', 'DateRange', 'None']).isRequired,
      isSortable: PropTypes.bool.isRequired,
      /** Size of filter field in a 12-grid system */
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
      /** Options for a "MultiSelect" inputType */
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  filterValues: PropTypes.object.isRequired,
  numOfStudies: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  isTriaged: PropTypes.bool,
  triageFilters: PropTypes.func,
  onUploadClick: PropTypes.func,
  getDataSourceConfigurationComponent: PropTypes.func,
};

export default StudyListFilter;
