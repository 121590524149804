import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { config } from './config/config';
import JoditEditor from 'jodit-react';
import { Appbar } from './components';
import { templates, templateModalities } from './templates/templates';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { LoadingIndicatorProgress } from '@ohif/ui';
import { Button as CustomButton } from '@ohif/ui';
import { useXylexaAppContext } from './../context';

import { useGetStudyReport, useUpdateStudyReport } from '../api-client';

export const EditReport = () => {
  const editor = useRef(null);

  const [isNew, setIsNew] = useState<boolean>(true);

  const [isAIReportPreview, setIsAIReportPreview] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editedReportContent, setEditedReportContent] = useState<string>('');

  const navigate = useNavigate();

  const {
    selectedTemplate,
    setSelectedTemplate,
    selectedModality,
    setSelectedModality,
    changeInReportDetected,
    setChangeInReportDetected,

    selectedStudy,
    setIsNewReport,
    getStudyReportKey,
    isInsideViewer,
  } = useXylexaAppContext();

  const { data: studyReportData, isLoading: isGetStudyReportLoading } = useGetStudyReport(
    selectedStudy?.studyInstanceUid,
    getStudyReportKey
  );

  const { mutate: handleUpdateStudyReport } = useUpdateStudyReport();

  useEffect(() => {
    setEditedReportContent(studyReportData?.data.description);
  }, []);

  useEffect(() => {
    const handleUnload = e => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  // Report Submission

  // handle report updates
  const handleUpdate = async (e: any) => {
    e.preventDefault();

    const reportBody = {
      description: editedReportContent,
    };

    const studyId = selectedStudy?.studyInstanceUid;

    handleUpdateStudyReport(
      { studyId, reportBody },
      {
        onSuccess: res => {
          setChangeInReportDetected(false);
          setIsNewReport(false);
          navigate(`/viewer/view-report/${studyId}`);
        },
        onError(error) {
          console.log(error);
        },
      }
    );
  };

  const handleBackWithSave = async e => {
    e.preventDefault();
    const reportBody = {
      description: editedReportContent,
    };

    const studyId = selectedStudy?.studyInstanceUid;

    handleUpdateStudyReport(
      { studyId, reportBody },
      {
        onSuccess: res => {
          setChangeInReportDetected(false);
          setSelectedModality('Default');
          setSelectedTemplate(null);
          setIsNewReport(false);
          if (isInsideViewer) {
            navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
            return;
          } else {
            navigate('/');
            return;
          }
        },
        onError(error) {
          console.log(error);
        },
      }
    );
  };

  const handleBackWithoutSave = async e => {
    e.preventDefault();
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);

    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  };

  // Confirmation Dialogue
  function handleBackWithoutSaveNoChangeDetected() {
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setChangeInReportDetected(false);
    setIsNewReport(false);
    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  }
  const handleOpenDialog = () => {
    if (!changeInReportDetected) {
      handleBackWithoutSaveNoChangeDetected();
      return;
    }
    if (changeInReportDetected) {
      setOpenDialog(true);
      return;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {isGetStudyReportLoading ? (
        <div className="flex flex-col items-center justify-center pt-48">
          <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
        </div>
      ) : (
        <div className="min-h-screen bg-white">
          <Appbar
            flag={flag}
            setFlag={setFlag}
            isNew={isNew}
            setIsNew={setIsNew}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templateOptions={templates[selectedModality?.value]}
            templateModalities={templateModalities}
            signatures={[]}
            selectedModality={selectedModality}
            setSelectedModality={setSelectedModality}
            handleOpenDialog={handleOpenDialog}
            handleUpdate={handleUpdate}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
          />

          <div style={{ marginTop: '80px', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
            <JoditEditor
              ref={editor}
              value={editedReportContent}
              config={config}
              onChange={newContent => {
                setChangeInReportDetected(true);
                setEditedReportContent(newContent);
              }}
            />
          </div>
        </div>
      )}
      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <h1 className="text-xl font-semibold text-black">Do you want to save changes?</h1>
        <p className="text-lg text-black">
          If you choose "No", the content will be deleted permanently, else the report will be
          saved.
        </p>
        <br />
        <div className="flex justify-end gap-2">
          <CustomButton onClick={handleBackWithoutSave}>No</CustomButton>
          <CustomButton onClick={handleBackWithSave}>Yes</CustomButton>
        </div>
      </Modal>
    </>
  );
};
export default EditReport;
