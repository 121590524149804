const colormaps = [
  {
    ColorSpace: 'RGB',
    Name: 'Grayscale',
    name: 'Grayscale',
    NanColor: [1, 0, 0],
    RGBPoints: [0, 0, 0, 0, 1, 1, 1, 1],
    description: 'Grayscale',
  },
  {
    ColorSpace: 'RGB',
    Name: 'X Ray',
    name: 'X Ray',
    NanColor: [1, 0, 0],
    RGBPoints: [0, 1, 1, 1, 1, 0, 0, 0],
    description: 'X Ray',
  },
  {
    ColorSpace: 'RGB',
    Name: 'hsv',
    name: 'hsv',
    RGBPoints: [
      -1, 1, 0, 0, -0.666666, 1, 0, 1, -0.333333, 0, 0, 1, 0, 0, 1, 1, 0.33333, 0, 1, 0, 0.66666, 1,
      1, 0, 1, 1, 0, 0,
    ],
    description: 'HSV',
  },
  {
    ColorSpace: 'RGB',
    Name: 'hot_iron',
    name: 'hot_iron',
    RGBPoints: [
      0.0, 0.0039215686, 0.0039215686, 0.0156862745, 0.00392156862745098, 0.0039215686,
      0.0039215686, 0.0156862745, 0.00784313725490196, 0.0039215686, 0.0039215686, 0.031372549,
      0.011764705882352941, 0.0039215686, 0.0039215686, 0.0470588235, 0.01568627450980392,
      0.0039215686, 0.0039215686, 0.062745098, 0.0196078431372549, 0.0039215686, 0.0039215686,
      0.0784313725, 0.023529411764705882, 0.0039215686, 0.0039215686, 0.0941176471,
      0.027450980392156862, 0.0039215686, 0.0039215686, 0.1098039216, 0.03137254901960784,
      0.0039215686, 0.0039215686, 0.1254901961, 0.03529411764705882, 0.0039215686, 0.0039215686,
      0.1411764706, 0.0392156862745098, 0.0039215686, 0.0039215686, 0.1568627451,
      0.043137254901960784, 0.0039215686, 0.0039215686, 0.1725490196, 0.047058823529411764,
      0.0039215686, 0.0039215686, 0.1882352941, 0.050980392156862744, 0.0039215686, 0.0039215686,
      0.2039215686, 0.054901960784313725, 0.0039215686, 0.0039215686, 0.2196078431,
      0.05882352941176471, 0.0039215686, 0.0039215686, 0.2352941176, 0.06274509803921569,
      0.0039215686, 0.0039215686, 0.2509803922, 0.06666666666666667, 0.0039215686, 0.0039215686,
      0.262745098, 0.07058823529411765, 0.0039215686, 0.0039215686, 0.2784313725,
      0.07450980392156863, 0.0039215686, 0.0039215686, 0.2941176471, 0.0784313725490196,
      0.0039215686, 0.0039215686, 0.3098039216, 0.08235294117647059, 0.0039215686, 0.0039215686,
      0.3254901961, 0.08627450980392157, 0.0039215686, 0.0039215686, 0.3411764706,
      0.09019607843137255, 0.0039215686, 0.0039215686, 0.3568627451, 0.09411764705882353,
      0.0039215686, 0.0039215686, 0.3725490196, 0.09803921568627451, 0.0039215686, 0.0039215686,
      0.3882352941, 0.10196078431372549, 0.0039215686, 0.0039215686, 0.4039215686,
      0.10588235294117647, 0.0039215686, 0.0039215686, 0.4196078431, 0.10980392156862745,
      0.0039215686, 0.0039215686, 0.4352941176, 0.11372549019607843, 0.0039215686, 0.0039215686,
      0.4509803922, 0.11764705882352942, 0.0039215686, 0.0039215686, 0.4666666667,
      0.12156862745098039, 0.0039215686, 0.0039215686, 0.4823529412, 0.12549019607843137,
      0.0039215686, 0.0039215686, 0.4980392157, 0.12941176470588237, 0.0039215686, 0.0039215686,
      0.5137254902, 0.13333333333333333, 0.0039215686, 0.0039215686, 0.5294117647,
      0.13725490196078433, 0.0039215686, 0.0039215686, 0.5450980392, 0.1411764705882353,
      0.0039215686, 0.0039215686, 0.5607843137, 0.1450980392156863, 0.0039215686, 0.0039215686,
      0.5764705882, 0.14901960784313725, 0.0039215686, 0.0039215686, 0.5921568627,
      0.15294117647058825, 0.0039215686, 0.0039215686, 0.6078431373, 0.1568627450980392,
      0.0039215686, 0.0039215686, 0.6235294118, 0.1607843137254902, 0.0039215686, 0.0039215686,
      0.6392156863, 0.16470588235294117, 0.0039215686, 0.0039215686, 0.6549019608,
      0.16862745098039217, 0.0039215686, 0.0039215686, 0.6705882353, 0.17254901960784313,
      0.0039215686, 0.0039215686, 0.6862745098, 0.17647058823529413, 0.0039215686, 0.0039215686,
      0.7019607843, 0.1803921568627451, 0.0039215686, 0.0039215686, 0.7176470588,
      0.1843137254901961, 0.0039215686, 0.0039215686, 0.7333333333, 0.18823529411764706,
      0.0039215686, 0.0039215686, 0.7490196078, 0.19215686274509805, 0.0039215686, 0.0039215686,
      0.7607843137, 0.19607843137254902, 0.0039215686, 0.0039215686, 0.7764705882, 0.2,
      0.0039215686, 0.0039215686, 0.7921568627, 0.20392156862745098, 0.0039215686, 0.0039215686,
      0.8078431373, 0.20784313725490197, 0.0039215686, 0.0039215686, 0.8235294118,
      0.21176470588235294, 0.0039215686, 0.0039215686, 0.8392156863, 0.21568627450980393,
      0.0039215686, 0.0039215686, 0.8549019608, 0.2196078431372549, 0.0039215686, 0.0039215686,
      0.8705882353, 0.2235294117647059, 0.0039215686, 0.0039215686, 0.8862745098,
      0.22745098039215686, 0.0039215686, 0.0039215686, 0.9019607843, 0.23137254901960785,
      0.0039215686, 0.0039215686, 0.9176470588, 0.23529411764705885, 0.0039215686, 0.0039215686,
      0.9333333333, 0.23921568627450984, 0.0039215686, 0.0039215686, 0.9490196078,
      0.24313725490196078, 0.0039215686, 0.0039215686, 0.9647058824, 0.24705882352941178,
      0.0039215686, 0.0039215686, 0.9803921569, 0.25098039215686274, 0.0039215686, 0.0039215686,
      0.9960784314, 0.2549019607843137, 0.0039215686, 0.0039215686, 0.9960784314,
      0.25882352941176473, 0.0156862745, 0.0039215686, 0.9803921569, 0.2627450980392157,
      0.031372549, 0.0039215686, 0.9647058824, 0.26666666666666666, 0.0470588235, 0.0039215686,
      0.9490196078, 0.27058823529411763, 0.062745098, 0.0039215686, 0.9333333333,
      0.27450980392156865, 0.0784313725, 0.0039215686, 0.9176470588, 0.2784313725490196,
      0.0941176471, 0.0039215686, 0.9019607843, 0.2823529411764706, 0.1098039216, 0.0039215686,
      0.8862745098, 0.28627450980392155, 0.1254901961, 0.0039215686, 0.8705882353,
      0.2901960784313726, 0.1411764706, 0.0039215686, 0.8549019608, 0.29411764705882354,
      0.1568627451, 0.0039215686, 0.8392156863, 0.2980392156862745, 0.1725490196, 0.0039215686,
      0.8235294118, 0.30196078431372547, 0.1882352941, 0.0039215686, 0.8078431373,
      0.3058823529411765, 0.2039215686, 0.0039215686, 0.7921568627, 0.30980392156862746,
      0.2196078431, 0.0039215686, 0.7764705882, 0.3137254901960784, 0.2352941176, 0.0039215686,
      0.7607843137, 0.3176470588235294, 0.2509803922, 0.0039215686, 0.7490196078,
      0.3215686274509804, 0.262745098, 0.0039215686, 0.7333333333, 0.3254901960784314, 0.2784313725,
      0.0039215686, 0.7176470588, 0.32941176470588235, 0.2941176471, 0.0039215686, 0.7019607843,
      0.3333333333333333, 0.3098039216, 0.0039215686, 0.6862745098, 0.33725490196078434,
      0.3254901961, 0.0039215686, 0.6705882353, 0.3411764705882353, 0.3411764706, 0.0039215686,
      0.6549019608, 0.34509803921568627, 0.3568627451, 0.0039215686, 0.6392156863,
      0.34901960784313724, 0.3725490196, 0.0039215686, 0.6235294118, 0.35294117647058826,
      0.3882352941, 0.0039215686, 0.6078431373, 0.3568627450980392, 0.4039215686, 0.0039215686,
      0.5921568627, 0.3607843137254902, 0.4196078431, 0.0039215686, 0.5764705882,
      0.36470588235294116, 0.4352941176, 0.0039215686, 0.5607843137, 0.3686274509803922,
      0.4509803922, 0.0039215686, 0.5450980392, 0.37254901960784315, 0.4666666667, 0.0039215686,
      0.5294117647, 0.3764705882352941, 0.4823529412, 0.0039215686, 0.5137254902,
      0.3803921568627451, 0.4980392157, 0.0039215686, 0.4980392157, 0.3843137254901961,
      0.5137254902, 0.0039215686, 0.4823529412, 0.38823529411764707, 0.5294117647, 0.0039215686,
      0.4666666667, 0.39215686274509803, 0.5450980392, 0.0039215686, 0.4509803922,
      0.396078431372549, 0.5607843137, 0.0039215686, 0.4352941176, 0.4, 0.5764705882, 0.0039215686,
      0.4196078431, 0.403921568627451, 0.5921568627, 0.0039215686, 0.4039215686,
      0.40784313725490196, 0.6078431373, 0.0039215686, 0.3882352941, 0.4117647058823529,
      0.6235294118, 0.0039215686, 0.3725490196, 0.41568627450980394, 0.6392156863, 0.0039215686,
      0.3568627451, 0.4196078431372549, 0.6549019608, 0.0039215686, 0.3411764706,
      0.4235294117647059, 0.6705882353, 0.0039215686, 0.3254901961, 0.42745098039215684,
      0.6862745098, 0.0039215686, 0.3098039216, 0.43137254901960786, 0.7019607843, 0.0039215686,
      0.2941176471, 0.43529411764705883, 0.7176470588, 0.0039215686, 0.2784313725,
      0.4392156862745098, 0.7333333333, 0.0039215686, 0.262745098, 0.44313725490196076,
      0.7490196078, 0.0039215686, 0.2509803922, 0.4470588235294118, 0.7607843137, 0.0039215686,
      0.2352941176, 0.45098039215686275, 0.7764705882, 0.0039215686, 0.2196078431,
      0.4549019607843137, 0.7921568627, 0.0039215686, 0.2039215686, 0.4588235294117647,
      0.8078431373, 0.0039215686, 0.1882352941, 0.4627450980392157, 0.8235294118, 0.0039215686,
      0.1725490196, 0.4666666666666667, 0.8392156863, 0.0039215686, 0.1568627451,
      0.4705882352941177, 0.8549019608, 0.0039215686, 0.1411764706, 0.4745098039215686,
      0.8705882353, 0.0039215686, 0.1254901961, 0.4784313725490197, 0.8862745098, 0.0039215686,
      0.1098039216, 0.48235294117647065, 0.9019607843, 0.0039215686, 0.0941176471,
      0.48627450980392156, 0.9176470588, 0.0039215686, 0.0784313725, 0.49019607843137253,
      0.9333333333, 0.0039215686, 0.062745098, 0.49411764705882355, 0.9490196078, 0.0039215686,
      0.0470588235, 0.4980392156862745, 0.9647058824, 0.0039215686, 0.031372549, 0.5019607843137255,
      0.9803921569, 0.0039215686, 0.0156862745, 0.5058823529411764, 0.9960784314, 0.0039215686,
      0.0039215686, 0.5098039215686274, 0.9960784314, 0.0156862745, 0.0039215686,
      0.5137254901960784, 0.9960784314, 0.031372549, 0.0039215686, 0.5176470588235295, 0.9960784314,
      0.0470588235, 0.0039215686, 0.5215686274509804, 0.9960784314, 0.062745098, 0.0039215686,
      0.5254901960784314, 0.9960784314, 0.0784313725, 0.0039215686, 0.5294117647058824,
      0.9960784314, 0.0941176471, 0.0039215686, 0.5333333333333333, 0.9960784314, 0.1098039216,
      0.0039215686, 0.5372549019607843, 0.9960784314, 0.1254901961, 0.0039215686,
      0.5411764705882353, 0.9960784314, 0.1411764706, 0.0039215686, 0.5450980392156862,
      0.9960784314, 0.1568627451, 0.0039215686, 0.5490196078431373, 0.9960784314, 0.1725490196,
      0.0039215686, 0.5529411764705883, 0.9960784314, 0.1882352941, 0.0039215686,
      0.5568627450980392, 0.9960784314, 0.2039215686, 0.0039215686, 0.5607843137254902,
      0.9960784314, 0.2196078431, 0.0039215686, 0.5647058823529412, 0.9960784314, 0.2352941176,
      0.0039215686, 0.5686274509803921, 0.9960784314, 0.2509803922, 0.0039215686,
      0.5725490196078431, 0.9960784314, 0.262745098, 0.0039215686, 0.5764705882352941, 0.9960784314,
      0.2784313725, 0.0039215686, 0.5803921568627451, 0.9960784314, 0.2941176471, 0.0039215686,
      0.5843137254901961, 0.9960784314, 0.3098039216, 0.0039215686, 0.5882352941176471,
      0.9960784314, 0.3254901961, 0.0039215686, 0.592156862745098, 0.9960784314, 0.3411764706,
      0.0039215686, 0.596078431372549, 0.9960784314, 0.3568627451, 0.0039215686, 0.6, 0.9960784314,
      0.3725490196, 0.0039215686, 0.6039215686274509, 0.9960784314, 0.3882352941, 0.0039215686,
      0.6078431372549019, 0.9960784314, 0.4039215686, 0.0039215686, 0.611764705882353, 0.9960784314,
      0.4196078431, 0.0039215686, 0.615686274509804, 0.9960784314, 0.4352941176, 0.0039215686,
      0.6196078431372549, 0.9960784314, 0.4509803922, 0.0039215686, 0.6235294117647059,
      0.9960784314, 0.4666666667, 0.0039215686, 0.6274509803921569, 0.9960784314, 0.4823529412,
      0.0039215686, 0.6313725490196078, 0.9960784314, 0.4980392157, 0.0039215686,
      0.6352941176470588, 0.9960784314, 0.5137254902, 0.0039215686, 0.6392156862745098,
      0.9960784314, 0.5294117647, 0.0039215686, 0.6431372549019608, 0.9960784314, 0.5450980392,
      0.0039215686, 0.6470588235294118, 0.9960784314, 0.5607843137, 0.0039215686,
      0.6509803921568628, 0.9960784314, 0.5764705882, 0.0039215686, 0.6549019607843137,
      0.9960784314, 0.5921568627, 0.0039215686, 0.6588235294117647, 0.9960784314, 0.6078431373,
      0.0039215686, 0.6627450980392157, 0.9960784314, 0.6235294118, 0.0039215686,
      0.6666666666666666, 0.9960784314, 0.6392156863, 0.0039215686, 0.6705882352941176,
      0.9960784314, 0.6549019608, 0.0039215686, 0.6745098039215687, 0.9960784314, 0.6705882353,
      0.0039215686, 0.6784313725490196, 0.9960784314, 0.6862745098, 0.0039215686,
      0.6823529411764706, 0.9960784314, 0.7019607843, 0.0039215686, 0.6862745098039216,
      0.9960784314, 0.7176470588, 0.0039215686, 0.6901960784313725, 0.9960784314, 0.7333333333,
      0.0039215686, 0.6941176470588235, 0.9960784314, 0.7490196078, 0.0039215686,
      0.6980392156862745, 0.9960784314, 0.7607843137, 0.0039215686, 0.7019607843137254,
      0.9960784314, 0.7764705882, 0.0039215686, 0.7058823529411765, 0.9960784314, 0.7921568627,
      0.0039215686, 0.7098039215686275, 0.9960784314, 0.8078431373, 0.0039215686,
      0.7137254901960784, 0.9960784314, 0.8235294118, 0.0039215686, 0.7176470588235294,
      0.9960784314, 0.8392156863, 0.0039215686, 0.7215686274509804, 0.9960784314, 0.8549019608,
      0.0039215686, 0.7254901960784313, 0.9960784314, 0.8705882353, 0.0039215686,
      0.7294117647058823, 0.9960784314, 0.8862745098, 0.0039215686, 0.7333333333333333,
      0.9960784314, 0.9019607843, 0.0039215686, 0.7372549019607844, 0.9960784314, 0.9176470588,
      0.0039215686, 0.7411764705882353, 0.9960784314, 0.9333333333, 0.0039215686,
      0.7450980392156863, 0.9960784314, 0.9490196078, 0.0039215686, 0.7490196078431373,
      0.9960784314, 0.9647058824, 0.0039215686, 0.7529411764705882, 0.9960784314, 0.9803921569,
      0.0039215686, 0.7568627450980392, 0.9960784314, 0.9960784314, 0.0039215686,
      0.7607843137254902, 0.9960784314, 0.9960784314, 0.0196078431, 0.7647058823529411,
      0.9960784314, 0.9960784314, 0.0352941176, 0.7686274509803922, 0.9960784314, 0.9960784314,
      0.0509803922, 0.7725490196078432, 0.9960784314, 0.9960784314, 0.0666666667,
      0.7764705882352941, 0.9960784314, 0.9960784314, 0.0823529412, 0.7803921568627451,
      0.9960784314, 0.9960784314, 0.0980392157, 0.7843137254901961, 0.9960784314, 0.9960784314,
      0.1137254902, 0.788235294117647, 0.9960784314, 0.9960784314, 0.1294117647, 0.792156862745098,
      0.9960784314, 0.9960784314, 0.1450980392, 0.796078431372549, 0.9960784314, 0.9960784314,
      0.1607843137, 0.8, 0.9960784314, 0.9960784314, 0.1764705882, 0.803921568627451, 0.9960784314,
      0.9960784314, 0.1921568627, 0.807843137254902, 0.9960784314, 0.9960784314, 0.2078431373,
      0.8117647058823529, 0.9960784314, 0.9960784314, 0.2235294118, 0.8156862745098039,
      0.9960784314, 0.9960784314, 0.2392156863, 0.8196078431372549, 0.9960784314, 0.9960784314,
      0.2509803922, 0.8235294117647058, 0.9960784314, 0.9960784314, 0.2666666667,
      0.8274509803921568, 0.9960784314, 0.9960784314, 0.2823529412, 0.8313725490196079,
      0.9960784314, 0.9960784314, 0.2980392157, 0.8352941176470589, 0.9960784314, 0.9960784314,
      0.3137254902, 0.8392156862745098, 0.9960784314, 0.9960784314, 0.3333333333,
      0.8431372549019608, 0.9960784314, 0.9960784314, 0.3490196078, 0.8470588235294118,
      0.9960784314, 0.9960784314, 0.3647058824, 0.8509803921568627, 0.9960784314, 0.9960784314,
      0.3803921569, 0.8549019607843137, 0.9960784314, 0.9960784314, 0.3960784314,
      0.8588235294117647, 0.9960784314, 0.9960784314, 0.4117647059, 0.8627450980392157,
      0.9960784314, 0.9960784314, 0.4274509804, 0.8666666666666667, 0.9960784314, 0.9960784314,
      0.4431372549, 0.8705882352941177, 0.9960784314, 0.9960784314, 0.4588235294,
      0.8745098039215686, 0.9960784314, 0.9960784314, 0.4745098039, 0.8784313725490196,
      0.9960784314, 0.9960784314, 0.4901960784, 0.8823529411764706, 0.9960784314, 0.9960784314,
      0.5058823529, 0.8862745098039215, 0.9960784314, 0.9960784314, 0.5215686275,
      0.8901960784313725, 0.9960784314, 0.9960784314, 0.537254902, 0.8941176470588236, 0.9960784314,
      0.9960784314, 0.5529411765, 0.8980392156862745, 0.9960784314, 0.9960784314, 0.568627451,
      0.9019607843137255, 0.9960784314, 0.9960784314, 0.5843137255, 0.9058823529411765,
      0.9960784314, 0.9960784314, 0.6, 0.9098039215686274, 0.9960784314, 0.9960784314, 0.6156862745,
      0.9137254901960784, 0.9960784314, 0.9960784314, 0.631372549, 0.9176470588235294, 0.9960784314,
      0.9960784314, 0.6470588235, 0.9215686274509803, 0.9960784314, 0.9960784314, 0.6666666667,
      0.9254901960784314, 0.9960784314, 0.9960784314, 0.6823529412, 0.9294117647058824,
      0.9960784314, 0.9960784314, 0.6980392157, 0.9333333333333333, 0.9960784314, 0.9960784314,
      0.7137254902, 0.9372549019607843, 0.9960784314, 0.9960784314, 0.7294117647,
      0.9411764705882354, 0.9960784314, 0.9960784314, 0.7450980392, 0.9450980392156864,
      0.9960784314, 0.9960784314, 0.7568627451, 0.9490196078431372, 0.9960784314, 0.9960784314,
      0.7725490196, 0.9529411764705882, 0.9960784314, 0.9960784314, 0.7882352941,
      0.9568627450980394, 0.9960784314, 0.9960784314, 0.8039215686, 0.9607843137254903,
      0.9960784314, 0.9960784314, 0.8196078431, 0.9647058823529413, 0.9960784314, 0.9960784314,
      0.8352941176, 0.9686274509803922, 0.9960784314, 0.9960784314, 0.8509803922,
      0.9725490196078431, 0.9960784314, 0.9960784314, 0.8666666667, 0.9764705882352941,
      0.9960784314, 0.9960784314, 0.8823529412, 0.9803921568627451, 0.9960784314, 0.9960784314,
      0.8980392157, 0.984313725490196, 0.9960784314, 0.9960784314, 0.9137254902, 0.9882352941176471,
      0.9960784314, 0.9960784314, 0.9294117647, 0.9921568627450981, 0.9960784314, 0.9960784314,
      0.9450980392, 0.996078431372549, 0.9960784314, 0.9960784314, 0.9607843137, 1.0, 0.9960784314,
      0.9960784314, 0.9607843137,
    ],
    description: 'Hot Iron',
  },
  {
    ColorSpace: 'RGB',
    Name: 'red_hot',
    name: 'red_hot',
    RGBPoints: [
      0.0, 0.0, 0.0, 0.0, 0.00392156862745098, 0.0, 0.0, 0.0, 0.00784313725490196, 0.0, 0.0, 0.0,
      0.011764705882352941, 0.0, 0.0, 0.0, 0.01568627450980392, 0.0039215686, 0.0039215686,
      0.0039215686, 0.0196078431372549, 0.0039215686, 0.0039215686, 0.0039215686,
      0.023529411764705882, 0.0039215686, 0.0039215686, 0.0039215686, 0.027450980392156862,
      0.0039215686, 0.0039215686, 0.0039215686, 0.03137254901960784, 0.0039215686, 0.0039215686,
      0.0039215686, 0.03529411764705882, 0.0156862745, 0.0, 0.0, 0.0392156862745098, 0.0274509804,
      0.0, 0.0, 0.043137254901960784, 0.0392156863, 0.0, 0.0, 0.047058823529411764, 0.0509803922,
      0.0, 0.0, 0.050980392156862744, 0.062745098, 0.0, 0.0, 0.054901960784313725, 0.0784313725,
      0.0, 0.0, 0.05882352941176471, 0.0901960784, 0.0, 0.0, 0.06274509803921569, 0.1058823529, 0.0,
      0.0, 0.06666666666666667, 0.1176470588, 0.0, 0.0, 0.07058823529411765, 0.1294117647, 0.0, 0.0,
      0.07450980392156863, 0.1411764706, 0.0, 0.0, 0.0784313725490196, 0.1529411765, 0.0, 0.0,
      0.08235294117647059, 0.1647058824, 0.0, 0.0, 0.08627450980392157, 0.1764705882, 0.0, 0.0,
      0.09019607843137255, 0.1882352941, 0.0, 0.0, 0.09411764705882353, 0.2039215686, 0.0, 0.0,
      0.09803921568627451, 0.2156862745, 0.0, 0.0, 0.10196078431372549, 0.2274509804, 0.0, 0.0,
      0.10588235294117647, 0.2392156863, 0.0, 0.0, 0.10980392156862745, 0.2549019608, 0.0, 0.0,
      0.11372549019607843, 0.2666666667, 0.0, 0.0, 0.11764705882352942, 0.2784313725, 0.0, 0.0,
      0.12156862745098039, 0.2901960784, 0.0, 0.0, 0.12549019607843137, 0.3058823529, 0.0, 0.0,
      0.12941176470588237, 0.3176470588, 0.0, 0.0, 0.13333333333333333, 0.3294117647, 0.0, 0.0,
      0.13725490196078433, 0.3411764706, 0.0, 0.0, 0.1411764705882353, 0.3529411765, 0.0, 0.0,
      0.1450980392156863, 0.3647058824, 0.0, 0.0, 0.14901960784313725, 0.3764705882, 0.0, 0.0,
      0.15294117647058825, 0.3882352941, 0.0, 0.0, 0.1568627450980392, 0.4039215686, 0.0, 0.0,
      0.1607843137254902, 0.4156862745, 0.0, 0.0, 0.16470588235294117, 0.431372549, 0.0, 0.0,
      0.16862745098039217, 0.4431372549, 0.0, 0.0, 0.17254901960784313, 0.4588235294, 0.0, 0.0,
      0.17647058823529413, 0.4705882353, 0.0, 0.0, 0.1803921568627451, 0.4823529412, 0.0, 0.0,
      0.1843137254901961, 0.4941176471, 0.0, 0.0, 0.18823529411764706, 0.5098039216, 0.0, 0.0,
      0.19215686274509805, 0.5215686275, 0.0, 0.0, 0.19607843137254902, 0.5333333333, 0.0, 0.0, 0.2,
      0.5450980392, 0.0, 0.0, 0.20392156862745098, 0.5568627451, 0.0, 0.0, 0.20784313725490197,
      0.568627451, 0.0, 0.0, 0.21176470588235294, 0.5803921569, 0.0, 0.0, 0.21568627450980393,
      0.5921568627, 0.0, 0.0, 0.2196078431372549, 0.6078431373, 0.0, 0.0, 0.2235294117647059,
      0.6196078431, 0.0, 0.0, 0.22745098039215686, 0.631372549, 0.0, 0.0, 0.23137254901960785,
      0.6431372549, 0.0, 0.0, 0.23529411764705885, 0.6588235294, 0.0, 0.0, 0.23921568627450984,
      0.6705882353, 0.0, 0.0, 0.24313725490196078, 0.6823529412, 0.0, 0.0, 0.24705882352941178,
      0.6941176471, 0.0, 0.0, 0.25098039215686274, 0.7098039216, 0.0, 0.0, 0.2549019607843137,
      0.7215686275, 0.0, 0.0, 0.25882352941176473, 0.7333333333, 0.0, 0.0, 0.2627450980392157,
      0.7450980392, 0.0, 0.0, 0.26666666666666666, 0.7568627451, 0.0, 0.0, 0.27058823529411763,
      0.768627451, 0.0, 0.0, 0.27450980392156865, 0.7843137255, 0.0, 0.0, 0.2784313725490196,
      0.7960784314, 0.0, 0.0, 0.2823529411764706, 0.8117647059, 0.0, 0.0, 0.28627450980392155,
      0.8235294118, 0.0, 0.0, 0.2901960784313726, 0.8352941176, 0.0, 0.0, 0.29411764705882354,
      0.8470588235, 0.0, 0.0, 0.2980392156862745, 0.862745098, 0.0, 0.0, 0.30196078431372547,
      0.8745098039, 0.0, 0.0, 0.3058823529411765, 0.8862745098, 0.0, 0.0, 0.30980392156862746,
      0.8980392157, 0.0, 0.0, 0.3137254901960784, 0.9137254902, 0.0, 0.0, 0.3176470588235294,
      0.9254901961, 0.0, 0.0, 0.3215686274509804, 0.937254902, 0.0, 0.0, 0.3254901960784314,
      0.9490196078, 0.0, 0.0, 0.32941176470588235, 0.9607843137, 0.0, 0.0, 0.3333333333333333,
      0.968627451, 0.0, 0.0, 0.33725490196078434, 0.9803921569, 0.0039215686, 0.0,
      0.3411764705882353, 0.9882352941, 0.0078431373, 0.0, 0.34509803921568627, 1.0, 0.0117647059,
      0.0, 0.34901960784313724, 1.0, 0.0235294118, 0.0, 0.35294117647058826, 1.0, 0.0352941176, 0.0,
      0.3568627450980392, 1.0, 0.0470588235, 0.0, 0.3607843137254902, 1.0, 0.062745098, 0.0,
      0.36470588235294116, 1.0, 0.0745098039, 0.0, 0.3686274509803922, 1.0, 0.0862745098, 0.0,
      0.37254901960784315, 1.0, 0.0980392157, 0.0, 0.3764705882352941, 1.0, 0.1137254902, 0.0,
      0.3803921568627451, 1.0, 0.1254901961, 0.0, 0.3843137254901961, 1.0, 0.137254902, 0.0,
      0.38823529411764707, 1.0, 0.1490196078, 0.0, 0.39215686274509803, 1.0, 0.1647058824, 0.0,
      0.396078431372549, 1.0, 0.1764705882, 0.0, 0.4, 1.0, 0.1882352941, 0.0, 0.403921568627451,
      1.0, 0.2, 0.0, 0.40784313725490196, 1.0, 0.2156862745, 0.0, 0.4117647058823529, 1.0,
      0.2274509804, 0.0, 0.41568627450980394, 1.0, 0.2392156863, 0.0, 0.4196078431372549, 1.0,
      0.2509803922, 0.0, 0.4235294117647059, 1.0, 0.2666666667, 0.0, 0.42745098039215684, 1.0,
      0.2784313725, 0.0, 0.43137254901960786, 1.0, 0.2901960784, 0.0, 0.43529411764705883, 1.0,
      0.3019607843, 0.0, 0.4392156862745098, 1.0, 0.3176470588, 0.0, 0.44313725490196076, 1.0,
      0.3294117647, 0.0, 0.4470588235294118, 1.0, 0.3411764706, 0.0, 0.45098039215686275, 1.0,
      0.3529411765, 0.0, 0.4549019607843137, 1.0, 0.368627451, 0.0, 0.4588235294117647, 1.0,
      0.3803921569, 0.0, 0.4627450980392157, 1.0, 0.3921568627, 0.0, 0.4666666666666667, 1.0,
      0.4039215686, 0.0, 0.4705882352941177, 1.0, 0.4156862745, 0.0, 0.4745098039215686, 1.0,
      0.4274509804, 0.0, 0.4784313725490197, 1.0, 0.4392156863, 0.0, 0.48235294117647065, 1.0,
      0.4509803922, 0.0, 0.48627450980392156, 1.0, 0.4666666667, 0.0, 0.49019607843137253, 1.0,
      0.4784313725, 0.0, 0.49411764705882355, 1.0, 0.4941176471, 0.0, 0.4980392156862745, 1.0,
      0.5058823529, 0.0, 0.5019607843137255, 1.0, 0.5215686275, 0.0, 0.5058823529411764, 1.0,
      0.5333333333, 0.0, 0.5098039215686274, 1.0, 0.5450980392, 0.0, 0.5137254901960784, 1.0,
      0.5568627451, 0.0, 0.5176470588235295, 1.0, 0.568627451, 0.0, 0.5215686274509804, 1.0,
      0.5803921569, 0.0, 0.5254901960784314, 1.0, 0.5921568627, 0.0, 0.5294117647058824, 1.0,
      0.6039215686, 0.0, 0.5333333333333333, 1.0, 0.6196078431, 0.0, 0.5372549019607843, 1.0,
      0.631372549, 0.0, 0.5411764705882353, 1.0, 0.6431372549, 0.0, 0.5450980392156862, 1.0,
      0.6549019608, 0.0, 0.5490196078431373, 1.0, 0.6705882353, 0.0, 0.5529411764705883, 1.0,
      0.6823529412, 0.0, 0.5568627450980392, 1.0, 0.6941176471, 0.0, 0.5607843137254902, 1.0,
      0.7058823529, 0.0, 0.5647058823529412, 1.0, 0.7215686275, 0.0, 0.5686274509803921, 1.0,
      0.7333333333, 0.0, 0.5725490196078431, 1.0, 0.7450980392, 0.0, 0.5764705882352941, 1.0,
      0.7568627451, 0.0, 0.5803921568627451, 1.0, 0.7725490196, 0.0, 0.5843137254901961, 1.0,
      0.7843137255, 0.0, 0.5882352941176471, 1.0, 0.7960784314, 0.0, 0.592156862745098, 1.0,
      0.8078431373, 0.0, 0.596078431372549, 1.0, 0.8196078431, 0.0, 0.6, 1.0, 0.831372549, 0.0,
      0.6039215686274509, 1.0, 0.8470588235, 0.0, 0.6078431372549019, 1.0, 0.8588235294, 0.0,
      0.611764705882353, 1.0, 0.8745098039, 0.0, 0.615686274509804, 1.0, 0.8862745098, 0.0,
      0.6196078431372549, 1.0, 0.8980392157, 0.0, 0.6235294117647059, 1.0, 0.9098039216, 0.0,
      0.6274509803921569, 1.0, 0.9254901961, 0.0, 0.6313725490196078, 1.0, 0.937254902, 0.0,
      0.6352941176470588, 1.0, 0.9490196078, 0.0, 0.6392156862745098, 1.0, 0.9607843137, 0.0,
      0.6431372549019608, 1.0, 0.9764705882, 0.0, 0.6470588235294118, 1.0, 0.9803921569,
      0.0039215686, 0.6509803921568628, 1.0, 0.9882352941, 0.0117647059, 0.6549019607843137, 1.0,
      0.9921568627, 0.0156862745, 0.6588235294117647, 1.0, 1.0, 0.0235294118, 0.6627450980392157,
      1.0, 1.0, 0.0352941176, 0.6666666666666666, 1.0, 1.0, 0.0470588235, 0.6705882352941176, 1.0,
      1.0, 0.0588235294, 0.6745098039215687, 1.0, 1.0, 0.0745098039, 0.6784313725490196, 1.0, 1.0,
      0.0862745098, 0.6823529411764706, 1.0, 1.0, 0.0980392157, 0.6862745098039216, 1.0, 1.0,
      0.1098039216, 0.6901960784313725, 1.0, 1.0, 0.1254901961, 0.6941176470588235, 1.0, 1.0,
      0.137254902, 0.6980392156862745, 1.0, 1.0, 0.1490196078, 0.7019607843137254, 1.0, 1.0,
      0.1607843137, 0.7058823529411765, 1.0, 1.0, 0.1764705882, 0.7098039215686275, 1.0, 1.0,
      0.1882352941, 0.7137254901960784, 1.0, 1.0, 0.2, 0.7176470588235294, 1.0, 1.0, 0.2117647059,
      0.7215686274509804, 1.0, 1.0, 0.2274509804, 0.7254901960784313, 1.0, 1.0, 0.2392156863,
      0.7294117647058823, 1.0, 1.0, 0.2509803922, 0.7333333333333333, 1.0, 1.0, 0.262745098,
      0.7372549019607844, 1.0, 1.0, 0.2784313725, 0.7411764705882353, 1.0, 1.0, 0.2901960784,
      0.7450980392156863, 1.0, 1.0, 0.3019607843, 0.7490196078431373, 1.0, 1.0, 0.3137254902,
      0.7529411764705882, 1.0, 1.0, 0.3294117647, 0.7568627450980392, 1.0, 1.0, 0.3411764706,
      0.7607843137254902, 1.0, 1.0, 0.3529411765, 0.7647058823529411, 1.0, 1.0, 0.3647058824,
      0.7686274509803922, 1.0, 1.0, 0.3803921569, 0.7725490196078432, 1.0, 1.0, 0.3921568627,
      0.7764705882352941, 1.0, 1.0, 0.4039215686, 0.7803921568627451, 1.0, 1.0, 0.4156862745,
      0.7843137254901961, 1.0, 1.0, 0.431372549, 0.788235294117647, 1.0, 1.0, 0.4431372549,
      0.792156862745098, 1.0, 1.0, 0.4549019608, 0.796078431372549, 1.0, 1.0, 0.4666666667, 0.8,
      1.0, 1.0, 0.4784313725, 0.803921568627451, 1.0, 1.0, 0.4901960784, 0.807843137254902, 1.0,
      1.0, 0.5019607843, 0.8117647058823529, 1.0, 1.0, 0.5137254902, 0.8156862745098039, 1.0, 1.0,
      0.5294117647, 0.8196078431372549, 1.0, 1.0, 0.5411764706, 0.8235294117647058, 1.0, 1.0,
      0.5568627451, 0.8274509803921568, 1.0, 1.0, 0.568627451, 0.8313725490196079, 1.0, 1.0,
      0.5843137255, 0.8352941176470589, 1.0, 1.0, 0.5960784314, 0.8392156862745098, 1.0, 1.0,
      0.6078431373, 0.8431372549019608, 1.0, 1.0, 0.6196078431, 0.8470588235294118, 1.0, 1.0,
      0.631372549, 0.8509803921568627, 1.0, 1.0, 0.6431372549, 0.8549019607843137, 1.0, 1.0,
      0.6549019608, 0.8588235294117647, 1.0, 1.0, 0.6666666667, 0.8627450980392157, 1.0, 1.0,
      0.6823529412, 0.8666666666666667, 1.0, 1.0, 0.6941176471, 0.8705882352941177, 1.0, 1.0,
      0.7058823529, 0.8745098039215686, 1.0, 1.0, 0.7176470588, 0.8784313725490196, 1.0, 1.0,
      0.7333333333, 0.8823529411764706, 1.0, 1.0, 0.7450980392, 0.8862745098039215, 1.0, 1.0,
      0.7568627451, 0.8901960784313725, 1.0, 1.0, 0.768627451, 0.8941176470588236, 1.0, 1.0,
      0.7843137255, 0.8980392156862745, 1.0, 1.0, 0.7960784314, 0.9019607843137255, 1.0, 1.0,
      0.8078431373, 0.9058823529411765, 1.0, 1.0, 0.8196078431, 0.9098039215686274, 1.0, 1.0,
      0.8352941176, 0.9137254901960784, 1.0, 1.0, 0.8470588235, 0.9176470588235294, 1.0, 1.0,
      0.8588235294, 0.9215686274509803, 1.0, 1.0, 0.8705882353, 0.9254901960784314, 1.0, 1.0,
      0.8823529412, 0.9294117647058824, 1.0, 1.0, 0.8941176471, 0.9333333333333333, 1.0, 1.0,
      0.9098039216, 0.9372549019607843, 1.0, 1.0, 0.9215686275, 0.9411764705882354, 1.0, 1.0,
      0.937254902, 0.9450980392156864, 1.0, 1.0, 0.9490196078, 0.9490196078431372, 1.0, 1.0,
      0.9607843137, 0.9529411764705882, 1.0, 1.0, 0.9725490196, 0.9568627450980394, 1.0, 1.0,
      0.9882352941, 0.9607843137254903, 1.0, 1.0, 0.9882352941, 0.9647058823529413, 1.0, 1.0,
      0.9921568627, 0.9686274509803922, 1.0, 1.0, 0.9960784314, 0.9725490196078431, 1.0, 1.0, 1.0,
      0.9764705882352941, 1.0, 1.0, 1.0, 0.9803921568627451, 1.0, 1.0, 1.0, 0.984313725490196, 1.0,
      1.0, 1.0, 0.9882352941176471, 1.0, 1.0, 1.0, 0.9921568627450981, 1.0, 1.0, 1.0,
      0.996078431372549, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
    ],
    description: 'Red Hot',
  },
  {
    ColorSpace: 'RGB',
    Name: 's_pet',
    name: 's_pet',
    RGBPoints: [
      0.0, 0.0156862745, 0.0039215686, 0.0156862745, 0.00392156862745098, 0.0156862745,
      0.0039215686, 0.0156862745, 0.00784313725490196, 0.0274509804, 0.0039215686, 0.031372549,
      0.011764705882352941, 0.0352941176, 0.0039215686, 0.0509803922, 0.01568627450980392,
      0.0392156863, 0.0039215686, 0.0666666667, 0.0196078431372549, 0.0509803922, 0.0039215686,
      0.0823529412, 0.023529411764705882, 0.062745098, 0.0039215686, 0.0980392157,
      0.027450980392156862, 0.0705882353, 0.0039215686, 0.1176470588, 0.03137254901960784,
      0.0745098039, 0.0039215686, 0.1333333333, 0.03529411764705882, 0.0862745098, 0.0039215686,
      0.1490196078, 0.0392156862745098, 0.0980392157, 0.0039215686, 0.1647058824,
      0.043137254901960784, 0.1058823529, 0.0039215686, 0.1843137255, 0.047058823529411764,
      0.1098039216, 0.0039215686, 0.2, 0.050980392156862744, 0.1215686275, 0.0039215686,
      0.2156862745, 0.054901960784313725, 0.1333333333, 0.0039215686, 0.231372549,
      0.05882352941176471, 0.137254902, 0.0039215686, 0.2509803922, 0.06274509803921569,
      0.1490196078, 0.0039215686, 0.262745098, 0.06666666666666667, 0.1607843137, 0.0039215686,
      0.2784313725, 0.07058823529411765, 0.168627451, 0.0039215686, 0.2941176471,
      0.07450980392156863, 0.1725490196, 0.0039215686, 0.3137254902, 0.0784313725490196,
      0.1843137255, 0.0039215686, 0.3294117647, 0.08235294117647059, 0.1960784314, 0.0039215686,
      0.3450980392, 0.08627450980392157, 0.2039215686, 0.0039215686, 0.3607843137,
      0.09019607843137255, 0.2078431373, 0.0039215686, 0.3803921569, 0.09411764705882353,
      0.2196078431, 0.0039215686, 0.3960784314, 0.09803921568627451, 0.231372549, 0.0039215686,
      0.4117647059, 0.10196078431372549, 0.2392156863, 0.0039215686, 0.4274509804,
      0.10588235294117647, 0.2431372549, 0.0039215686, 0.4470588235, 0.10980392156862745,
      0.2509803922, 0.0039215686, 0.462745098, 0.11372549019607843, 0.262745098, 0.0039215686,
      0.4784313725, 0.11764705882352942, 0.2666666667, 0.0039215686, 0.4980392157,
      0.12156862745098039, 0.2666666667, 0.0039215686, 0.4980392157, 0.12549019607843137,
      0.262745098, 0.0039215686, 0.5137254902, 0.12941176470588237, 0.2509803922, 0.0039215686,
      0.5294117647, 0.13333333333333333, 0.2431372549, 0.0039215686, 0.5450980392,
      0.13725490196078433, 0.2392156863, 0.0039215686, 0.5607843137, 0.1411764705882353,
      0.231372549, 0.0039215686, 0.5764705882, 0.1450980392156863, 0.2196078431, 0.0039215686,
      0.5921568627, 0.14901960784313725, 0.2078431373, 0.0039215686, 0.6078431373,
      0.15294117647058825, 0.2039215686, 0.0039215686, 0.6235294118, 0.1568627450980392,
      0.1960784314, 0.0039215686, 0.6392156863, 0.1607843137254902, 0.1843137255, 0.0039215686,
      0.6549019608, 0.16470588235294117, 0.1725490196, 0.0039215686, 0.6705882353,
      0.16862745098039217, 0.168627451, 0.0039215686, 0.6862745098, 0.17254901960784313,
      0.1607843137, 0.0039215686, 0.7019607843, 0.17647058823529413, 0.1490196078, 0.0039215686,
      0.7176470588, 0.1803921568627451, 0.137254902, 0.0039215686, 0.7333333333, 0.1843137254901961,
      0.1333333333, 0.0039215686, 0.7490196078, 0.18823529411764706, 0.1215686275, 0.0039215686,
      0.7607843137, 0.19215686274509805, 0.1098039216, 0.0039215686, 0.7764705882,
      0.19607843137254902, 0.1058823529, 0.0039215686, 0.7921568627, 0.2, 0.0980392157,
      0.0039215686, 0.8078431373, 0.20392156862745098, 0.0862745098, 0.0039215686, 0.8235294118,
      0.20784313725490197, 0.0745098039, 0.0039215686, 0.8392156863, 0.21176470588235294,
      0.0705882353, 0.0039215686, 0.8549019608, 0.21568627450980393, 0.062745098, 0.0039215686,
      0.8705882353, 0.2196078431372549, 0.0509803922, 0.0039215686, 0.8862745098,
      0.2235294117647059, 0.0392156863, 0.0039215686, 0.9019607843, 0.22745098039215686,
      0.0352941176, 0.0039215686, 0.9176470588, 0.23137254901960785, 0.0274509804, 0.0039215686,
      0.9333333333, 0.23529411764705885, 0.0156862745, 0.0039215686, 0.9490196078,
      0.23921568627450984, 0.0078431373, 0.0039215686, 0.9647058824, 0.24313725490196078,
      0.0039215686, 0.0039215686, 0.9960784314, 0.24705882352941178, 0.0039215686, 0.0039215686,
      0.9960784314, 0.25098039215686274, 0.0039215686, 0.0196078431, 0.9647058824,
      0.2549019607843137, 0.0039215686, 0.0392156863, 0.9490196078, 0.25882352941176473,
      0.0039215686, 0.0549019608, 0.9333333333, 0.2627450980392157, 0.0039215686, 0.0745098039,
      0.9176470588, 0.26666666666666666, 0.0039215686, 0.0901960784, 0.9019607843,
      0.27058823529411763, 0.0039215686, 0.1098039216, 0.8862745098, 0.27450980392156865,
      0.0039215686, 0.1254901961, 0.8705882353, 0.2784313725490196, 0.0039215686, 0.1450980392,
      0.8549019608, 0.2823529411764706, 0.0039215686, 0.1607843137, 0.8392156863,
      0.28627450980392155, 0.0039215686, 0.1803921569, 0.8235294118, 0.2901960784313726,
      0.0039215686, 0.1960784314, 0.8078431373, 0.29411764705882354, 0.0039215686, 0.2156862745,
      0.7921568627, 0.2980392156862745, 0.0039215686, 0.231372549, 0.7764705882,
      0.30196078431372547, 0.0039215686, 0.2509803922, 0.7607843137, 0.3058823529411765,
      0.0039215686, 0.262745098, 0.7490196078, 0.30980392156862746, 0.0039215686, 0.2823529412,
      0.7333333333, 0.3137254901960784, 0.0039215686, 0.2980392157, 0.7176470588,
      0.3176470588235294, 0.0039215686, 0.3176470588, 0.7019607843, 0.3215686274509804,
      0.0039215686, 0.3333333333, 0.6862745098, 0.3254901960784314, 0.0039215686, 0.3529411765,
      0.6705882353, 0.32941176470588235, 0.0039215686, 0.368627451, 0.6549019608,
      0.3333333333333333, 0.0039215686, 0.3882352941, 0.6392156863, 0.33725490196078434,
      0.0039215686, 0.4039215686, 0.6235294118, 0.3411764705882353, 0.0039215686, 0.4235294118,
      0.6078431373, 0.34509803921568627, 0.0039215686, 0.4392156863, 0.5921568627,
      0.34901960784313724, 0.0039215686, 0.4588235294, 0.5764705882, 0.35294117647058826,
      0.0039215686, 0.4745098039, 0.5607843137, 0.3568627450980392, 0.0039215686, 0.4941176471,
      0.5450980392, 0.3607843137254902, 0.0039215686, 0.5098039216, 0.5294117647,
      0.36470588235294116, 0.0039215686, 0.5294117647, 0.5137254902, 0.3686274509803922,
      0.0039215686, 0.5450980392, 0.4980392157, 0.37254901960784315, 0.0039215686, 0.5647058824,
      0.4784313725, 0.3764705882352941, 0.0039215686, 0.5803921569, 0.462745098, 0.3803921568627451,
      0.0039215686, 0.6, 0.4470588235, 0.3843137254901961, 0.0039215686, 0.6156862745, 0.4274509804,
      0.38823529411764707, 0.0039215686, 0.6352941176, 0.4117647059, 0.39215686274509803,
      0.0039215686, 0.6509803922, 0.3960784314, 0.396078431372549, 0.0039215686, 0.6705882353,
      0.3803921569, 0.4, 0.0039215686, 0.6862745098, 0.3607843137, 0.403921568627451, 0.0039215686,
      0.7058823529, 0.3450980392, 0.40784313725490196, 0.0039215686, 0.7215686275, 0.3294117647,
      0.4117647058823529, 0.0039215686, 0.7411764706, 0.3137254902, 0.41568627450980394,
      0.0039215686, 0.7529411765, 0.2941176471, 0.4196078431372549, 0.0039215686, 0.7960784314,
      0.2784313725, 0.4235294117647059, 0.0039215686, 0.7960784314, 0.262745098,
      0.42745098039215684, 0.0392156863, 0.8039215686, 0.2509803922, 0.43137254901960786,
      0.0745098039, 0.8117647059, 0.231372549, 0.43529411764705883, 0.1098039216, 0.8196078431,
      0.2156862745, 0.4392156862745098, 0.1450980392, 0.8274509804, 0.2, 0.44313725490196076,
      0.1803921569, 0.8352941176, 0.1843137255, 0.4470588235294118, 0.2156862745, 0.8431372549,
      0.1647058824, 0.45098039215686275, 0.2509803922, 0.8509803922, 0.1490196078,
      0.4549019607843137, 0.2823529412, 0.8588235294, 0.1333333333, 0.4588235294117647,
      0.3176470588, 0.8666666667, 0.1176470588, 0.4627450980392157, 0.3529411765, 0.8745098039,
      0.0980392157, 0.4666666666666667, 0.3882352941, 0.8823529412, 0.0823529412,
      0.4705882352941177, 0.4235294118, 0.8901960784, 0.0666666667, 0.4745098039215686,
      0.4588235294, 0.8980392157, 0.0509803922, 0.4784313725490197, 0.4941176471, 0.9058823529,
      0.0431372549, 0.48235294117647065, 0.5294117647, 0.9137254902, 0.031372549,
      0.48627450980392156, 0.5647058824, 0.9215686275, 0.0196078431, 0.49019607843137253, 0.6,
      0.9294117647, 0.0078431373, 0.49411764705882355, 0.6352941176, 0.937254902, 0.0039215686,
      0.4980392156862745, 0.6705882353, 0.9450980392, 0.0039215686, 0.5019607843137255,
      0.7058823529, 0.9490196078, 0.0039215686, 0.5058823529411764, 0.7411764706, 0.9568627451,
      0.0039215686, 0.5098039215686274, 0.7725490196, 0.9607843137, 0.0039215686,
      0.5137254901960784, 0.8078431373, 0.968627451, 0.0039215686, 0.5176470588235295, 0.8431372549,
      0.9725490196, 0.0039215686, 0.5215686274509804, 0.8784313725, 0.9803921569, 0.0039215686,
      0.5254901960784314, 0.9137254902, 0.9843137255, 0.0039215686, 0.5294117647058824,
      0.9490196078, 0.9921568627, 0.0039215686, 0.5333333333333333, 0.9960784314, 0.9960784314,
      0.0039215686, 0.5372549019607843, 0.9960784314, 0.9960784314, 0.0039215686,
      0.5411764705882353, 0.9960784314, 0.9921568627, 0.0039215686, 0.5450980392156862,
      0.9960784314, 0.9843137255, 0.0039215686, 0.5490196078431373, 0.9960784314, 0.9764705882,
      0.0039215686, 0.5529411764705883, 0.9960784314, 0.968627451, 0.0039215686, 0.5568627450980392,
      0.9960784314, 0.9607843137, 0.0039215686, 0.5607843137254902, 0.9960784314, 0.9529411765,
      0.0039215686, 0.5647058823529412, 0.9960784314, 0.9450980392, 0.0039215686,
      0.5686274509803921, 0.9960784314, 0.937254902, 0.0039215686, 0.5725490196078431, 0.9960784314,
      0.9294117647, 0.0039215686, 0.5764705882352941, 0.9960784314, 0.9215686275, 0.0039215686,
      0.5803921568627451, 0.9960784314, 0.9137254902, 0.0039215686, 0.5843137254901961,
      0.9960784314, 0.9058823529, 0.0039215686, 0.5882352941176471, 0.9960784314, 0.8980392157,
      0.0039215686, 0.592156862745098, 0.9960784314, 0.8901960784, 0.0039215686, 0.596078431372549,
      0.9960784314, 0.8823529412, 0.0039215686, 0.6, 0.9960784314, 0.8745098039, 0.0039215686,
      0.6039215686274509, 0.9960784314, 0.8666666667, 0.0039215686, 0.6078431372549019,
      0.9960784314, 0.8588235294, 0.0039215686, 0.611764705882353, 0.9960784314, 0.8509803922,
      0.0039215686, 0.615686274509804, 0.9960784314, 0.8431372549, 0.0039215686, 0.6196078431372549,
      0.9960784314, 0.8352941176, 0.0039215686, 0.6235294117647059, 0.9960784314, 0.8274509804,
      0.0039215686, 0.6274509803921569, 0.9960784314, 0.8196078431, 0.0039215686,
      0.6313725490196078, 0.9960784314, 0.8117647059, 0.0039215686, 0.6352941176470588,
      0.9960784314, 0.8039215686, 0.0039215686, 0.6392156862745098, 0.9960784314, 0.7960784314,
      0.0039215686, 0.6431372549019608, 0.9960784314, 0.7882352941, 0.0039215686,
      0.6470588235294118, 0.9960784314, 0.7803921569, 0.0039215686, 0.6509803921568628,
      0.9960784314, 0.7725490196, 0.0039215686, 0.6549019607843137, 0.9960784314, 0.7647058824,
      0.0039215686, 0.6588235294117647, 0.9960784314, 0.7568627451, 0.0039215686,
      0.6627450980392157, 0.9960784314, 0.7490196078, 0.0039215686, 0.6666666666666666,
      0.9960784314, 0.7450980392, 0.0039215686, 0.6705882352941176, 0.9960784314, 0.737254902,
      0.0039215686, 0.6745098039215687, 0.9960784314, 0.7294117647, 0.0039215686,
      0.6784313725490196, 0.9960784314, 0.7215686275, 0.0039215686, 0.6823529411764706,
      0.9960784314, 0.7137254902, 0.0039215686, 0.6862745098039216, 0.9960784314, 0.7058823529,
      0.0039215686, 0.6901960784313725, 0.9960784314, 0.6980392157, 0.0039215686,
      0.6941176470588235, 0.9960784314, 0.6901960784, 0.0039215686, 0.6980392156862745,
      0.9960784314, 0.6823529412, 0.0039215686, 0.7019607843137254, 0.9960784314, 0.6745098039,
      0.0039215686, 0.7058823529411765, 0.9960784314, 0.6666666667, 0.0039215686,
      0.7098039215686275, 0.9960784314, 0.6588235294, 0.0039215686, 0.7137254901960784,
      0.9960784314, 0.6509803922, 0.0039215686, 0.7176470588235294, 0.9960784314, 0.6431372549,
      0.0039215686, 0.7215686274509804, 0.9960784314, 0.6352941176, 0.0039215686,
      0.7254901960784313, 0.9960784314, 0.6274509804, 0.0039215686, 0.7294117647058823,
      0.9960784314, 0.6196078431, 0.0039215686, 0.7333333333333333, 0.9960784314, 0.6117647059,
      0.0039215686, 0.7372549019607844, 0.9960784314, 0.6039215686, 0.0039215686,
      0.7411764705882353, 0.9960784314, 0.5960784314, 0.0039215686, 0.7450980392156863,
      0.9960784314, 0.5882352941, 0.0039215686, 0.7490196078431373, 0.9960784314, 0.5803921569,
      0.0039215686, 0.7529411764705882, 0.9960784314, 0.5725490196, 0.0039215686,
      0.7568627450980392, 0.9960784314, 0.5647058824, 0.0039215686, 0.7607843137254902,
      0.9960784314, 0.5568627451, 0.0039215686, 0.7647058823529411, 0.9960784314, 0.5490196078,
      0.0039215686, 0.7686274509803922, 0.9960784314, 0.5411764706, 0.0039215686,
      0.7725490196078432, 0.9960784314, 0.5333333333, 0.0039215686, 0.7764705882352941,
      0.9960784314, 0.5254901961, 0.0039215686, 0.7803921568627451, 0.9960784314, 0.5176470588,
      0.0039215686, 0.7843137254901961, 0.9960784314, 0.5098039216, 0.0039215686, 0.788235294117647,
      0.9960784314, 0.5019607843, 0.0039215686, 0.792156862745098, 0.9960784314, 0.4941176471,
      0.0039215686, 0.796078431372549, 0.9960784314, 0.4862745098, 0.0039215686, 0.8, 0.9960784314,
      0.4784313725, 0.0039215686, 0.803921568627451, 0.9960784314, 0.4705882353, 0.0039215686,
      0.807843137254902, 0.9960784314, 0.462745098, 0.0039215686, 0.8117647058823529, 0.9960784314,
      0.4549019608, 0.0039215686, 0.8156862745098039, 0.9960784314, 0.4470588235, 0.0039215686,
      0.8196078431372549, 0.9960784314, 0.4392156863, 0.0039215686, 0.8235294117647058,
      0.9960784314, 0.431372549, 0.0039215686, 0.8274509803921568, 0.9960784314, 0.4235294118,
      0.0039215686, 0.8313725490196079, 0.9960784314, 0.4156862745, 0.0039215686,
      0.8352941176470589, 0.9960784314, 0.4078431373, 0.0039215686, 0.8392156862745098,
      0.9960784314, 0.4, 0.0039215686, 0.8431372549019608, 0.9960784314, 0.3921568627, 0.0039215686,
      0.8470588235294118, 0.9960784314, 0.3843137255, 0.0039215686, 0.8509803921568627,
      0.9960784314, 0.3764705882, 0.0039215686, 0.8549019607843137, 0.9960784314, 0.368627451,
      0.0039215686, 0.8588235294117647, 0.9960784314, 0.3607843137, 0.0039215686,
      0.8627450980392157, 0.9960784314, 0.3529411765, 0.0039215686, 0.8666666666666667,
      0.9960784314, 0.3450980392, 0.0039215686, 0.8705882352941177, 0.9960784314, 0.337254902,
      0.0039215686, 0.8745098039215686, 0.9960784314, 0.3294117647, 0.0039215686,
      0.8784313725490196, 0.9960784314, 0.3215686275, 0.0039215686, 0.8823529411764706,
      0.9960784314, 0.3137254902, 0.0039215686, 0.8862745098039215, 0.9960784314, 0.3058823529,
      0.0039215686, 0.8901960784313725, 0.9960784314, 0.2980392157, 0.0039215686,
      0.8941176470588236, 0.9960784314, 0.2901960784, 0.0039215686, 0.8980392156862745,
      0.9960784314, 0.2823529412, 0.0039215686, 0.9019607843137255, 0.9960784314, 0.2705882353,
      0.0039215686, 0.9058823529411765, 0.9960784314, 0.2588235294, 0.0039215686,
      0.9098039215686274, 0.9960784314, 0.2509803922, 0.0039215686, 0.9137254901960784,
      0.9960784314, 0.2431372549, 0.0039215686, 0.9176470588235294, 0.9960784314, 0.231372549,
      0.0039215686, 0.9215686274509803, 0.9960784314, 0.2196078431, 0.0039215686,
      0.9254901960784314, 0.9960784314, 0.2117647059, 0.0039215686, 0.9294117647058824,
      0.9960784314, 0.2, 0.0039215686, 0.9333333333333333, 0.9960784314, 0.1882352941, 0.0039215686,
      0.9372549019607843, 0.9960784314, 0.1764705882, 0.0039215686, 0.9411764705882354,
      0.9960784314, 0.168627451, 0.0039215686, 0.9450980392156864, 0.9960784314, 0.1568627451,
      0.0039215686, 0.9490196078431372, 0.9960784314, 0.1450980392, 0.0039215686,
      0.9529411764705882, 0.9960784314, 0.1333333333, 0.0039215686, 0.9568627450980394,
      0.9960784314, 0.1254901961, 0.0039215686, 0.9607843137254903, 0.9960784314, 0.1137254902,
      0.0039215686, 0.9647058823529413, 0.9960784314, 0.1019607843, 0.0039215686,
      0.9686274509803922, 0.9960784314, 0.0901960784, 0.0039215686, 0.9725490196078431,
      0.9960784314, 0.0823529412, 0.0039215686, 0.9764705882352941, 0.9960784314, 0.0705882353,
      0.0039215686, 0.9803921568627451, 0.9960784314, 0.0588235294, 0.0039215686, 0.984313725490196,
      0.9960784314, 0.0470588235, 0.0039215686, 0.9882352941176471, 0.9960784314, 0.0392156863,
      0.0039215686, 0.9921568627450981, 0.9960784314, 0.0274509804, 0.0039215686, 0.996078431372549,
      0.9960784314, 0.0156862745, 0.0039215686, 1.0, 0.9960784314, 0.0156862745, 0.0039215686,
    ],
    description: 'S PET',
  },
  {
    ColorSpace: 'RGB',
    Name: 'perfusion',
    name: 'perfusion',
    RGBPoints: [
      0.0, 0.0, 0.0, 0.0, 0.00392156862745098, 0.0078431373, 0.0235294118, 0.0235294118,
      0.00784313725490196, 0.0078431373, 0.031372549, 0.0470588235, 0.011764705882352941,
      0.0078431373, 0.0392156863, 0.062745098, 0.01568627450980392, 0.0078431373, 0.0470588235,
      0.0862745098, 0.0196078431372549, 0.0078431373, 0.0549019608, 0.1019607843,
      0.023529411764705882, 0.0078431373, 0.0549019608, 0.1254901961, 0.027450980392156862,
      0.0078431373, 0.062745098, 0.1411764706, 0.03137254901960784, 0.0078431373, 0.0705882353,
      0.1647058824, 0.03529411764705882, 0.0078431373, 0.0784313725, 0.1803921569,
      0.0392156862745098, 0.0078431373, 0.0862745098, 0.2039215686, 0.043137254901960784,
      0.0078431373, 0.0862745098, 0.2196078431, 0.047058823529411764, 0.0078431373, 0.0941176471,
      0.2431372549, 0.050980392156862744, 0.0078431373, 0.1019607843, 0.2666666667,
      0.054901960784313725, 0.0078431373, 0.1098039216, 0.2823529412, 0.05882352941176471,
      0.0078431373, 0.1176470588, 0.3058823529, 0.06274509803921569, 0.0078431373, 0.1176470588,
      0.3215686275, 0.06666666666666667, 0.0078431373, 0.1254901961, 0.3450980392,
      0.07058823529411765, 0.0078431373, 0.1333333333, 0.3607843137, 0.07450980392156863,
      0.0078431373, 0.1411764706, 0.3843137255, 0.0784313725490196, 0.0078431373, 0.1490196078, 0.4,
      0.08235294117647059, 0.0078431373, 0.1490196078, 0.4235294118, 0.08627450980392157,
      0.0078431373, 0.1568627451, 0.4392156863, 0.09019607843137255, 0.0078431373, 0.1647058824,
      0.462745098, 0.09411764705882353, 0.0078431373, 0.1725490196, 0.4784313725,
      0.09803921568627451, 0.0078431373, 0.1803921569, 0.5019607843, 0.10196078431372549,
      0.0078431373, 0.1803921569, 0.5254901961, 0.10588235294117647, 0.0078431373, 0.1882352941,
      0.5411764706, 0.10980392156862745, 0.0078431373, 0.1960784314, 0.5647058824,
      0.11372549019607843, 0.0078431373, 0.2039215686, 0.5803921569, 0.11764705882352942,
      0.0078431373, 0.2117647059, 0.6039215686, 0.12156862745098039, 0.0078431373, 0.2117647059,
      0.6196078431, 0.12549019607843137, 0.0078431373, 0.2196078431, 0.6431372549,
      0.12941176470588237, 0.0078431373, 0.2274509804, 0.6588235294, 0.13333333333333333,
      0.0078431373, 0.2352941176, 0.6823529412, 0.13725490196078433, 0.0078431373, 0.2431372549,
      0.6980392157, 0.1411764705882353, 0.0078431373, 0.2431372549, 0.7215686275,
      0.1450980392156863, 0.0078431373, 0.2509803922, 0.737254902, 0.14901960784313725,
      0.0078431373, 0.2588235294, 0.7607843137, 0.15294117647058825, 0.0078431373, 0.2666666667,
      0.7843137255, 0.1568627450980392, 0.0078431373, 0.2745098039, 0.8, 0.1607843137254902,
      0.0078431373, 0.2745098039, 0.8235294118, 0.16470588235294117, 0.0078431373, 0.2823529412,
      0.8392156863, 0.16862745098039217, 0.0078431373, 0.2901960784, 0.862745098,
      0.17254901960784313, 0.0078431373, 0.2980392157, 0.8784313725, 0.17647058823529413,
      0.0078431373, 0.3058823529, 0.9019607843, 0.1803921568627451, 0.0078431373, 0.3058823529,
      0.9176470588, 0.1843137254901961, 0.0078431373, 0.2980392157, 0.9411764706,
      0.18823529411764706, 0.0078431373, 0.3058823529, 0.9568627451, 0.19215686274509805,
      0.0078431373, 0.2980392157, 0.9803921569, 0.19607843137254902, 0.0078431373, 0.2980392157,
      0.9882352941, 0.2, 0.0078431373, 0.2901960784, 0.9803921569, 0.20392156862745098,
      0.0078431373, 0.2901960784, 0.9647058824, 0.20784313725490197, 0.0078431373, 0.2823529412,
      0.9568627451, 0.21176470588235294, 0.0078431373, 0.2823529412, 0.9411764706,
      0.21568627450980393, 0.0078431373, 0.2745098039, 0.9333333333, 0.2196078431372549,
      0.0078431373, 0.2666666667, 0.9176470588, 0.2235294117647059, 0.0078431373, 0.2666666667,
      0.9098039216, 0.22745098039215686, 0.0078431373, 0.2588235294, 0.9019607843,
      0.23137254901960785, 0.0078431373, 0.2588235294, 0.8862745098, 0.23529411764705885,
      0.0078431373, 0.2509803922, 0.8784313725, 0.23921568627450984, 0.0078431373, 0.2509803922,
      0.862745098, 0.24313725490196078, 0.0078431373, 0.2431372549, 0.8549019608,
      0.24705882352941178, 0.0078431373, 0.2352941176, 0.8392156863, 0.25098039215686274,
      0.0078431373, 0.2352941176, 0.831372549, 0.2549019607843137, 0.0078431373, 0.2274509804,
      0.8235294118, 0.25882352941176473, 0.0078431373, 0.2274509804, 0.8078431373,
      0.2627450980392157, 0.0078431373, 0.2196078431, 0.8, 0.26666666666666666, 0.0078431373,
      0.2196078431, 0.7843137255, 0.27058823529411763, 0.0078431373, 0.2117647059, 0.7764705882,
      0.27450980392156865, 0.0078431373, 0.2039215686, 0.7607843137, 0.2784313725490196,
      0.0078431373, 0.2039215686, 0.7529411765, 0.2823529411764706, 0.0078431373, 0.1960784314,
      0.7450980392, 0.28627450980392155, 0.0078431373, 0.1960784314, 0.7294117647,
      0.2901960784313726, 0.0078431373, 0.1882352941, 0.7215686275, 0.29411764705882354,
      0.0078431373, 0.1882352941, 0.7058823529, 0.2980392156862745, 0.0078431373, 0.1803921569,
      0.6980392157, 0.30196078431372547, 0.0078431373, 0.1803921569, 0.6823529412,
      0.3058823529411765, 0.0078431373, 0.1725490196, 0.6745098039, 0.30980392156862746,
      0.0078431373, 0.1647058824, 0.6666666667, 0.3137254901960784, 0.0078431373, 0.1647058824,
      0.6509803922, 0.3176470588235294, 0.0078431373, 0.1568627451, 0.6431372549,
      0.3215686274509804, 0.0078431373, 0.1568627451, 0.6274509804, 0.3254901960784314,
      0.0078431373, 0.1490196078, 0.6196078431, 0.32941176470588235, 0.0078431373, 0.1490196078,
      0.6039215686, 0.3333333333333333, 0.0078431373, 0.1411764706, 0.5960784314,
      0.33725490196078434, 0.0078431373, 0.1333333333, 0.5882352941, 0.3411764705882353,
      0.0078431373, 0.1333333333, 0.5725490196, 0.34509803921568627, 0.0078431373, 0.1254901961,
      0.5647058824, 0.34901960784313724, 0.0078431373, 0.1254901961, 0.5490196078,
      0.35294117647058826, 0.0078431373, 0.1176470588, 0.5411764706, 0.3568627450980392,
      0.0078431373, 0.1176470588, 0.5254901961, 0.3607843137254902, 0.0078431373, 0.1098039216,
      0.5176470588, 0.36470588235294116, 0.0078431373, 0.1019607843, 0.5098039216,
      0.3686274509803922, 0.0078431373, 0.1019607843, 0.4941176471, 0.37254901960784315,
      0.0078431373, 0.0941176471, 0.4862745098, 0.3764705882352941, 0.0078431373, 0.0941176471,
      0.4705882353, 0.3803921568627451, 0.0078431373, 0.0862745098, 0.462745098, 0.3843137254901961,
      0.0078431373, 0.0862745098, 0.4470588235, 0.38823529411764707, 0.0078431373, 0.0784313725,
      0.4392156863, 0.39215686274509803, 0.0078431373, 0.0705882353, 0.431372549, 0.396078431372549,
      0.0078431373, 0.0705882353, 0.4156862745, 0.4, 0.0078431373, 0.062745098, 0.4078431373,
      0.403921568627451, 0.0078431373, 0.062745098, 0.3921568627, 0.40784313725490196, 0.0078431373,
      0.0549019608, 0.3843137255, 0.4117647058823529, 0.0078431373, 0.0549019608, 0.368627451,
      0.41568627450980394, 0.0078431373, 0.0470588235, 0.3607843137, 0.4196078431372549,
      0.0078431373, 0.0470588235, 0.3529411765, 0.4235294117647059, 0.0078431373, 0.0392156863,
      0.337254902, 0.42745098039215684, 0.0078431373, 0.031372549, 0.3294117647,
      0.43137254901960786, 0.0078431373, 0.031372549, 0.3137254902, 0.43529411764705883,
      0.0078431373, 0.0235294118, 0.3058823529, 0.4392156862745098, 0.0078431373, 0.0235294118,
      0.2901960784, 0.44313725490196076, 0.0078431373, 0.0156862745, 0.2823529412,
      0.4470588235294118, 0.0078431373, 0.0156862745, 0.2745098039, 0.45098039215686275,
      0.0078431373, 0.0078431373, 0.2588235294, 0.4549019607843137, 0.0235294118, 0.0078431373,
      0.2509803922, 0.4588235294117647, 0.0078431373, 0.0078431373, 0.2352941176,
      0.4627450980392157, 0.0078431373, 0.0078431373, 0.2274509804, 0.4666666666666667,
      0.0078431373, 0.0078431373, 0.2117647059, 0.4705882352941177, 0.0078431373, 0.0078431373,
      0.2039215686, 0.4745098039215686, 0.0078431373, 0.0078431373, 0.1960784314,
      0.4784313725490197, 0.0078431373, 0.0078431373, 0.1803921569, 0.48235294117647065,
      0.0078431373, 0.0078431373, 0.1725490196, 0.48627450980392156, 0.0078431373, 0.0078431373,
      0.1568627451, 0.49019607843137253, 0.0078431373, 0.0078431373, 0.1490196078,
      0.49411764705882355, 0.0078431373, 0.0078431373, 0.1333333333, 0.4980392156862745,
      0.0078431373, 0.0078431373, 0.1254901961, 0.5019607843137255, 0.0078431373, 0.0078431373,
      0.1176470588, 0.5058823529411764, 0.0078431373, 0.0078431373, 0.1019607843,
      0.5098039215686274, 0.0078431373, 0.0078431373, 0.0941176471, 0.5137254901960784,
      0.0078431373, 0.0078431373, 0.0784313725, 0.5176470588235295, 0.0078431373, 0.0078431373,
      0.0705882353, 0.5215686274509804, 0.0078431373, 0.0078431373, 0.0549019608,
      0.5254901960784314, 0.0078431373, 0.0078431373, 0.0470588235, 0.5294117647058824,
      0.0235294118, 0.0078431373, 0.0392156863, 0.5333333333333333, 0.031372549, 0.0078431373,
      0.0235294118, 0.5372549019607843, 0.0392156863, 0.0078431373, 0.0156862745,
      0.5411764705882353, 0.0549019608, 0.0078431373, 0.0, 0.5450980392156862, 0.062745098,
      0.0078431373, 0.0, 0.5490196078431373, 0.0705882353, 0.0078431373, 0.0, 0.5529411764705883,
      0.0862745098, 0.0078431373, 0.0, 0.5568627450980392, 0.0941176471, 0.0078431373, 0.0,
      0.5607843137254902, 0.1019607843, 0.0078431373, 0.0, 0.5647058823529412, 0.1098039216,
      0.0078431373, 0.0, 0.5686274509803921, 0.1254901961, 0.0078431373, 0.0, 0.5725490196078431,
      0.1333333333, 0.0078431373, 0.0, 0.5764705882352941, 0.1411764706, 0.0078431373, 0.0,
      0.5803921568627451, 0.1568627451, 0.0078431373, 0.0, 0.5843137254901961, 0.1647058824,
      0.0078431373, 0.0, 0.5882352941176471, 0.1725490196, 0.0078431373, 0.0, 0.592156862745098,
      0.1882352941, 0.0078431373, 0.0, 0.596078431372549, 0.1960784314, 0.0078431373, 0.0, 0.6,
      0.2039215686, 0.0078431373, 0.0, 0.6039215686274509, 0.2117647059, 0.0078431373, 0.0,
      0.6078431372549019, 0.2274509804, 0.0078431373, 0.0, 0.611764705882353, 0.2352941176,
      0.0078431373, 0.0, 0.615686274509804, 0.2431372549, 0.0078431373, 0.0, 0.6196078431372549,
      0.2588235294, 0.0078431373, 0.0, 0.6235294117647059, 0.2666666667, 0.0078431373, 0.0,
      0.6274509803921569, 0.2745098039, 0.0, 0.0, 0.6313725490196078, 0.2901960784, 0.0156862745,
      0.0, 0.6352941176470588, 0.2980392157, 0.0235294118, 0.0, 0.6392156862745098, 0.3058823529,
      0.0392156863, 0.0, 0.6431372549019608, 0.3137254902, 0.0470588235, 0.0, 0.6470588235294118,
      0.3294117647, 0.0549019608, 0.0, 0.6509803921568628, 0.337254902, 0.0705882353, 0.0,
      0.6549019607843137, 0.3450980392, 0.0784313725, 0.0, 0.6588235294117647, 0.3607843137,
      0.0862745098, 0.0, 0.6627450980392157, 0.368627451, 0.1019607843, 0.0, 0.6666666666666666,
      0.3764705882, 0.1098039216, 0.0, 0.6705882352941176, 0.3843137255, 0.1176470588, 0.0,
      0.6745098039215687, 0.4, 0.1333333333, 0.0, 0.6784313725490196, 0.4078431373, 0.1411764706,
      0.0, 0.6823529411764706, 0.4156862745, 0.1490196078, 0.0, 0.6862745098039216, 0.431372549,
      0.1647058824, 0.0, 0.6901960784313725, 0.4392156863, 0.1725490196, 0.0, 0.6941176470588235,
      0.4470588235, 0.1803921569, 0.0, 0.6980392156862745, 0.462745098, 0.1960784314, 0.0,
      0.7019607843137254, 0.4705882353, 0.2039215686, 0.0, 0.7058823529411765, 0.4784313725,
      0.2117647059, 0.0, 0.7098039215686275, 0.4862745098, 0.2274509804, 0.0, 0.7137254901960784,
      0.5019607843, 0.2352941176, 0.0, 0.7176470588235294, 0.5098039216, 0.2431372549, 0.0,
      0.7215686274509804, 0.5176470588, 0.2588235294, 0.0, 0.7254901960784313, 0.5333333333,
      0.2666666667, 0.0, 0.7294117647058823, 0.5411764706, 0.2745098039, 0.0, 0.7333333333333333,
      0.5490196078, 0.2901960784, 0.0, 0.7372549019607844, 0.5647058824, 0.2980392157, 0.0,
      0.7411764705882353, 0.5725490196, 0.3058823529, 0.0, 0.7450980392156863, 0.5803921569,
      0.3215686275, 0.0, 0.7490196078431373, 0.5882352941, 0.3294117647, 0.0, 0.7529411764705882,
      0.6039215686, 0.337254902, 0.0, 0.7568627450980392, 0.6117647059, 0.3529411765, 0.0,
      0.7607843137254902, 0.6196078431, 0.3607843137, 0.0, 0.7647058823529411, 0.6352941176,
      0.368627451, 0.0, 0.7686274509803922, 0.6431372549, 0.3843137255, 0.0, 0.7725490196078432,
      0.6509803922, 0.3921568627, 0.0, 0.7764705882352941, 0.6588235294, 0.4, 0.0,
      0.7803921568627451, 0.6745098039, 0.4156862745, 0.0, 0.7843137254901961, 0.6823529412,
      0.4235294118, 0.0, 0.788235294117647, 0.6901960784, 0.431372549, 0.0, 0.792156862745098,
      0.7058823529, 0.4470588235, 0.0, 0.796078431372549, 0.7137254902, 0.4549019608, 0.0, 0.8,
      0.7215686275, 0.462745098, 0.0, 0.803921568627451, 0.737254902, 0.4784313725, 0.0,
      0.807843137254902, 0.7450980392, 0.4862745098, 0.0, 0.8117647058823529, 0.7529411765,
      0.4941176471, 0.0, 0.8156862745098039, 0.7607843137, 0.5098039216, 0.0, 0.8196078431372549,
      0.7764705882, 0.5176470588, 0.0, 0.8235294117647058, 0.7843137255, 0.5254901961, 0.0,
      0.8274509803921568, 0.7921568627, 0.5411764706, 0.0, 0.8313725490196079, 0.8078431373,
      0.5490196078, 0.0, 0.8352941176470589, 0.8156862745, 0.5568627451, 0.0, 0.8392156862745098,
      0.8235294118, 0.5725490196, 0.0, 0.8431372549019608, 0.8392156863, 0.5803921569, 0.0,
      0.8470588235294118, 0.8470588235, 0.5882352941, 0.0, 0.8509803921568627, 0.8549019608,
      0.6039215686, 0.0, 0.8549019607843137, 0.862745098, 0.6117647059, 0.0, 0.8588235294117647,
      0.8784313725, 0.6196078431, 0.0, 0.8627450980392157, 0.8862745098, 0.6352941176, 0.0,
      0.8666666666666667, 0.8941176471, 0.6431372549, 0.0, 0.8705882352941177, 0.9098039216,
      0.6509803922, 0.0, 0.8745098039215686, 0.9176470588, 0.6666666667, 0.0, 0.8784313725490196,
      0.9254901961, 0.6745098039, 0.0, 0.8823529411764706, 0.9411764706, 0.6823529412, 0.0,
      0.8862745098039215, 0.9490196078, 0.6980392157, 0.0, 0.8901960784313725, 0.9568627451,
      0.7058823529, 0.0, 0.8941176470588236, 0.9647058824, 0.7137254902, 0.0, 0.8980392156862745,
      0.9803921569, 0.7294117647, 0.0, 0.9019607843137255, 0.9882352941, 0.737254902, 0.0,
      0.9058823529411765, 0.9960784314, 0.7450980392, 0.0, 0.9098039215686274, 0.9960784314,
      0.7607843137, 0.0, 0.9137254901960784, 0.9960784314, 0.768627451, 0.0, 0.9176470588235294,
      0.9960784314, 0.7764705882, 0.0, 0.9215686274509803, 0.9960784314, 0.7921568627, 0.0,
      0.9254901960784314, 0.9960784314, 0.8, 0.0, 0.9294117647058824, 0.9960784314, 0.8078431373,
      0.0, 0.9333333333333333, 0.9960784314, 0.8235294118, 0.0, 0.9372549019607843, 0.9960784314,
      0.831372549, 0.0, 0.9411764705882354, 0.9960784314, 0.8392156863, 0.0, 0.9450980392156864,
      0.9960784314, 0.8549019608, 0.0, 0.9490196078431372, 0.9960784314, 0.862745098, 0.0549019608,
      0.9529411764705882, 0.9960784314, 0.8705882353, 0.1098039216, 0.9568627450980394,
      0.9960784314, 0.8862745098, 0.1647058824, 0.9607843137254903, 0.9960784314, 0.8941176471,
      0.2196078431, 0.9647058823529413, 0.9960784314, 0.9019607843, 0.2666666667,
      0.9686274509803922, 0.9960784314, 0.9176470588, 0.3215686275, 0.9725490196078431,
      0.9960784314, 0.9254901961, 0.3764705882, 0.9764705882352941, 0.9960784314, 0.9333333333,
      0.431372549, 0.9803921568627451, 0.9960784314, 0.9490196078, 0.4862745098, 0.984313725490196,
      0.9960784314, 0.9568627451, 0.5333333333, 0.9882352941176471, 0.9960784314, 0.9647058824,
      0.5882352941, 0.9921568627450981, 0.9960784314, 0.9803921569, 0.6431372549, 0.996078431372549,
      0.9960784314, 0.9882352941, 0.6980392157, 1.0, 0.9960784314, 0.9960784314, 0.7450980392,
    ],
    description: 'Perfusion',
  },
  {
    ColorSpace: 'RGB',
    Name: 'rainbow_2',
    name: 'rainbow_2',
    RGBPoints: [
      0.0, 0.0, 0.0, 0.0, 0.00392156862745098, 0.0156862745, 0.0, 0.0117647059, 0.00784313725490196,
      0.0352941176, 0.0, 0.0274509804, 0.011764705882352941, 0.0509803922, 0.0, 0.0392156863,
      0.01568627450980392, 0.0705882353, 0.0, 0.0549019608, 0.0196078431372549, 0.0862745098, 0.0,
      0.0745098039, 0.023529411764705882, 0.1058823529, 0.0, 0.0901960784, 0.027450980392156862,
      0.1215686275, 0.0, 0.1098039216, 0.03137254901960784, 0.1411764706, 0.0, 0.1254901961,
      0.03529411764705882, 0.1568627451, 0.0, 0.1490196078, 0.0392156862745098, 0.1764705882, 0.0,
      0.168627451, 0.043137254901960784, 0.1960784314, 0.0, 0.1882352941, 0.047058823529411764,
      0.2117647059, 0.0, 0.2078431373, 0.050980392156862744, 0.2274509804, 0.0, 0.231372549,
      0.054901960784313725, 0.2392156863, 0.0, 0.2470588235, 0.05882352941176471, 0.2509803922, 0.0,
      0.2666666667, 0.06274509803921569, 0.2666666667, 0.0, 0.2823529412, 0.06666666666666667,
      0.2705882353, 0.0, 0.3019607843, 0.07058823529411765, 0.2823529412, 0.0, 0.3176470588,
      0.07450980392156863, 0.2901960784, 0.0, 0.337254902, 0.0784313725490196, 0.3019607843, 0.0,
      0.3568627451, 0.08235294117647059, 0.3098039216, 0.0, 0.3725490196, 0.08627450980392157,
      0.3137254902, 0.0, 0.3921568627, 0.09019607843137255, 0.3215686275, 0.0, 0.4078431373,
      0.09411764705882353, 0.3254901961, 0.0, 0.4274509804, 0.09803921568627451, 0.3333333333, 0.0,
      0.4431372549, 0.10196078431372549, 0.3294117647, 0.0, 0.462745098, 0.10588235294117647,
      0.337254902, 0.0, 0.4784313725, 0.10980392156862745, 0.3411764706, 0.0, 0.4980392157,
      0.11372549019607843, 0.3450980392, 0.0, 0.5176470588, 0.11764705882352942, 0.337254902, 0.0,
      0.5333333333, 0.12156862745098039, 0.3411764706, 0.0, 0.5529411765, 0.12549019607843137,
      0.3411764706, 0.0, 0.568627451, 0.12941176470588237, 0.3411764706, 0.0, 0.5882352941,
      0.13333333333333333, 0.3333333333, 0.0, 0.6039215686, 0.13725490196078433, 0.3294117647, 0.0,
      0.6235294118, 0.1411764705882353, 0.3294117647, 0.0, 0.6392156863, 0.1450980392156863,
      0.3294117647, 0.0, 0.6588235294, 0.14901960784313725, 0.3254901961, 0.0, 0.6784313725,
      0.15294117647058825, 0.3098039216, 0.0, 0.6941176471, 0.1568627450980392, 0.3058823529, 0.0,
      0.7137254902, 0.1607843137254902, 0.3019607843, 0.0, 0.7294117647, 0.16470588235294117,
      0.2980392157, 0.0, 0.7490196078, 0.16862745098039217, 0.2784313725, 0.0, 0.7647058824,
      0.17254901960784313, 0.2745098039, 0.0, 0.7843137255, 0.17647058823529413, 0.2666666667, 0.0,
      0.8, 0.1803921568627451, 0.2588235294, 0.0, 0.8196078431, 0.1843137254901961, 0.2352941176,
      0.0, 0.8392156863, 0.18823529411764706, 0.2274509804, 0.0, 0.8549019608, 0.19215686274509805,
      0.2156862745, 0.0, 0.8745098039, 0.19607843137254902, 0.2078431373, 0.0, 0.8901960784, 0.2,
      0.1803921569, 0.0, 0.9098039216, 0.20392156862745098, 0.168627451, 0.0, 0.9254901961,
      0.20784313725490197, 0.1568627451, 0.0, 0.9450980392, 0.21176470588235294, 0.1411764706, 0.0,
      0.9607843137, 0.21568627450980393, 0.1294117647, 0.0, 0.9803921569, 0.2196078431372549,
      0.0980392157, 0.0, 1.0, 0.2235294117647059, 0.0823529412, 0.0, 1.0, 0.22745098039215686,
      0.062745098, 0.0, 1.0, 0.23137254901960785, 0.0470588235, 0.0, 1.0, 0.23529411764705885,
      0.0156862745, 0.0, 1.0, 0.23921568627450984, 0.0, 0.0, 1.0, 0.24313725490196078, 0.0,
      0.0156862745, 1.0, 0.24705882352941178, 0.0, 0.031372549, 1.0, 0.25098039215686274, 0.0,
      0.062745098, 1.0, 0.2549019607843137, 0.0, 0.0823529412, 1.0, 0.25882352941176473, 0.0,
      0.0980392157, 1.0, 0.2627450980392157, 0.0, 0.1137254902, 1.0, 0.26666666666666666, 0.0,
      0.1490196078, 1.0, 0.27058823529411763, 0.0, 0.1647058824, 1.0, 0.27450980392156865, 0.0,
      0.1803921569, 1.0, 0.2784313725490196, 0.0, 0.2, 1.0, 0.2823529411764706, 0.0, 0.2156862745,
      1.0, 0.28627450980392155, 0.0, 0.2470588235, 1.0, 0.2901960784313726, 0.0, 0.262745098, 1.0,
      0.29411764705882354, 0.0, 0.2823529412, 1.0, 0.2980392156862745, 0.0, 0.2980392157, 1.0,
      0.30196078431372547, 0.0, 0.3294117647, 1.0, 0.3058823529411765, 0.0, 0.3490196078, 1.0,
      0.30980392156862746, 0.0, 0.3647058824, 1.0, 0.3137254901960784, 0.0, 0.3803921569, 1.0,
      0.3176470588235294, 0.0, 0.4156862745, 1.0, 0.3215686274509804, 0.0, 0.431372549, 1.0,
      0.3254901960784314, 0.0, 0.4470588235, 1.0, 0.32941176470588235, 0.0, 0.4666666667, 1.0,
      0.3333333333333333, 0.0, 0.4980392157, 1.0, 0.33725490196078434, 0.0, 0.5137254902, 1.0,
      0.3411764705882353, 0.0, 0.5294117647, 1.0, 0.34509803921568627, 0.0, 0.5490196078, 1.0,
      0.34901960784313724, 0.0, 0.5647058824, 1.0, 0.35294117647058826, 0.0, 0.5960784314, 1.0,
      0.3568627450980392, 0.0, 0.6156862745, 1.0, 0.3607843137254902, 0.0, 0.631372549, 1.0,
      0.36470588235294116, 0.0, 0.6470588235, 1.0, 0.3686274509803922, 0.0, 0.6823529412, 1.0,
      0.37254901960784315, 0.0, 0.6980392157, 1.0, 0.3764705882352941, 0.0, 0.7137254902, 1.0,
      0.3803921568627451, 0.0, 0.7333333333, 1.0, 0.3843137254901961, 0.0, 0.7647058824, 1.0,
      0.38823529411764707, 0.0, 0.7803921569, 1.0, 0.39215686274509803, 0.0, 0.7960784314, 1.0,
      0.396078431372549, 0.0, 0.8156862745, 1.0, 0.4, 0.0, 0.8470588235, 1.0, 0.403921568627451,
      0.0, 0.862745098, 1.0, 0.40784313725490196, 0.0, 0.8823529412, 1.0, 0.4117647058823529, 0.0,
      0.8980392157, 1.0, 0.41568627450980394, 0.0, 0.9137254902, 1.0, 0.4196078431372549, 0.0,
      0.9490196078, 1.0, 0.4235294117647059, 0.0, 0.9647058824, 1.0, 0.42745098039215684, 0.0,
      0.9803921569, 1.0, 0.43137254901960786, 0.0, 1.0, 1.0, 0.43529411764705883, 0.0, 1.0,
      0.9647058824, 0.4392156862745098, 0.0, 1.0, 0.9490196078, 0.44313725490196076, 0.0, 1.0,
      0.9333333333, 0.4470588235294118, 0.0, 1.0, 0.9137254902, 0.45098039215686275, 0.0, 1.0,
      0.8823529412, 0.4549019607843137, 0.0, 1.0, 0.862745098, 0.4588235294117647, 0.0, 1.0,
      0.8470588235, 0.4627450980392157, 0.0, 1.0, 0.831372549, 0.4666666666666667, 0.0, 1.0,
      0.7960784314, 0.4705882352941177, 0.0, 1.0, 0.7803921569, 0.4745098039215686, 0.0, 1.0,
      0.7647058824, 0.4784313725490197, 0.0, 1.0, 0.7490196078, 0.48235294117647065, 0.0, 1.0,
      0.7333333333, 0.48627450980392156, 0.0, 1.0, 0.6980392157, 0.49019607843137253, 0.0, 1.0,
      0.6823529412, 0.49411764705882355, 0.0, 1.0, 0.6666666667, 0.4980392156862745, 0.0, 1.0,
      0.6470588235, 0.5019607843137255, 0.0, 1.0, 0.6156862745, 0.5058823529411764, 0.0, 1.0,
      0.5960784314, 0.5098039215686274, 0.0, 1.0, 0.5803921569, 0.5137254901960784, 0.0, 1.0,
      0.5647058824, 0.5176470588235295, 0.0, 1.0, 0.5294117647, 0.5215686274509804, 0.0, 1.0,
      0.5137254902, 0.5254901960784314, 0.0, 1.0, 0.4980392157, 0.5294117647058824, 0.0, 1.0,
      0.4823529412, 0.5333333333333333, 0.0, 1.0, 0.4470588235, 0.5372549019607843, 0.0, 1.0,
      0.431372549, 0.5411764705882353, 0.0, 1.0, 0.4156862745, 0.5450980392156862, 0.0, 1.0, 0.4,
      0.5490196078431373, 0.0, 1.0, 0.3803921569, 0.5529411764705883, 0.0, 1.0, 0.3490196078,
      0.5568627450980392, 0.0, 1.0, 0.3294117647, 0.5607843137254902, 0.0, 1.0, 0.3137254902,
      0.5647058823529412, 0.0, 1.0, 0.2980392157, 0.5686274509803921, 0.0, 1.0, 0.262745098,
      0.5725490196078431, 0.0, 1.0, 0.2470588235, 0.5764705882352941, 0.0, 1.0, 0.231372549,
      0.5803921568627451, 0.0, 1.0, 0.2156862745, 0.5843137254901961, 0.0, 1.0, 0.1803921569,
      0.5882352941176471, 0.0, 1.0, 0.1647058824, 0.592156862745098, 0.0, 1.0, 0.1490196078,
      0.596078431372549, 0.0, 1.0, 0.1333333333, 0.6, 0.0, 1.0, 0.0980392157, 0.6039215686274509,
      0.0, 1.0, 0.0823529412, 0.6078431372549019, 0.0, 1.0, 0.062745098, 0.611764705882353, 0.0,
      1.0, 0.0470588235, 0.615686274509804, 0.0, 1.0, 0.031372549, 0.6196078431372549, 0.0, 1.0,
      0.0, 0.6235294117647059, 0.0156862745, 1.0, 0.0, 0.6274509803921569, 0.031372549, 1.0, 0.0,
      0.6313725490196078, 0.0470588235, 1.0, 0.0, 0.6352941176470588, 0.0823529412, 1.0, 0.0,
      0.6392156862745098, 0.0980392157, 1.0, 0.0, 0.6431372549019608, 0.1137254902, 1.0, 0.0,
      0.6470588235294118, 0.1294117647, 1.0, 0.0, 0.6509803921568628, 0.1647058824, 1.0, 0.0,
      0.6549019607843137, 0.1803921569, 1.0, 0.0, 0.6588235294117647, 0.2, 1.0, 0.0,
      0.6627450980392157, 0.2156862745, 1.0, 0.0, 0.6666666666666666, 0.2470588235, 1.0, 0.0,
      0.6705882352941176, 0.262745098, 1.0, 0.0, 0.6745098039215687, 0.2823529412, 1.0, 0.0,
      0.6784313725490196, 0.2980392157, 1.0, 0.0, 0.6823529411764706, 0.3137254902, 1.0, 0.0,
      0.6862745098039216, 0.3490196078, 1.0, 0.0, 0.6901960784313725, 0.3647058824, 1.0, 0.0,
      0.6941176470588235, 0.3803921569, 1.0, 0.0, 0.6980392156862745, 0.3960784314, 1.0, 0.0,
      0.7019607843137254, 0.431372549, 1.0, 0.0, 0.7058823529411765, 0.4470588235, 1.0, 0.0,
      0.7098039215686275, 0.4666666667, 1.0, 0.0, 0.7137254901960784, 0.4823529412, 1.0, 0.0,
      0.7176470588235294, 0.5137254902, 1.0, 0.0, 0.7215686274509804, 0.5294117647, 1.0, 0.0,
      0.7254901960784313, 0.5490196078, 1.0, 0.0, 0.7294117647058823, 0.5647058824, 1.0, 0.0,
      0.7333333333333333, 0.6, 1.0, 0.0, 0.7372549019607844, 0.6156862745, 1.0, 0.0,
      0.7411764705882353, 0.631372549, 1.0, 0.0, 0.7450980392156863, 0.6470588235, 1.0, 0.0,
      0.7490196078431373, 0.662745098, 1.0, 0.0, 0.7529411764705882, 0.6980392157, 1.0, 0.0,
      0.7568627450980392, 0.7137254902, 1.0, 0.0, 0.7607843137254902, 0.7333333333, 1.0, 0.0,
      0.7647058823529411, 0.7490196078, 1.0, 0.0, 0.7686274509803922, 0.7803921569, 1.0, 0.0,
      0.7725490196078432, 0.7960784314, 1.0, 0.0, 0.7764705882352941, 0.8156862745, 1.0, 0.0,
      0.7803921568627451, 0.831372549, 1.0, 0.0, 0.7843137254901961, 0.8666666667, 1.0, 0.0,
      0.788235294117647, 0.8823529412, 1.0, 0.0, 0.792156862745098, 0.8980392157, 1.0, 0.0,
      0.796078431372549, 0.9137254902, 1.0, 0.0, 0.8, 0.9490196078, 1.0, 0.0, 0.803921568627451,
      0.9647058824, 1.0, 0.0, 0.807843137254902, 0.9803921569, 1.0, 0.0, 0.8117647058823529, 1.0,
      1.0, 0.0, 0.8156862745098039, 1.0, 0.9803921569, 0.0, 0.8196078431372549, 1.0, 0.9490196078,
      0.0, 0.8235294117647058, 1.0, 0.9333333333, 0.0, 0.8274509803921568, 1.0, 0.9137254902, 0.0,
      0.8313725490196079, 1.0, 0.8980392157, 0.0, 0.8352941176470589, 1.0, 0.8666666667, 0.0,
      0.8392156862745098, 1.0, 0.8470588235, 0.0, 0.8431372549019608, 1.0, 0.831372549, 0.0,
      0.8470588235294118, 1.0, 0.8156862745, 0.0, 0.8509803921568627, 1.0, 0.7803921569, 0.0,
      0.8549019607843137, 1.0, 0.7647058824, 0.0, 0.8588235294117647, 1.0, 0.7490196078, 0.0,
      0.8627450980392157, 1.0, 0.7333333333, 0.0, 0.8666666666666667, 1.0, 0.6980392157, 0.0,
      0.8705882352941177, 1.0, 0.6823529412, 0.0, 0.8745098039215686, 1.0, 0.6666666667, 0.0,
      0.8784313725490196, 1.0, 0.6470588235, 0.0, 0.8823529411764706, 1.0, 0.631372549, 0.0,
      0.8862745098039215, 1.0, 0.6, 0.0, 0.8901960784313725, 1.0, 0.5803921569, 0.0,
      0.8941176470588236, 1.0, 0.5647058824, 0.0, 0.8980392156862745, 1.0, 0.5490196078, 0.0,
      0.9019607843137255, 1.0, 0.5137254902, 0.0, 0.9058823529411765, 1.0, 0.4980392157, 0.0,
      0.9098039215686274, 1.0, 0.4823529412, 0.0, 0.9137254901960784, 1.0, 0.4666666667, 0.0,
      0.9176470588235294, 1.0, 0.431372549, 0.0, 0.9215686274509803, 1.0, 0.4156862745, 0.0,
      0.9254901960784314, 1.0, 0.4, 0.0, 0.9294117647058824, 1.0, 0.3803921569, 0.0,
      0.9333333333333333, 1.0, 0.3490196078, 0.0, 0.9372549019607843, 1.0, 0.3333333333, 0.0,
      0.9411764705882354, 1.0, 0.3137254902, 0.0, 0.9450980392156864, 1.0, 0.2980392157, 0.0,
      0.9490196078431372, 1.0, 0.2823529412, 0.0, 0.9529411764705882, 1.0, 0.2470588235, 0.0,
      0.9568627450980394, 1.0, 0.231372549, 0.0, 0.9607843137254903, 1.0, 0.2156862745, 0.0,
      0.9647058823529413, 1.0, 0.2, 0.0, 0.9686274509803922, 1.0, 0.1647058824, 0.0,
      0.9725490196078431, 1.0, 0.1490196078, 0.0, 0.9764705882352941, 1.0, 0.1333333333, 0.0,
      0.9803921568627451, 1.0, 0.1137254902, 0.0, 0.984313725490196, 1.0, 0.0823529412, 0.0,
      0.9882352941176471, 1.0, 0.0666666667, 0.0, 0.9921568627450981, 1.0, 0.0470588235, 0.0,
      0.996078431372549, 1.0, 0.031372549, 0.0, 1.0, 1.0, 0.0, 0.0,
    ],
    description: 'Rainbow',
  },
  {
    ColorSpace: 'RGB',
    Name: 'suv',
    name: 'suv',
    RGBPoints: [
      0.0, 1.0, 1.0, 1.0, 0.00392156862745098, 1.0, 1.0, 1.0, 0.00784313725490196, 1.0, 1.0, 1.0,
      0.011764705882352941, 1.0, 1.0, 1.0, 0.01568627450980392, 1.0, 1.0, 1.0, 0.0196078431372549,
      1.0, 1.0, 1.0, 0.023529411764705882, 1.0, 1.0, 1.0, 0.027450980392156862, 1.0, 1.0, 1.0,
      0.03137254901960784, 1.0, 1.0, 1.0, 0.03529411764705882, 1.0, 1.0, 1.0, 0.0392156862745098,
      1.0, 1.0, 1.0, 0.043137254901960784, 1.0, 1.0, 1.0, 0.047058823529411764, 1.0, 1.0, 1.0,
      0.050980392156862744, 1.0, 1.0, 1.0, 0.054901960784313725, 1.0, 1.0, 1.0, 0.05882352941176471,
      1.0, 1.0, 1.0, 0.06274509803921569, 1.0, 1.0, 1.0, 0.06666666666666667, 1.0, 1.0, 1.0,
      0.07058823529411765, 1.0, 1.0, 1.0, 0.07450980392156863, 1.0, 1.0, 1.0, 0.0784313725490196,
      1.0, 1.0, 1.0, 0.08235294117647059, 1.0, 1.0, 1.0, 0.08627450980392157, 1.0, 1.0, 1.0,
      0.09019607843137255, 1.0, 1.0, 1.0, 0.09411764705882353, 1.0, 1.0, 1.0, 0.09803921568627451,
      1.0, 1.0, 1.0, 0.10196078431372549, 0.737254902, 0.737254902, 0.737254902,
      0.10588235294117647, 0.737254902, 0.737254902, 0.737254902, 0.10980392156862745, 0.737254902,
      0.737254902, 0.737254902, 0.11372549019607843, 0.737254902, 0.737254902, 0.737254902,
      0.11764705882352942, 0.737254902, 0.737254902, 0.737254902, 0.12156862745098039, 0.737254902,
      0.737254902, 0.737254902, 0.12549019607843137, 0.737254902, 0.737254902, 0.737254902,
      0.12941176470588237, 0.737254902, 0.737254902, 0.737254902, 0.13333333333333333, 0.737254902,
      0.737254902, 0.737254902, 0.13725490196078433, 0.737254902, 0.737254902, 0.737254902,
      0.1411764705882353, 0.737254902, 0.737254902, 0.737254902, 0.1450980392156863, 0.737254902,
      0.737254902, 0.737254902, 0.14901960784313725, 0.737254902, 0.737254902, 0.737254902,
      0.15294117647058825, 0.737254902, 0.737254902, 0.737254902, 0.1568627450980392, 0.737254902,
      0.737254902, 0.737254902, 0.1607843137254902, 0.737254902, 0.737254902, 0.737254902,
      0.16470588235294117, 0.737254902, 0.737254902, 0.737254902, 0.16862745098039217, 0.737254902,
      0.737254902, 0.737254902, 0.17254901960784313, 0.737254902, 0.737254902, 0.737254902,
      0.17647058823529413, 0.737254902, 0.737254902, 0.737254902, 0.1803921568627451, 0.737254902,
      0.737254902, 0.737254902, 0.1843137254901961, 0.737254902, 0.737254902, 0.737254902,
      0.18823529411764706, 0.737254902, 0.737254902, 0.737254902, 0.19215686274509805, 0.737254902,
      0.737254902, 0.737254902, 0.19607843137254902, 0.737254902, 0.737254902, 0.737254902, 0.2,
      0.737254902, 0.737254902, 0.737254902, 0.20392156862745098, 0.431372549, 0.0, 0.568627451,
      0.20784313725490197, 0.431372549, 0.0, 0.568627451, 0.21176470588235294, 0.431372549, 0.0,
      0.568627451, 0.21568627450980393, 0.431372549, 0.0, 0.568627451, 0.2196078431372549,
      0.431372549, 0.0, 0.568627451, 0.2235294117647059, 0.431372549, 0.0, 0.568627451,
      0.22745098039215686, 0.431372549, 0.0, 0.568627451, 0.23137254901960785, 0.431372549, 0.0,
      0.568627451, 0.23529411764705885, 0.431372549, 0.0, 0.568627451, 0.23921568627450984,
      0.431372549, 0.0, 0.568627451, 0.24313725490196078, 0.431372549, 0.0, 0.568627451,
      0.24705882352941178, 0.431372549, 0.0, 0.568627451, 0.25098039215686274, 0.431372549, 0.0,
      0.568627451, 0.2549019607843137, 0.431372549, 0.0, 0.568627451, 0.25882352941176473,
      0.431372549, 0.0, 0.568627451, 0.2627450980392157, 0.431372549, 0.0, 0.568627451,
      0.26666666666666666, 0.431372549, 0.0, 0.568627451, 0.27058823529411763, 0.431372549, 0.0,
      0.568627451, 0.27450980392156865, 0.431372549, 0.0, 0.568627451, 0.2784313725490196,
      0.431372549, 0.0, 0.568627451, 0.2823529411764706, 0.431372549, 0.0, 0.568627451,
      0.28627450980392155, 0.431372549, 0.0, 0.568627451, 0.2901960784313726, 0.431372549, 0.0,
      0.568627451, 0.29411764705882354, 0.431372549, 0.0, 0.568627451, 0.2980392156862745,
      0.431372549, 0.0, 0.568627451, 0.30196078431372547, 0.431372549, 0.0, 0.568627451,
      0.3058823529411765, 0.2509803922, 0.3333333333, 0.6509803922, 0.30980392156862746,
      0.2509803922, 0.3333333333, 0.6509803922, 0.3137254901960784, 0.2509803922, 0.3333333333,
      0.6509803922, 0.3176470588235294, 0.2509803922, 0.3333333333, 0.6509803922,
      0.3215686274509804, 0.2509803922, 0.3333333333, 0.6509803922, 0.3254901960784314,
      0.2509803922, 0.3333333333, 0.6509803922, 0.32941176470588235, 0.2509803922, 0.3333333333,
      0.6509803922, 0.3333333333333333, 0.2509803922, 0.3333333333, 0.6509803922,
      0.33725490196078434, 0.2509803922, 0.3333333333, 0.6509803922, 0.3411764705882353,
      0.2509803922, 0.3333333333, 0.6509803922, 0.34509803921568627, 0.2509803922, 0.3333333333,
      0.6509803922, 0.34901960784313724, 0.2509803922, 0.3333333333, 0.6509803922,
      0.35294117647058826, 0.2509803922, 0.3333333333, 0.6509803922, 0.3568627450980392,
      0.2509803922, 0.3333333333, 0.6509803922, 0.3607843137254902, 0.2509803922, 0.3333333333,
      0.6509803922, 0.36470588235294116, 0.2509803922, 0.3333333333, 0.6509803922,
      0.3686274509803922, 0.2509803922, 0.3333333333, 0.6509803922, 0.37254901960784315,
      0.2509803922, 0.3333333333, 0.6509803922, 0.3764705882352941, 0.2509803922, 0.3333333333,
      0.6509803922, 0.3803921568627451, 0.2509803922, 0.3333333333, 0.6509803922,
      0.3843137254901961, 0.2509803922, 0.3333333333, 0.6509803922, 0.38823529411764707,
      0.2509803922, 0.3333333333, 0.6509803922, 0.39215686274509803, 0.2509803922, 0.3333333333,
      0.6509803922, 0.396078431372549, 0.2509803922, 0.3333333333, 0.6509803922, 0.4, 0.2509803922,
      0.3333333333, 0.6509803922, 0.403921568627451, 0.2509803922, 0.3333333333, 0.6509803922,
      0.40784313725490196, 0.0, 0.8, 1.0, 0.4117647058823529, 0.0, 0.8, 1.0, 0.41568627450980394,
      0.0, 0.8, 1.0, 0.4196078431372549, 0.0, 0.8, 1.0, 0.4235294117647059, 0.0, 0.8, 1.0,
      0.42745098039215684, 0.0, 0.8, 1.0, 0.43137254901960786, 0.0, 0.8, 1.0, 0.43529411764705883,
      0.0, 0.8, 1.0, 0.4392156862745098, 0.0, 0.8, 1.0, 0.44313725490196076, 0.0, 0.8, 1.0,
      0.4470588235294118, 0.0, 0.8, 1.0, 0.45098039215686275, 0.0, 0.8, 1.0, 0.4549019607843137,
      0.0, 0.8, 1.0, 0.4588235294117647, 0.0, 0.8, 1.0, 0.4627450980392157, 0.0, 0.8, 1.0,
      0.4666666666666667, 0.0, 0.8, 1.0, 0.4705882352941177, 0.0, 0.8, 1.0, 0.4745098039215686, 0.0,
      0.8, 1.0, 0.4784313725490197, 0.0, 0.8, 1.0, 0.48235294117647065, 0.0, 0.8, 1.0,
      0.48627450980392156, 0.0, 0.8, 1.0, 0.49019607843137253, 0.0, 0.8, 1.0, 0.49411764705882355,
      0.0, 0.8, 1.0, 0.4980392156862745, 0.0, 0.8, 1.0, 0.5019607843137255, 0.0, 0.8, 1.0,
      0.5058823529411764, 0.0, 0.6666666667, 0.5333333333, 0.5098039215686274, 0.0, 0.6666666667,
      0.5333333333, 0.5137254901960784, 0.0, 0.6666666667, 0.5333333333, 0.5176470588235295, 0.0,
      0.6666666667, 0.5333333333, 0.5215686274509804, 0.0, 0.6666666667, 0.5333333333,
      0.5254901960784314, 0.0, 0.6666666667, 0.5333333333, 0.5294117647058824, 0.0, 0.6666666667,
      0.5333333333, 0.5333333333333333, 0.0, 0.6666666667, 0.5333333333, 0.5372549019607843, 0.0,
      0.6666666667, 0.5333333333, 0.5411764705882353, 0.0, 0.6666666667, 0.5333333333,
      0.5450980392156862, 0.0, 0.6666666667, 0.5333333333, 0.5490196078431373, 0.0, 0.6666666667,
      0.5333333333, 0.5529411764705883, 0.0, 0.6666666667, 0.5333333333, 0.5568627450980392, 0.0,
      0.6666666667, 0.5333333333, 0.5607843137254902, 0.0, 0.6666666667, 0.5333333333,
      0.5647058823529412, 0.0, 0.6666666667, 0.5333333333, 0.5686274509803921, 0.0, 0.6666666667,
      0.5333333333, 0.5725490196078431, 0.0, 0.6666666667, 0.5333333333, 0.5764705882352941, 0.0,
      0.6666666667, 0.5333333333, 0.5803921568627451, 0.0, 0.6666666667, 0.5333333333,
      0.5843137254901961, 0.0, 0.6666666667, 0.5333333333, 0.5882352941176471, 0.0, 0.6666666667,
      0.5333333333, 0.592156862745098, 0.0, 0.6666666667, 0.5333333333, 0.596078431372549, 0.0,
      0.6666666667, 0.5333333333, 0.6, 0.0, 0.6666666667, 0.5333333333, 0.6039215686274509, 0.0,
      0.6666666667, 0.5333333333, 0.6078431372549019, 0.4, 1.0, 0.4, 0.611764705882353, 0.4, 1.0,
      0.4, 0.615686274509804, 0.4, 1.0, 0.4, 0.6196078431372549, 0.4, 1.0, 0.4, 0.6235294117647059,
      0.4, 1.0, 0.4, 0.6274509803921569, 0.4, 1.0, 0.4, 0.6313725490196078, 0.4, 1.0, 0.4,
      0.6352941176470588, 0.4, 1.0, 0.4, 0.6392156862745098, 0.4, 1.0, 0.4, 0.6431372549019608, 0.4,
      1.0, 0.4, 0.6470588235294118, 0.4, 1.0, 0.4, 0.6509803921568628, 0.4, 1.0, 0.4,
      0.6549019607843137, 0.4, 1.0, 0.4, 0.6588235294117647, 0.4, 1.0, 0.4, 0.6627450980392157, 0.4,
      1.0, 0.4, 0.6666666666666666, 0.4, 1.0, 0.4, 0.6705882352941176, 0.4, 1.0, 0.4,
      0.6745098039215687, 0.4, 1.0, 0.4, 0.6784313725490196, 0.4, 1.0, 0.4, 0.6823529411764706, 0.4,
      1.0, 0.4, 0.6862745098039216, 0.4, 1.0, 0.4, 0.6901960784313725, 0.4, 1.0, 0.4,
      0.6941176470588235, 0.4, 1.0, 0.4, 0.6980392156862745, 0.4, 1.0, 0.4, 0.7019607843137254, 0.4,
      1.0, 0.4, 0.7058823529411765, 1.0, 0.9490196078, 0.0, 0.7098039215686275, 1.0, 0.9490196078,
      0.0, 0.7137254901960784, 1.0, 0.9490196078, 0.0, 0.7176470588235294, 1.0, 0.9490196078, 0.0,
      0.7215686274509804, 1.0, 0.9490196078, 0.0, 0.7254901960784313, 1.0, 0.9490196078, 0.0,
      0.7294117647058823, 1.0, 0.9490196078, 0.0, 0.7333333333333333, 1.0, 0.9490196078, 0.0,
      0.7372549019607844, 1.0, 0.9490196078, 0.0, 0.7411764705882353, 1.0, 0.9490196078, 0.0,
      0.7450980392156863, 1.0, 0.9490196078, 0.0, 0.7490196078431373, 1.0, 0.9490196078, 0.0,
      0.7529411764705882, 1.0, 0.9490196078, 0.0, 0.7568627450980392, 1.0, 0.9490196078, 0.0,
      0.7607843137254902, 1.0, 0.9490196078, 0.0, 0.7647058823529411, 1.0, 0.9490196078, 0.0,
      0.7686274509803922, 1.0, 0.9490196078, 0.0, 0.7725490196078432, 1.0, 0.9490196078, 0.0,
      0.7764705882352941, 1.0, 0.9490196078, 0.0, 0.7803921568627451, 1.0, 0.9490196078, 0.0,
      0.7843137254901961, 1.0, 0.9490196078, 0.0, 0.788235294117647, 1.0, 0.9490196078, 0.0,
      0.792156862745098, 1.0, 0.9490196078, 0.0, 0.796078431372549, 1.0, 0.9490196078, 0.0, 0.8,
      1.0, 0.9490196078, 0.0, 0.803921568627451, 1.0, 0.9490196078, 0.0, 0.807843137254902,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8117647058823529, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8156862745098039, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8196078431372549, 0.9490196078, 0.6509803922, 0.2509803922, 0.8235294117647058,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8274509803921568, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8313725490196079, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8352941176470589, 0.9490196078, 0.6509803922, 0.2509803922, 0.8392156862745098,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8431372549019608, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8470588235294118, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8509803921568627, 0.9490196078, 0.6509803922, 0.2509803922, 0.8549019607843137,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8588235294117647, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8627450980392157, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8666666666666667, 0.9490196078, 0.6509803922, 0.2509803922, 0.8705882352941177,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8745098039215686, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8784313725490196, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8823529411764706, 0.9490196078, 0.6509803922, 0.2509803922, 0.8862745098039215,
      0.9490196078, 0.6509803922, 0.2509803922, 0.8901960784313725, 0.9490196078, 0.6509803922,
      0.2509803922, 0.8941176470588236, 0.9490196078, 0.6509803922, 0.2509803922,
      0.8980392156862745, 0.9490196078, 0.6509803922, 0.2509803922, 0.9019607843137255,
      0.9490196078, 0.6509803922, 0.2509803922, 0.9058823529411765, 0.9490196078, 0.6509803922,
      0.2509803922, 0.9098039215686274, 1.0, 0.0, 0.0, 0.9137254901960784, 1.0, 0.0, 0.0,
      0.9176470588235294, 1.0, 0.0, 0.0, 0.9215686274509803, 1.0, 0.0, 0.0, 0.9254901960784314, 1.0,
      0.0, 0.0, 0.9294117647058824, 1.0, 0.0, 0.0, 0.9333333333333333, 1.0, 0.0, 0.0,
      0.9372549019607843, 1.0, 0.0, 0.0, 0.9411764705882354, 1.0, 0.0, 0.0, 0.9450980392156864, 1.0,
      0.0, 0.0, 0.9490196078431372, 1.0, 0.0, 0.0, 0.9529411764705882, 1.0, 0.0, 0.0,
      0.9568627450980394, 1.0, 0.0, 0.0, 0.9607843137254903, 1.0, 0.0, 0.0, 0.9647058823529413, 1.0,
      0.0, 0.0, 0.9686274509803922, 1.0, 0.0, 0.0, 0.9725490196078431, 1.0, 0.0, 0.0,
      0.9764705882352941, 1.0, 0.0, 0.0, 0.9803921568627451, 1.0, 0.0, 0.0, 0.984313725490196, 1.0,
      0.0, 0.0, 0.9882352941176471, 1.0, 0.0, 0.0, 0.9921568627450981, 1.0, 0.0, 0.0,
      0.996078431372549, 1.0, 0.0, 0.0, 1.0, 1.0, 0.0, 0.0,
    ],
    description: 'SUV',
  },
  {
    ColorSpace: 'RGB',
    Name: 'ge_256',
    name: 'ge_256',
    RGBPoints: [
      0.0, 0.0039215686, 0.0078431373, 0.0078431373, 0.00392156862745098, 0.0039215686,
      0.0078431373, 0.0078431373, 0.00784313725490196, 0.0039215686, 0.0078431373, 0.0117647059,
      0.011764705882352941, 0.0039215686, 0.0117647059, 0.0156862745, 0.01568627450980392,
      0.0039215686, 0.0117647059, 0.0196078431, 0.0196078431372549, 0.0039215686, 0.0156862745,
      0.0235294118, 0.023529411764705882, 0.0039215686, 0.0156862745, 0.0274509804,
      0.027450980392156862, 0.0039215686, 0.0196078431, 0.031372549, 0.03137254901960784,
      0.0039215686, 0.0196078431, 0.0352941176, 0.03529411764705882, 0.0039215686, 0.0235294118,
      0.0392156863, 0.0392156862745098, 0.0039215686, 0.0235294118, 0.0431372549,
      0.043137254901960784, 0.0039215686, 0.0274509804, 0.0470588235, 0.047058823529411764,
      0.0039215686, 0.0274509804, 0.0509803922, 0.050980392156862744, 0.0039215686, 0.031372549,
      0.0549019608, 0.054901960784313725, 0.0039215686, 0.031372549, 0.0588235294,
      0.05882352941176471, 0.0039215686, 0.0352941176, 0.062745098, 0.06274509803921569,
      0.0039215686, 0.0352941176, 0.0666666667, 0.06666666666666667, 0.0039215686, 0.0392156863,
      0.0705882353, 0.07058823529411765, 0.0039215686, 0.0392156863, 0.0745098039,
      0.07450980392156863, 0.0039215686, 0.0431372549, 0.0784313725, 0.0784313725490196,
      0.0039215686, 0.0431372549, 0.0823529412, 0.08235294117647059, 0.0039215686, 0.0470588235,
      0.0862745098, 0.08627450980392157, 0.0039215686, 0.0470588235, 0.0901960784,
      0.09019607843137255, 0.0039215686, 0.0509803922, 0.0941176471, 0.09411764705882353,
      0.0039215686, 0.0509803922, 0.0980392157, 0.09803921568627451, 0.0039215686, 0.0549019608,
      0.1019607843, 0.10196078431372549, 0.0039215686, 0.0549019608, 0.1058823529,
      0.10588235294117647, 0.0039215686, 0.0588235294, 0.1098039216, 0.10980392156862745,
      0.0039215686, 0.0588235294, 0.1137254902, 0.11372549019607843, 0.0039215686, 0.062745098,
      0.1176470588, 0.11764705882352942, 0.0039215686, 0.062745098, 0.1215686275,
      0.12156862745098039, 0.0039215686, 0.0666666667, 0.1254901961, 0.12549019607843137,
      0.0039215686, 0.0666666667, 0.1294117647, 0.12941176470588237, 0.0039215686, 0.0705882353,
      0.1333333333, 0.13333333333333333, 0.0039215686, 0.0705882353, 0.137254902,
      0.13725490196078433, 0.0039215686, 0.0745098039, 0.1411764706, 0.1411764705882353,
      0.0039215686, 0.0745098039, 0.1450980392, 0.1450980392156863, 0.0039215686, 0.0784313725,
      0.1490196078, 0.14901960784313725, 0.0039215686, 0.0784313725, 0.1529411765,
      0.15294117647058825, 0.0039215686, 0.0823529412, 0.1568627451, 0.1568627450980392,
      0.0039215686, 0.0823529412, 0.1607843137, 0.1607843137254902, 0.0039215686, 0.0862745098,
      0.1647058824, 0.16470588235294117, 0.0039215686, 0.0862745098, 0.168627451,
      0.16862745098039217, 0.0039215686, 0.0901960784, 0.1725490196, 0.17254901960784313,
      0.0039215686, 0.0901960784, 0.1764705882, 0.17647058823529413, 0.0039215686, 0.0941176471,
      0.1803921569, 0.1803921568627451, 0.0039215686, 0.0941176471, 0.1843137255,
      0.1843137254901961, 0.0039215686, 0.0980392157, 0.1882352941, 0.18823529411764706,
      0.0039215686, 0.0980392157, 0.1921568627, 0.19215686274509805, 0.0039215686, 0.1019607843,
      0.1960784314, 0.19607843137254902, 0.0039215686, 0.1019607843, 0.2, 0.2, 0.0039215686,
      0.1058823529, 0.2039215686, 0.20392156862745098, 0.0039215686, 0.1058823529, 0.2078431373,
      0.20784313725490197, 0.0039215686, 0.1098039216, 0.2117647059, 0.21176470588235294,
      0.0039215686, 0.1098039216, 0.2156862745, 0.21568627450980393, 0.0039215686, 0.1137254902,
      0.2196078431, 0.2196078431372549, 0.0039215686, 0.1137254902, 0.2235294118,
      0.2235294117647059, 0.0039215686, 0.1176470588, 0.2274509804, 0.22745098039215686,
      0.0039215686, 0.1176470588, 0.231372549, 0.23137254901960785, 0.0039215686, 0.1215686275,
      0.2352941176, 0.23529411764705885, 0.0039215686, 0.1215686275, 0.2392156863,
      0.23921568627450984, 0.0039215686, 0.1254901961, 0.2431372549, 0.24313725490196078,
      0.0039215686, 0.1254901961, 0.2470588235, 0.24705882352941178, 0.0039215686, 0.1294117647,
      0.2509803922, 0.25098039215686274, 0.0039215686, 0.1294117647, 0.2509803922,
      0.2549019607843137, 0.0078431373, 0.1254901961, 0.2549019608, 0.25882352941176473,
      0.0156862745, 0.1254901961, 0.2588235294, 0.2627450980392157, 0.0235294118, 0.1215686275,
      0.262745098, 0.26666666666666666, 0.031372549, 0.1215686275, 0.2666666667,
      0.27058823529411763, 0.0392156863, 0.1176470588, 0.2705882353, 0.27450980392156865,
      0.0470588235, 0.1176470588, 0.2745098039, 0.2784313725490196, 0.0549019608, 0.1137254902,
      0.2784313725, 0.2823529411764706, 0.062745098, 0.1137254902, 0.2823529412,
      0.28627450980392155, 0.0705882353, 0.1098039216, 0.2862745098, 0.2901960784313726,
      0.0784313725, 0.1098039216, 0.2901960784, 0.29411764705882354, 0.0862745098, 0.1058823529,
      0.2941176471, 0.2980392156862745, 0.0941176471, 0.1058823529, 0.2980392157,
      0.30196078431372547, 0.1019607843, 0.1019607843, 0.3019607843, 0.3058823529411765,
      0.1098039216, 0.1019607843, 0.3058823529, 0.30980392156862746, 0.1176470588, 0.0980392157,
      0.3098039216, 0.3137254901960784, 0.1254901961, 0.0980392157, 0.3137254902,
      0.3176470588235294, 0.1333333333, 0.0941176471, 0.3176470588, 0.3215686274509804,
      0.1411764706, 0.0941176471, 0.3215686275, 0.3254901960784314, 0.1490196078, 0.0901960784,
      0.3254901961, 0.32941176470588235, 0.1568627451, 0.0901960784, 0.3294117647,
      0.3333333333333333, 0.1647058824, 0.0862745098, 0.3333333333, 0.33725490196078434,
      0.1725490196, 0.0862745098, 0.337254902, 0.3411764705882353, 0.1803921569, 0.0823529412,
      0.3411764706, 0.34509803921568627, 0.1882352941, 0.0823529412, 0.3450980392,
      0.34901960784313724, 0.1960784314, 0.0784313725, 0.3490196078, 0.35294117647058826,
      0.2039215686, 0.0784313725, 0.3529411765, 0.3568627450980392, 0.2117647059, 0.0745098039,
      0.3568627451, 0.3607843137254902, 0.2196078431, 0.0745098039, 0.3607843137,
      0.36470588235294116, 0.2274509804, 0.0705882353, 0.3647058824, 0.3686274509803922,
      0.2352941176, 0.0705882353, 0.368627451, 0.37254901960784315, 0.2431372549, 0.0666666667,
      0.3725490196, 0.3764705882352941, 0.2509803922, 0.0666666667, 0.3764705882,
      0.3803921568627451, 0.2549019608, 0.062745098, 0.3803921569, 0.3843137254901961, 0.262745098,
      0.062745098, 0.3843137255, 0.38823529411764707, 0.2705882353, 0.0588235294, 0.3882352941,
      0.39215686274509803, 0.2784313725, 0.0588235294, 0.3921568627, 0.396078431372549,
      0.2862745098, 0.0549019608, 0.3960784314, 0.4, 0.2941176471, 0.0549019608, 0.4,
      0.403921568627451, 0.3019607843, 0.0509803922, 0.4039215686, 0.40784313725490196,
      0.3098039216, 0.0509803922, 0.4078431373, 0.4117647058823529, 0.3176470588, 0.0470588235,
      0.4117647059, 0.41568627450980394, 0.3254901961, 0.0470588235, 0.4156862745,
      0.4196078431372549, 0.3333333333, 0.0431372549, 0.4196078431, 0.4235294117647059,
      0.3411764706, 0.0431372549, 0.4235294118, 0.42745098039215684, 0.3490196078, 0.0392156863,
      0.4274509804, 0.43137254901960786, 0.3568627451, 0.0392156863, 0.431372549,
      0.43529411764705883, 0.3647058824, 0.0352941176, 0.4352941176, 0.4392156862745098,
      0.3725490196, 0.0352941176, 0.4392156863, 0.44313725490196076, 0.3803921569, 0.031372549,
      0.4431372549, 0.4470588235294118, 0.3882352941, 0.031372549, 0.4470588235,
      0.45098039215686275, 0.3960784314, 0.0274509804, 0.4509803922, 0.4549019607843137,
      0.4039215686, 0.0274509804, 0.4549019608, 0.4588235294117647, 0.4117647059, 0.0235294118,
      0.4588235294, 0.4627450980392157, 0.4196078431, 0.0235294118, 0.462745098, 0.4666666666666667,
      0.4274509804, 0.0196078431, 0.4666666667, 0.4705882352941177, 0.4352941176, 0.0196078431,
      0.4705882353, 0.4745098039215686, 0.4431372549, 0.0156862745, 0.4745098039,
      0.4784313725490197, 0.4509803922, 0.0156862745, 0.4784313725, 0.48235294117647065,
      0.4588235294, 0.0117647059, 0.4823529412, 0.48627450980392156, 0.4666666667, 0.0117647059,
      0.4862745098, 0.49019607843137253, 0.4745098039, 0.0078431373, 0.4901960784,
      0.49411764705882355, 0.4823529412, 0.0078431373, 0.4941176471, 0.4980392156862745,
      0.4901960784, 0.0039215686, 0.4980392157, 0.5019607843137255, 0.4980392157, 0.0117647059,
      0.4980392157, 0.5058823529411764, 0.5058823529, 0.0156862745, 0.4901960784,
      0.5098039215686274, 0.5137254902, 0.0235294118, 0.4823529412, 0.5137254901960784,
      0.5215686275, 0.0274509804, 0.4745098039, 0.5176470588235295, 0.5294117647, 0.0352941176,
      0.4666666667, 0.5215686274509804, 0.537254902, 0.0392156863, 0.4588235294, 0.5254901960784314,
      0.5450980392, 0.0470588235, 0.4509803922, 0.5294117647058824, 0.5529411765, 0.0509803922,
      0.4431372549, 0.5333333333333333, 0.5607843137, 0.0588235294, 0.4352941176,
      0.5372549019607843, 0.568627451, 0.062745098, 0.4274509804, 0.5411764705882353, 0.5764705882,
      0.0705882353, 0.4196078431, 0.5450980392156862, 0.5843137255, 0.0745098039, 0.4117647059,
      0.5490196078431373, 0.5921568627, 0.0823529412, 0.4039215686, 0.5529411764705883, 0.6,
      0.0862745098, 0.3960784314, 0.5568627450980392, 0.6078431373, 0.0941176471, 0.3882352941,
      0.5607843137254902, 0.6156862745, 0.0980392157, 0.3803921569, 0.5647058823529412,
      0.6235294118, 0.1058823529, 0.3725490196, 0.5686274509803921, 0.631372549, 0.1098039216,
      0.3647058824, 0.5725490196078431, 0.6392156863, 0.1176470588, 0.3568627451,
      0.5764705882352941, 0.6470588235, 0.1215686275, 0.3490196078, 0.5803921568627451,
      0.6549019608, 0.1294117647, 0.3411764706, 0.5843137254901961, 0.662745098, 0.1333333333,
      0.3333333333, 0.5882352941176471, 0.6705882353, 0.1411764706, 0.3254901961, 0.592156862745098,
      0.6784313725, 0.1450980392, 0.3176470588, 0.596078431372549, 0.6862745098, 0.1529411765,
      0.3098039216, 0.6, 0.6941176471, 0.1568627451, 0.3019607843, 0.6039215686274509, 0.7019607843,
      0.1647058824, 0.2941176471, 0.6078431372549019, 0.7098039216, 0.168627451, 0.2862745098,
      0.611764705882353, 0.7176470588, 0.1764705882, 0.2784313725, 0.615686274509804, 0.7254901961,
      0.1803921569, 0.2705882353, 0.6196078431372549, 0.7333333333, 0.1882352941, 0.262745098,
      0.6235294117647059, 0.7411764706, 0.1921568627, 0.2549019608, 0.6274509803921569,
      0.7490196078, 0.2, 0.2509803922, 0.6313725490196078, 0.7529411765, 0.2039215686, 0.2431372549,
      0.6352941176470588, 0.7607843137, 0.2117647059, 0.2352941176, 0.6392156862745098, 0.768627451,
      0.2156862745, 0.2274509804, 0.6431372549019608, 0.7764705882, 0.2235294118, 0.2196078431,
      0.6470588235294118, 0.7843137255, 0.2274509804, 0.2117647059, 0.6509803921568628,
      0.7921568627, 0.2352941176, 0.2039215686, 0.6549019607843137, 0.8, 0.2392156863, 0.1960784314,
      0.6588235294117647, 0.8078431373, 0.2470588235, 0.1882352941, 0.6627450980392157,
      0.8156862745, 0.2509803922, 0.1803921569, 0.6666666666666666, 0.8235294118, 0.2549019608,
      0.1725490196, 0.6705882352941176, 0.831372549, 0.2588235294, 0.1647058824, 0.6745098039215687,
      0.8392156863, 0.2666666667, 0.1568627451, 0.6784313725490196, 0.8470588235, 0.2705882353,
      0.1490196078, 0.6823529411764706, 0.8549019608, 0.2784313725, 0.1411764706,
      0.6862745098039216, 0.862745098, 0.2823529412, 0.1333333333, 0.6901960784313725, 0.8705882353,
      0.2901960784, 0.1254901961, 0.6941176470588235, 0.8784313725, 0.2941176471, 0.1176470588,
      0.6980392156862745, 0.8862745098, 0.3019607843, 0.1098039216, 0.7019607843137254,
      0.8941176471, 0.3058823529, 0.1019607843, 0.7058823529411765, 0.9019607843, 0.3137254902,
      0.0941176471, 0.7098039215686275, 0.9098039216, 0.3176470588, 0.0862745098,
      0.7137254901960784, 0.9176470588, 0.3254901961, 0.0784313725, 0.7176470588235294,
      0.9254901961, 0.3294117647, 0.0705882353, 0.7215686274509804, 0.9333333333, 0.337254902,
      0.062745098, 0.7254901960784313, 0.9411764706, 0.3411764706, 0.0549019608, 0.7294117647058823,
      0.9490196078, 0.3490196078, 0.0470588235, 0.7333333333333333, 0.9568627451, 0.3529411765,
      0.0392156863, 0.7372549019607844, 0.9647058824, 0.3607843137, 0.031372549, 0.7411764705882353,
      0.9725490196, 0.3647058824, 0.0235294118, 0.7450980392156863, 0.9803921569, 0.3725490196,
      0.0156862745, 0.7490196078431373, 0.9882352941, 0.3725490196, 0.0039215686,
      0.7529411764705882, 0.9960784314, 0.3843137255, 0.0156862745, 0.7568627450980392,
      0.9960784314, 0.3921568627, 0.031372549, 0.7607843137254902, 0.9960784314, 0.4039215686,
      0.0470588235, 0.7647058823529411, 0.9960784314, 0.4117647059, 0.062745098, 0.7686274509803922,
      0.9960784314, 0.4235294118, 0.0784313725, 0.7725490196078432, 0.9960784314, 0.431372549,
      0.0941176471, 0.7764705882352941, 0.9960784314, 0.4431372549, 0.1098039216,
      0.7803921568627451, 0.9960784314, 0.4509803922, 0.1254901961, 0.7843137254901961,
      0.9960784314, 0.462745098, 0.1411764706, 0.788235294117647, 0.9960784314, 0.4705882353,
      0.1568627451, 0.792156862745098, 0.9960784314, 0.4823529412, 0.1725490196, 0.796078431372549,
      0.9960784314, 0.4901960784, 0.1882352941, 0.8, 0.9960784314, 0.5019607843, 0.2039215686,
      0.803921568627451, 0.9960784314, 0.5098039216, 0.2196078431, 0.807843137254902, 0.9960784314,
      0.5215686275, 0.2352941176, 0.8117647058823529, 0.9960784314, 0.5294117647, 0.2509803922,
      0.8156862745098039, 0.9960784314, 0.5411764706, 0.262745098, 0.8196078431372549, 0.9960784314,
      0.5490196078, 0.2784313725, 0.8235294117647058, 0.9960784314, 0.5607843137, 0.2941176471,
      0.8274509803921568, 0.9960784314, 0.568627451, 0.3098039216, 0.8313725490196079, 0.9960784314,
      0.5803921569, 0.3254901961, 0.8352941176470589, 0.9960784314, 0.5882352941, 0.3411764706,
      0.8392156862745098, 0.9960784314, 0.6, 0.3568627451, 0.8431372549019608, 0.9960784314,
      0.6078431373, 0.3725490196, 0.8470588235294118, 0.9960784314, 0.6196078431, 0.3882352941,
      0.8509803921568627, 0.9960784314, 0.6274509804, 0.4039215686, 0.8549019607843137,
      0.9960784314, 0.6392156863, 0.4196078431, 0.8588235294117647, 0.9960784314, 0.6470588235,
      0.4352941176, 0.8627450980392157, 0.9960784314, 0.6588235294, 0.4509803922,
      0.8666666666666667, 0.9960784314, 0.6666666667, 0.4666666667, 0.8705882352941177,
      0.9960784314, 0.6784313725, 0.4823529412, 0.8745098039215686, 0.9960784314, 0.6862745098,
      0.4980392157, 0.8784313725490196, 0.9960784314, 0.6980392157, 0.5137254902,
      0.8823529411764706, 0.9960784314, 0.7058823529, 0.5294117647, 0.8862745098039215,
      0.9960784314, 0.7176470588, 0.5450980392, 0.8901960784313725, 0.9960784314, 0.7254901961,
      0.5607843137, 0.8941176470588236, 0.9960784314, 0.737254902, 0.5764705882, 0.8980392156862745,
      0.9960784314, 0.7450980392, 0.5921568627, 0.9019607843137255, 0.9960784314, 0.7529411765,
      0.6078431373, 0.9058823529411765, 0.9960784314, 0.7607843137, 0.6235294118,
      0.9098039215686274, 0.9960784314, 0.7725490196, 0.6392156863, 0.9137254901960784,
      0.9960784314, 0.7803921569, 0.6549019608, 0.9176470588235294, 0.9960784314, 0.7921568627,
      0.6705882353, 0.9215686274509803, 0.9960784314, 0.8, 0.6862745098, 0.9254901960784314,
      0.9960784314, 0.8117647059, 0.7019607843, 0.9294117647058824, 0.9960784314, 0.8196078431,
      0.7176470588, 0.9333333333333333, 0.9960784314, 0.831372549, 0.7333333333, 0.9372549019607843,
      0.9960784314, 0.8392156863, 0.7490196078, 0.9411764705882354, 0.9960784314, 0.8509803922,
      0.7607843137, 0.9450980392156864, 0.9960784314, 0.8588235294, 0.7764705882,
      0.9490196078431372, 0.9960784314, 0.8705882353, 0.7921568627, 0.9529411764705882,
      0.9960784314, 0.8784313725, 0.8078431373, 0.9568627450980394, 0.9960784314, 0.8901960784,
      0.8235294118, 0.9607843137254903, 0.9960784314, 0.8980392157, 0.8392156863,
      0.9647058823529413, 0.9960784314, 0.9098039216, 0.8549019608, 0.9686274509803922,
      0.9960784314, 0.9176470588, 0.8705882353, 0.9725490196078431, 0.9960784314, 0.9294117647,
      0.8862745098, 0.9764705882352941, 0.9960784314, 0.937254902, 0.9019607843, 0.9803921568627451,
      0.9960784314, 0.9490196078, 0.9176470588, 0.984313725490196, 0.9960784314, 0.9568627451,
      0.9333333333, 0.9882352941176471, 0.9960784314, 0.968627451, 0.9490196078, 0.9921568627450981,
      0.9960784314, 0.9764705882, 0.9647058824, 0.996078431372549, 0.9960784314, 0.9882352941,
      0.9803921569, 1.0, 0.9960784314, 0.9882352941, 0.9803921569,
    ],
    description: 'GE 256',
  },
  {
    ColorSpace: 'RGB',
    Name: 'ge',
    name: 'ge',
    RGBPoints: [
      0.0, 0.0078431373, 0.0078431373, 0.0078431373, 0.00392156862745098, 0.0078431373,
      0.0078431373, 0.0078431373, 0.00784313725490196, 0.0078431373, 0.0078431373, 0.0078431373,
      0.011764705882352941, 0.0078431373, 0.0078431373, 0.0078431373, 0.01568627450980392,
      0.0078431373, 0.0078431373, 0.0078431373, 0.0196078431372549, 0.0078431373, 0.0078431373,
      0.0078431373, 0.023529411764705882, 0.0078431373, 0.0078431373, 0.0078431373,
      0.027450980392156862, 0.0078431373, 0.0078431373, 0.0078431373, 0.03137254901960784,
      0.0078431373, 0.0078431373, 0.0078431373, 0.03529411764705882, 0.0078431373, 0.0078431373,
      0.0078431373, 0.0392156862745098, 0.0078431373, 0.0078431373, 0.0078431373,
      0.043137254901960784, 0.0078431373, 0.0078431373, 0.0078431373, 0.047058823529411764,
      0.0078431373, 0.0078431373, 0.0078431373, 0.050980392156862744, 0.0078431373, 0.0078431373,
      0.0078431373, 0.054901960784313725, 0.0078431373, 0.0078431373, 0.0078431373,
      0.05882352941176471, 0.0117647059, 0.0078431373, 0.0078431373, 0.06274509803921569,
      0.0078431373, 0.0156862745, 0.0156862745, 0.06666666666666667, 0.0078431373, 0.0235294118,
      0.0235294118, 0.07058823529411765, 0.0078431373, 0.031372549, 0.031372549,
      0.07450980392156863, 0.0078431373, 0.0392156863, 0.0392156863, 0.0784313725490196,
      0.0078431373, 0.0470588235, 0.0470588235, 0.08235294117647059, 0.0078431373, 0.0549019608,
      0.0549019608, 0.08627450980392157, 0.0078431373, 0.062745098, 0.062745098,
      0.09019607843137255, 0.0078431373, 0.0705882353, 0.0705882353, 0.09411764705882353,
      0.0078431373, 0.0784313725, 0.0784313725, 0.09803921568627451, 0.0078431373, 0.0901960784,
      0.0862745098, 0.10196078431372549, 0.0078431373, 0.0980392157, 0.0941176471,
      0.10588235294117647, 0.0078431373, 0.1058823529, 0.1019607843, 0.10980392156862745,
      0.0078431373, 0.1137254902, 0.1098039216, 0.11372549019607843, 0.0078431373, 0.1215686275,
      0.1176470588, 0.11764705882352942, 0.0078431373, 0.1294117647, 0.1254901961,
      0.12156862745098039, 0.0078431373, 0.137254902, 0.1333333333, 0.12549019607843137,
      0.0078431373, 0.1450980392, 0.1411764706, 0.12941176470588237, 0.0078431373, 0.1529411765,
      0.1490196078, 0.13333333333333333, 0.0078431373, 0.1647058824, 0.1568627451,
      0.13725490196078433, 0.0078431373, 0.1725490196, 0.1647058824, 0.1411764705882353,
      0.0078431373, 0.1803921569, 0.1725490196, 0.1450980392156863, 0.0078431373, 0.1882352941,
      0.1803921569, 0.14901960784313725, 0.0078431373, 0.1960784314, 0.1882352941,
      0.15294117647058825, 0.0078431373, 0.2039215686, 0.1960784314, 0.1568627450980392,
      0.0078431373, 0.2117647059, 0.2039215686, 0.1607843137254902, 0.0078431373, 0.2196078431,
      0.2117647059, 0.16470588235294117, 0.0078431373, 0.2274509804, 0.2196078431,
      0.16862745098039217, 0.0078431373, 0.2352941176, 0.2274509804, 0.17254901960784313,
      0.0078431373, 0.2470588235, 0.2352941176, 0.17647058823529413, 0.0078431373, 0.2509803922,
      0.2431372549, 0.1803921568627451, 0.0078431373, 0.2549019608, 0.2509803922,
      0.1843137254901961, 0.0078431373, 0.262745098, 0.2509803922, 0.18823529411764706,
      0.0078431373, 0.2705882353, 0.2588235294, 0.19215686274509805, 0.0078431373, 0.2784313725,
      0.2666666667, 0.19607843137254902, 0.0078431373, 0.2862745098, 0.2745098039, 0.2,
      0.0078431373, 0.2941176471, 0.2823529412, 0.20392156862745098, 0.0078431373, 0.3019607843,
      0.2901960784, 0.20784313725490197, 0.0078431373, 0.3137254902, 0.2980392157,
      0.21176470588235294, 0.0078431373, 0.3215686275, 0.3058823529, 0.21568627450980393,
      0.0078431373, 0.3294117647, 0.3137254902, 0.2196078431372549, 0.0078431373, 0.337254902,
      0.3215686275, 0.2235294117647059, 0.0078431373, 0.3450980392, 0.3294117647,
      0.22745098039215686, 0.0078431373, 0.3529411765, 0.337254902, 0.23137254901960785,
      0.0078431373, 0.3607843137, 0.3450980392, 0.23529411764705885, 0.0078431373, 0.368627451,
      0.3529411765, 0.23921568627450984, 0.0078431373, 0.3764705882, 0.3607843137,
      0.24313725490196078, 0.0078431373, 0.3843137255, 0.368627451, 0.24705882352941178,
      0.0078431373, 0.3960784314, 0.3764705882, 0.25098039215686274, 0.0078431373, 0.4039215686,
      0.3843137255, 0.2549019607843137, 0.0078431373, 0.4117647059, 0.3921568627,
      0.25882352941176473, 0.0078431373, 0.4196078431, 0.4, 0.2627450980392157, 0.0078431373,
      0.4274509804, 0.4078431373, 0.26666666666666666, 0.0078431373, 0.4352941176, 0.4156862745,
      0.27058823529411763, 0.0078431373, 0.4431372549, 0.4235294118, 0.27450980392156865,
      0.0078431373, 0.4509803922, 0.431372549, 0.2784313725490196, 0.0078431373, 0.4588235294,
      0.4392156863, 0.2823529411764706, 0.0078431373, 0.4705882353, 0.4470588235,
      0.28627450980392155, 0.0078431373, 0.4784313725, 0.4549019608, 0.2901960784313726,
      0.0078431373, 0.4862745098, 0.462745098, 0.29411764705882354, 0.0078431373, 0.4941176471,
      0.4705882353, 0.2980392156862745, 0.0078431373, 0.5019607843, 0.4784313725,
      0.30196078431372547, 0.0117647059, 0.5098039216, 0.4862745098, 0.3058823529411765,
      0.0196078431, 0.5019607843, 0.4941176471, 0.30980392156862746, 0.0274509804, 0.4941176471,
      0.5058823529, 0.3137254901960784, 0.0352941176, 0.4862745098, 0.5137254902,
      0.3176470588235294, 0.0431372549, 0.4784313725, 0.5215686275, 0.3215686274509804,
      0.0509803922, 0.4705882353, 0.5294117647, 0.3254901960784314, 0.0588235294, 0.462745098,
      0.537254902, 0.32941176470588235, 0.0666666667, 0.4549019608, 0.5450980392,
      0.3333333333333333, 0.0745098039, 0.4470588235, 0.5529411765, 0.33725490196078434,
      0.0823529412, 0.4392156863, 0.5607843137, 0.3411764705882353, 0.0901960784, 0.431372549,
      0.568627451, 0.34509803921568627, 0.0980392157, 0.4235294118, 0.5764705882,
      0.34901960784313724, 0.1058823529, 0.4156862745, 0.5843137255, 0.35294117647058826,
      0.1137254902, 0.4078431373, 0.5921568627, 0.3568627450980392, 0.1215686275, 0.4, 0.6,
      0.3607843137254902, 0.1294117647, 0.3921568627, 0.6078431373, 0.36470588235294116,
      0.137254902, 0.3843137255, 0.6156862745, 0.3686274509803922, 0.1450980392, 0.3764705882,
      0.6235294118, 0.37254901960784315, 0.1529411765, 0.368627451, 0.631372549, 0.3764705882352941,
      0.1607843137, 0.3607843137, 0.6392156863, 0.3803921568627451, 0.168627451, 0.3529411765,
      0.6470588235, 0.3843137254901961, 0.1764705882, 0.3450980392, 0.6549019608,
      0.38823529411764707, 0.1843137255, 0.337254902, 0.662745098, 0.39215686274509803,
      0.1921568627, 0.3294117647, 0.6705882353, 0.396078431372549, 0.2, 0.3215686275, 0.6784313725,
      0.4, 0.2078431373, 0.3137254902, 0.6862745098, 0.403921568627451, 0.2156862745, 0.3058823529,
      0.6941176471, 0.40784313725490196, 0.2235294118, 0.2980392157, 0.7019607843,
      0.4117647058823529, 0.231372549, 0.2901960784, 0.7098039216, 0.41568627450980394,
      0.2392156863, 0.2823529412, 0.7176470588, 0.4196078431372549, 0.2470588235, 0.2745098039,
      0.7254901961, 0.4235294117647059, 0.2509803922, 0.2666666667, 0.7333333333,
      0.42745098039215684, 0.2509803922, 0.2588235294, 0.7411764706, 0.43137254901960786,
      0.2588235294, 0.2509803922, 0.7490196078, 0.43529411764705883, 0.2666666667, 0.2509803922,
      0.7490196078, 0.4392156862745098, 0.2745098039, 0.2431372549, 0.7568627451,
      0.44313725490196076, 0.2823529412, 0.2352941176, 0.7647058824, 0.4470588235294118,
      0.2901960784, 0.2274509804, 0.7725490196, 0.45098039215686275, 0.2980392157, 0.2196078431,
      0.7803921569, 0.4549019607843137, 0.3058823529, 0.2117647059, 0.7882352941,
      0.4588235294117647, 0.3137254902, 0.2039215686, 0.7960784314, 0.4627450980392157,
      0.3215686275, 0.1960784314, 0.8039215686, 0.4666666666666667, 0.3294117647, 0.1882352941,
      0.8117647059, 0.4705882352941177, 0.337254902, 0.1803921569, 0.8196078431, 0.4745098039215686,
      0.3450980392, 0.1725490196, 0.8274509804, 0.4784313725490197, 0.3529411765, 0.1647058824,
      0.8352941176, 0.48235294117647065, 0.3607843137, 0.1568627451, 0.8431372549,
      0.48627450980392156, 0.368627451, 0.1490196078, 0.8509803922, 0.49019607843137253,
      0.3764705882, 0.1411764706, 0.8588235294, 0.49411764705882355, 0.3843137255, 0.1333333333,
      0.8666666667, 0.4980392156862745, 0.3921568627, 0.1254901961, 0.8745098039,
      0.5019607843137255, 0.4, 0.1176470588, 0.8823529412, 0.5058823529411764, 0.4078431373,
      0.1098039216, 0.8901960784, 0.5098039215686274, 0.4156862745, 0.1019607843, 0.8980392157,
      0.5137254901960784, 0.4235294118, 0.0941176471, 0.9058823529, 0.5176470588235295, 0.431372549,
      0.0862745098, 0.9137254902, 0.5215686274509804, 0.4392156863, 0.0784313725, 0.9215686275,
      0.5254901960784314, 0.4470588235, 0.0705882353, 0.9294117647, 0.5294117647058824,
      0.4549019608, 0.062745098, 0.937254902, 0.5333333333333333, 0.462745098, 0.0549019608,
      0.9450980392, 0.5372549019607843, 0.4705882353, 0.0470588235, 0.9529411765,
      0.5411764705882353, 0.4784313725, 0.0392156863, 0.9607843137, 0.5450980392156862,
      0.4862745098, 0.031372549, 0.968627451, 0.5490196078431373, 0.4941176471, 0.0235294118,
      0.9764705882, 0.5529411764705883, 0.4980392157, 0.0156862745, 0.9843137255,
      0.5568627450980392, 0.5058823529, 0.0078431373, 0.9921568627, 0.5607843137254902,
      0.5137254902, 0.0156862745, 0.9803921569, 0.5647058823529412, 0.5215686275, 0.0235294118,
      0.9647058824, 0.5686274509803921, 0.5294117647, 0.0352941176, 0.9490196078,
      0.5725490196078431, 0.537254902, 0.0431372549, 0.9333333333, 0.5764705882352941, 0.5450980392,
      0.0509803922, 0.9176470588, 0.5803921568627451, 0.5529411765, 0.062745098, 0.9019607843,
      0.5843137254901961, 0.5607843137, 0.0705882353, 0.8862745098, 0.5882352941176471, 0.568627451,
      0.0784313725, 0.8705882353, 0.592156862745098, 0.5764705882, 0.0901960784, 0.8549019608,
      0.596078431372549, 0.5843137255, 0.0980392157, 0.8392156863, 0.6, 0.5921568627, 0.1098039216,
      0.8235294118, 0.6039215686274509, 0.6, 0.1176470588, 0.8078431373, 0.6078431372549019,
      0.6078431373, 0.1254901961, 0.7921568627, 0.611764705882353, 0.6156862745, 0.137254902,
      0.7764705882, 0.615686274509804, 0.6235294118, 0.1450980392, 0.7607843137, 0.6196078431372549,
      0.631372549, 0.1529411765, 0.7490196078, 0.6235294117647059, 0.6392156863, 0.1647058824,
      0.737254902, 0.6274509803921569, 0.6470588235, 0.1725490196, 0.7215686275, 0.6313725490196078,
      0.6549019608, 0.1843137255, 0.7058823529, 0.6352941176470588, 0.662745098, 0.1921568627,
      0.6901960784, 0.6392156862745098, 0.6705882353, 0.2, 0.6745098039, 0.6431372549019608,
      0.6784313725, 0.2117647059, 0.6588235294, 0.6470588235294118, 0.6862745098, 0.2196078431,
      0.6431372549, 0.6509803921568628, 0.6941176471, 0.2274509804, 0.6274509804,
      0.6549019607843137, 0.7019607843, 0.2392156863, 0.6117647059, 0.6588235294117647,
      0.7098039216, 0.2470588235, 0.5960784314, 0.6627450980392157, 0.7176470588, 0.2509803922,
      0.5803921569, 0.6666666666666666, 0.7254901961, 0.2588235294, 0.5647058824,
      0.6705882352941176, 0.7333333333, 0.2666666667, 0.5490196078, 0.6745098039215687,
      0.7411764706, 0.2784313725, 0.5333333333, 0.6784313725490196, 0.7490196078, 0.2862745098,
      0.5176470588, 0.6823529411764706, 0.7490196078, 0.2941176471, 0.5019607843,
      0.6862745098039216, 0.7529411765, 0.3058823529, 0.4862745098, 0.6901960784313725,
      0.7607843137, 0.3137254902, 0.4705882353, 0.6941176470588235, 0.768627451, 0.3215686275,
      0.4549019608, 0.6980392156862745, 0.7764705882, 0.3333333333, 0.4392156863,
      0.7019607843137254, 0.7843137255, 0.3411764706, 0.4235294118, 0.7058823529411765,
      0.7921568627, 0.3529411765, 0.4078431373, 0.7098039215686275, 0.8, 0.3607843137, 0.3921568627,
      0.7137254901960784, 0.8078431373, 0.368627451, 0.3764705882, 0.7176470588235294, 0.8156862745,
      0.3803921569, 0.3607843137, 0.7215686274509804, 0.8235294118, 0.3882352941, 0.3450980392,
      0.7254901960784313, 0.831372549, 0.3960784314, 0.3294117647, 0.7294117647058823, 0.8392156863,
      0.4078431373, 0.3137254902, 0.7333333333333333, 0.8470588235, 0.4156862745, 0.2980392157,
      0.7372549019607844, 0.8549019608, 0.4274509804, 0.2823529412, 0.7411764705882353, 0.862745098,
      0.4352941176, 0.2666666667, 0.7450980392156863, 0.8705882353, 0.4431372549, 0.2509803922,
      0.7490196078431373, 0.8784313725, 0.4549019608, 0.2431372549, 0.7529411764705882,
      0.8862745098, 0.462745098, 0.2274509804, 0.7568627450980392, 0.8941176471, 0.4705882353,
      0.2117647059, 0.7607843137254902, 0.9019607843, 0.4823529412, 0.1960784314,
      0.7647058823529411, 0.9098039216, 0.4901960784, 0.1803921569, 0.7686274509803922,
      0.9176470588, 0.4980392157, 0.1647058824, 0.7725490196078432, 0.9254901961, 0.5098039216,
      0.1490196078, 0.7764705882352941, 0.9333333333, 0.5176470588, 0.1333333333,
      0.7803921568627451, 0.9411764706, 0.5294117647, 0.1176470588, 0.7843137254901961,
      0.9490196078, 0.537254902, 0.1019607843, 0.788235294117647, 0.9568627451, 0.5450980392,
      0.0862745098, 0.792156862745098, 0.9647058824, 0.5568627451, 0.0705882353, 0.796078431372549,
      0.9725490196, 0.5647058824, 0.0549019608, 0.8, 0.9803921569, 0.5725490196, 0.0392156863,
      0.803921568627451, 0.9882352941, 0.5843137255, 0.0235294118, 0.807843137254902, 0.9921568627,
      0.5921568627, 0.0078431373, 0.8117647058823529, 0.9921568627, 0.6039215686, 0.0274509804,
      0.8156862745098039, 0.9921568627, 0.6117647059, 0.0509803922, 0.8196078431372549,
      0.9921568627, 0.6196078431, 0.0745098039, 0.8235294117647058, 0.9921568627, 0.631372549,
      0.0980392157, 0.8274509803921568, 0.9921568627, 0.6392156863, 0.1215686275,
      0.8313725490196079, 0.9921568627, 0.6470588235, 0.1411764706, 0.8352941176470589,
      0.9921568627, 0.6588235294, 0.1647058824, 0.8392156862745098, 0.9921568627, 0.6666666667,
      0.1882352941, 0.8431372549019608, 0.9921568627, 0.6784313725, 0.2117647059,
      0.8470588235294118, 0.9921568627, 0.6862745098, 0.2352941176, 0.8509803921568627,
      0.9921568627, 0.6941176471, 0.2509803922, 0.8549019607843137, 0.9921568627, 0.7058823529,
      0.2705882353, 0.8588235294117647, 0.9921568627, 0.7137254902, 0.2941176471,
      0.8627450980392157, 0.9921568627, 0.7215686275, 0.3176470588, 0.8666666666666667,
      0.9921568627, 0.7333333333, 0.3411764706, 0.8705882352941177, 0.9921568627, 0.7411764706,
      0.3647058824, 0.8745098039215686, 0.9921568627, 0.7490196078, 0.3843137255,
      0.8784313725490196, 0.9921568627, 0.7529411765, 0.4078431373, 0.8823529411764706,
      0.9921568627, 0.7607843137, 0.431372549, 0.8862745098039215, 0.9921568627, 0.7725490196,
      0.4549019608, 0.8901960784313725, 0.9921568627, 0.7803921569, 0.4784313725,
      0.8941176470588236, 0.9921568627, 0.7882352941, 0.4980392157, 0.8980392156862745,
      0.9921568627, 0.8, 0.5215686275, 0.9019607843137255, 0.9921568627, 0.8078431373, 0.5450980392,
      0.9058823529411765, 0.9921568627, 0.8156862745, 0.568627451, 0.9098039215686274, 0.9921568627,
      0.8274509804, 0.5921568627, 0.9137254901960784, 0.9921568627, 0.8352941176, 0.6156862745,
      0.9176470588235294, 0.9921568627, 0.8470588235, 0.6352941176, 0.9215686274509803,
      0.9921568627, 0.8549019608, 0.6588235294, 0.9254901960784314, 0.9921568627, 0.862745098,
      0.6823529412, 0.9294117647058824, 0.9921568627, 0.8745098039, 0.7058823529,
      0.9333333333333333, 0.9921568627, 0.8823529412, 0.7294117647, 0.9372549019607843,
      0.9921568627, 0.8901960784, 0.7490196078, 0.9411764705882354, 0.9921568627, 0.9019607843,
      0.7647058824, 0.9450980392156864, 0.9921568627, 0.9098039216, 0.7882352941,
      0.9490196078431372, 0.9921568627, 0.9215686275, 0.8117647059, 0.9529411764705882,
      0.9921568627, 0.9294117647, 0.8352941176, 0.9568627450980394, 0.9921568627, 0.937254902,
      0.8588235294, 0.9607843137254903, 0.9921568627, 0.9490196078, 0.8784313725,
      0.9647058823529413, 0.9921568627, 0.9568627451, 0.9019607843, 0.9686274509803922,
      0.9921568627, 0.9647058824, 0.9254901961, 0.9725490196078431, 0.9921568627, 0.9764705882,
      0.9490196078, 0.9764705882352941, 0.9921568627, 0.9843137255, 0.9725490196,
      0.9803921568627451, 0.9921568627, 0.9921568627, 0.9921568627, 0.984313725490196, 0.9921568627,
      0.9921568627, 0.9921568627, 0.9882352941176471, 0.9921568627, 0.9921568627, 0.9921568627,
      0.9921568627450981, 0.9921568627, 0.9921568627, 0.9921568627, 0.996078431372549, 0.9921568627,
      0.9921568627, 0.9921568627, 1.0, 0.9921568627, 0.9921568627, 0.9921568627,
    ],
    description: 'GE',
  },
  {
    ColorSpace: 'RGB',
    Name: 'siemens',
    name: 'siemens',
    RGBPoints: [
      0.0, 0.0078431373, 0.0039215686, 0.1254901961, 0.00392156862745098, 0.0078431373,
      0.0039215686, 0.1254901961, 0.00784313725490196, 0.0078431373, 0.0039215686, 0.1882352941,
      0.011764705882352941, 0.0117647059, 0.0039215686, 0.2509803922, 0.01568627450980392,
      0.0117647059, 0.0039215686, 0.3098039216, 0.0196078431372549, 0.0156862745, 0.0039215686,
      0.3725490196, 0.023529411764705882, 0.0156862745, 0.0039215686, 0.3725490196,
      0.027450980392156862, 0.0156862745, 0.0039215686, 0.3725490196, 0.03137254901960784,
      0.0156862745, 0.0039215686, 0.3725490196, 0.03529411764705882, 0.0156862745, 0.0039215686,
      0.3725490196, 0.0392156862745098, 0.0156862745, 0.0039215686, 0.3725490196,
      0.043137254901960784, 0.0156862745, 0.0039215686, 0.3725490196, 0.047058823529411764,
      0.0156862745, 0.0039215686, 0.3725490196, 0.050980392156862744, 0.0156862745, 0.0039215686,
      0.3725490196, 0.054901960784313725, 0.0156862745, 0.0039215686, 0.3725490196,
      0.05882352941176471, 0.0156862745, 0.0039215686, 0.3725490196, 0.06274509803921569,
      0.0156862745, 0.0039215686, 0.3882352941, 0.06666666666666667, 0.0156862745, 0.0039215686,
      0.4078431373, 0.07058823529411765, 0.0156862745, 0.0039215686, 0.4235294118,
      0.07450980392156863, 0.0156862745, 0.0039215686, 0.4431372549, 0.0784313725490196,
      0.0156862745, 0.0039215686, 0.462745098, 0.08235294117647059, 0.0156862745, 0.0039215686,
      0.4784313725, 0.08627450980392157, 0.0156862745, 0.0039215686, 0.4980392157,
      0.09019607843137255, 0.0196078431, 0.0039215686, 0.5137254902, 0.09411764705882353,
      0.0196078431, 0.0039215686, 0.5333333333, 0.09803921568627451, 0.0196078431, 0.0039215686,
      0.5529411765, 0.10196078431372549, 0.0196078431, 0.0039215686, 0.568627451,
      0.10588235294117647, 0.0196078431, 0.0039215686, 0.5882352941, 0.10980392156862745,
      0.0196078431, 0.0039215686, 0.6039215686, 0.11372549019607843, 0.0196078431, 0.0039215686,
      0.6235294118, 0.11764705882352942, 0.0196078431, 0.0039215686, 0.6431372549,
      0.12156862745098039, 0.0235294118, 0.0039215686, 0.6588235294, 0.12549019607843137,
      0.0235294118, 0.0039215686, 0.6784313725, 0.12941176470588237, 0.0235294118, 0.0039215686,
      0.6980392157, 0.13333333333333333, 0.0235294118, 0.0039215686, 0.7137254902,
      0.13725490196078433, 0.0235294118, 0.0039215686, 0.7333333333, 0.1411764705882353,
      0.0235294118, 0.0039215686, 0.7490196078, 0.1450980392156863, 0.0235294118, 0.0039215686,
      0.7647058824, 0.14901960784313725, 0.0235294118, 0.0039215686, 0.7843137255,
      0.15294117647058825, 0.0274509804, 0.0039215686, 0.8, 0.1568627450980392, 0.0274509804,
      0.0039215686, 0.8196078431, 0.1607843137254902, 0.0274509804, 0.0039215686, 0.8352941176,
      0.16470588235294117, 0.0274509804, 0.0039215686, 0.8549019608, 0.16862745098039217,
      0.0274509804, 0.0039215686, 0.8745098039, 0.17254901960784313, 0.0274509804, 0.0039215686,
      0.8901960784, 0.17647058823529413, 0.0274509804, 0.0039215686, 0.9098039216,
      0.1803921568627451, 0.031372549, 0.0039215686, 0.9294117647, 0.1843137254901961, 0.031372549,
      0.0039215686, 0.9254901961, 0.18823529411764706, 0.0509803922, 0.0039215686, 0.9098039216,
      0.19215686274509805, 0.0705882353, 0.0039215686, 0.8901960784, 0.19607843137254902,
      0.0901960784, 0.0039215686, 0.8705882353, 0.2, 0.1137254902, 0.0039215686, 0.8509803922,
      0.20392156862745098, 0.1333333333, 0.0039215686, 0.831372549, 0.20784313725490197,
      0.1529411765, 0.0039215686, 0.8117647059, 0.21176470588235294, 0.1725490196, 0.0039215686,
      0.7921568627, 0.21568627450980393, 0.1960784314, 0.0039215686, 0.7725490196,
      0.2196078431372549, 0.2156862745, 0.0039215686, 0.7529411765, 0.2235294117647059,
      0.2352941176, 0.0039215686, 0.737254902, 0.22745098039215686, 0.2509803922, 0.0039215686,
      0.7176470588, 0.23137254901960785, 0.2745098039, 0.0039215686, 0.6980392157,
      0.23529411764705885, 0.2941176471, 0.0039215686, 0.6784313725, 0.23921568627450984,
      0.3137254902, 0.0039215686, 0.6588235294, 0.24313725490196078, 0.3333333333, 0.0039215686,
      0.6392156863, 0.24705882352941178, 0.3568627451, 0.0039215686, 0.6196078431,
      0.25098039215686274, 0.3764705882, 0.0039215686, 0.6, 0.2549019607843137, 0.3960784314,
      0.0039215686, 0.5803921569, 0.25882352941176473, 0.4156862745, 0.0039215686, 0.5607843137,
      0.2627450980392157, 0.4392156863, 0.0039215686, 0.5411764706, 0.26666666666666666,
      0.4588235294, 0.0039215686, 0.5215686275, 0.27058823529411763, 0.4784313725, 0.0039215686,
      0.5019607843, 0.27450980392156865, 0.4980392157, 0.0039215686, 0.4823529412,
      0.2784313725490196, 0.5215686275, 0.0039215686, 0.4666666667, 0.2823529411764706,
      0.5411764706, 0.0039215686, 0.4470588235, 0.28627450980392155, 0.5607843137, 0.0039215686,
      0.4274509804, 0.2901960784313726, 0.5803921569, 0.0039215686, 0.4078431373,
      0.29411764705882354, 0.6039215686, 0.0039215686, 0.3882352941, 0.2980392156862745,
      0.6235294118, 0.0039215686, 0.368627451, 0.30196078431372547, 0.6431372549, 0.0039215686,
      0.3490196078, 0.3058823529411765, 0.662745098, 0.0039215686, 0.3294117647,
      0.30980392156862746, 0.6862745098, 0.0039215686, 0.3098039216, 0.3137254901960784,
      0.7058823529, 0.0039215686, 0.2901960784, 0.3176470588235294, 0.7254901961, 0.0039215686,
      0.2705882353, 0.3215686274509804, 0.7450980392, 0.0039215686, 0.2509803922,
      0.3254901960784314, 0.7647058824, 0.0039215686, 0.2352941176, 0.32941176470588235,
      0.7843137255, 0.0039215686, 0.2156862745, 0.3333333333333333, 0.8039215686, 0.0039215686,
      0.1960784314, 0.33725490196078434, 0.8235294118, 0.0039215686, 0.1764705882,
      0.3411764705882353, 0.8470588235, 0.0039215686, 0.1568627451, 0.34509803921568627,
      0.8666666667, 0.0039215686, 0.137254902, 0.34901960784313724, 0.8862745098, 0.0039215686,
      0.1176470588, 0.35294117647058826, 0.9058823529, 0.0039215686, 0.0980392157,
      0.3568627450980392, 0.9294117647, 0.0039215686, 0.0784313725, 0.3607843137254902,
      0.9490196078, 0.0039215686, 0.0588235294, 0.36470588235294116, 0.968627451, 0.0039215686,
      0.0392156863, 0.3686274509803922, 0.9921568627, 0.0039215686, 0.0235294118,
      0.37254901960784315, 0.9529411765, 0.0039215686, 0.0588235294, 0.3764705882352941,
      0.9529411765, 0.0078431373, 0.0549019608, 0.3803921568627451, 0.9529411765, 0.0156862745,
      0.0549019608, 0.3843137254901961, 0.9529411765, 0.0235294118, 0.0549019608,
      0.38823529411764707, 0.9529411765, 0.031372549, 0.0549019608, 0.39215686274509803,
      0.9529411765, 0.0352941176, 0.0549019608, 0.396078431372549, 0.9529411765, 0.0431372549,
      0.0549019608, 0.4, 0.9529411765, 0.0509803922, 0.0549019608, 0.403921568627451, 0.9529411765,
      0.0588235294, 0.0549019608, 0.40784313725490196, 0.9529411765, 0.062745098, 0.0549019608,
      0.4117647058823529, 0.9529411765, 0.0705882353, 0.0549019608, 0.41568627450980394,
      0.9529411765, 0.0784313725, 0.0509803922, 0.4196078431372549, 0.9529411765, 0.0862745098,
      0.0509803922, 0.4235294117647059, 0.9568627451, 0.0941176471, 0.0509803922,
      0.42745098039215684, 0.9568627451, 0.0980392157, 0.0509803922, 0.43137254901960786,
      0.9568627451, 0.1058823529, 0.0509803922, 0.43529411764705883, 0.9568627451, 0.1137254902,
      0.0509803922, 0.4392156862745098, 0.9568627451, 0.1215686275, 0.0509803922,
      0.44313725490196076, 0.9568627451, 0.1254901961, 0.0509803922, 0.4470588235294118,
      0.9568627451, 0.1333333333, 0.0509803922, 0.45098039215686275, 0.9568627451, 0.1411764706,
      0.0509803922, 0.4549019607843137, 0.9568627451, 0.1490196078, 0.0470588235,
      0.4588235294117647, 0.9568627451, 0.1568627451, 0.0470588235, 0.4627450980392157,
      0.9568627451, 0.1607843137, 0.0470588235, 0.4666666666666667, 0.9568627451, 0.168627451,
      0.0470588235, 0.4705882352941177, 0.9607843137, 0.1764705882, 0.0470588235,
      0.4745098039215686, 0.9607843137, 0.1843137255, 0.0470588235, 0.4784313725490197,
      0.9607843137, 0.1882352941, 0.0470588235, 0.48235294117647065, 0.9607843137, 0.1960784314,
      0.0470588235, 0.48627450980392156, 0.9607843137, 0.2039215686, 0.0470588235,
      0.49019607843137253, 0.9607843137, 0.2117647059, 0.0470588235, 0.49411764705882355,
      0.9607843137, 0.2196078431, 0.0431372549, 0.4980392156862745, 0.9607843137, 0.2235294118,
      0.0431372549, 0.5019607843137255, 0.9607843137, 0.231372549, 0.0431372549, 0.5058823529411764,
      0.9607843137, 0.2392156863, 0.0431372549, 0.5098039215686274, 0.9607843137, 0.2470588235,
      0.0431372549, 0.5137254901960784, 0.9607843137, 0.2509803922, 0.0431372549,
      0.5176470588235295, 0.9647058824, 0.2549019608, 0.0431372549, 0.5215686274509804,
      0.9647058824, 0.262745098, 0.0431372549, 0.5254901960784314, 0.9647058824, 0.2705882353,
      0.0431372549, 0.5294117647058824, 0.9647058824, 0.2745098039, 0.0431372549,
      0.5333333333333333, 0.9647058824, 0.2823529412, 0.0392156863, 0.5372549019607843,
      0.9647058824, 0.2901960784, 0.0392156863, 0.5411764705882353, 0.9647058824, 0.2980392157,
      0.0392156863, 0.5450980392156862, 0.9647058824, 0.3058823529, 0.0392156863,
      0.5490196078431373, 0.9647058824, 0.3098039216, 0.0392156863, 0.5529411764705883,
      0.9647058824, 0.3176470588, 0.0392156863, 0.5568627450980392, 0.9647058824, 0.3254901961,
      0.0392156863, 0.5607843137254902, 0.9647058824, 0.3333333333, 0.0392156863,
      0.5647058823529412, 0.9647058824, 0.337254902, 0.0392156863, 0.5686274509803921, 0.968627451,
      0.3450980392, 0.0392156863, 0.5725490196078431, 0.968627451, 0.3529411765, 0.0352941176,
      0.5764705882352941, 0.968627451, 0.3607843137, 0.0352941176, 0.5803921568627451, 0.968627451,
      0.368627451, 0.0352941176, 0.5843137254901961, 0.968627451, 0.3725490196, 0.0352941176,
      0.5882352941176471, 0.968627451, 0.3803921569, 0.0352941176, 0.592156862745098, 0.968627451,
      0.3882352941, 0.0352941176, 0.596078431372549, 0.968627451, 0.3960784314, 0.0352941176, 0.6,
      0.968627451, 0.4, 0.0352941176, 0.6039215686274509, 0.968627451, 0.4078431373, 0.0352941176,
      0.6078431372549019, 0.968627451, 0.4156862745, 0.0352941176, 0.611764705882353, 0.968627451,
      0.4235294118, 0.031372549, 0.615686274509804, 0.9725490196, 0.431372549, 0.031372549,
      0.6196078431372549, 0.9725490196, 0.4352941176, 0.031372549, 0.6235294117647059, 0.9725490196,
      0.4431372549, 0.031372549, 0.6274509803921569, 0.9725490196, 0.4509803922, 0.031372549,
      0.6313725490196078, 0.9725490196, 0.4588235294, 0.031372549, 0.6352941176470588, 0.9725490196,
      0.462745098, 0.031372549, 0.6392156862745098, 0.9725490196, 0.4705882353, 0.031372549,
      0.6431372549019608, 0.9725490196, 0.4784313725, 0.031372549, 0.6470588235294118, 0.9725490196,
      0.4862745098, 0.031372549, 0.6509803921568628, 0.9725490196, 0.4941176471, 0.0274509804,
      0.6549019607843137, 0.9725490196, 0.4980392157, 0.0274509804, 0.6588235294117647,
      0.9725490196, 0.5058823529, 0.0274509804, 0.6627450980392157, 0.9764705882, 0.5137254902,
      0.0274509804, 0.6666666666666666, 0.9764705882, 0.5215686275, 0.0274509804,
      0.6705882352941176, 0.9764705882, 0.5254901961, 0.0274509804, 0.6745098039215687,
      0.9764705882, 0.5333333333, 0.0274509804, 0.6784313725490196, 0.9764705882, 0.5411764706,
      0.0274509804, 0.6823529411764706, 0.9764705882, 0.5490196078, 0.0274509804,
      0.6862745098039216, 0.9764705882, 0.5529411765, 0.0274509804, 0.6901960784313725,
      0.9764705882, 0.5607843137, 0.0235294118, 0.6941176470588235, 0.9764705882, 0.568627451,
      0.0235294118, 0.6980392156862745, 0.9764705882, 0.5764705882, 0.0235294118,
      0.7019607843137254, 0.9764705882, 0.5843137255, 0.0235294118, 0.7058823529411765,
      0.9764705882, 0.5882352941, 0.0235294118, 0.7098039215686275, 0.9764705882, 0.5960784314,
      0.0235294118, 0.7137254901960784, 0.9803921569, 0.6039215686, 0.0235294118,
      0.7176470588235294, 0.9803921569, 0.6117647059, 0.0235294118, 0.7215686274509804,
      0.9803921569, 0.6156862745, 0.0235294118, 0.7254901960784313, 0.9803921569, 0.6235294118,
      0.0235294118, 0.7294117647058823, 0.9803921569, 0.631372549, 0.0196078431, 0.7333333333333333,
      0.9803921569, 0.6392156863, 0.0196078431, 0.7372549019607844, 0.9803921569, 0.6470588235,
      0.0196078431, 0.7411764705882353, 0.9803921569, 0.6509803922, 0.0196078431,
      0.7450980392156863, 0.9803921569, 0.6588235294, 0.0196078431, 0.7490196078431373,
      0.9803921569, 0.6666666667, 0.0196078431, 0.7529411764705882, 0.9803921569, 0.6745098039,
      0.0196078431, 0.7568627450980392, 0.9803921569, 0.6784313725, 0.0196078431,
      0.7607843137254902, 0.9843137255, 0.6862745098, 0.0196078431, 0.7647058823529411,
      0.9843137255, 0.6941176471, 0.0196078431, 0.7686274509803922, 0.9843137255, 0.7019607843,
      0.0156862745, 0.7725490196078432, 0.9843137255, 0.7098039216, 0.0156862745,
      0.7764705882352941, 0.9843137255, 0.7137254902, 0.0156862745, 0.7803921568627451,
      0.9843137255, 0.7215686275, 0.0156862745, 0.7843137254901961, 0.9843137255, 0.7294117647,
      0.0156862745, 0.788235294117647, 0.9843137255, 0.737254902, 0.0156862745, 0.792156862745098,
      0.9843137255, 0.7411764706, 0.0156862745, 0.796078431372549, 0.9843137255, 0.7490196078,
      0.0156862745, 0.8, 0.9843137255, 0.7529411765, 0.0156862745, 0.803921568627451, 0.9843137255,
      0.7607843137, 0.0156862745, 0.807843137254902, 0.9882352941, 0.768627451, 0.0156862745,
      0.8117647058823529, 0.9882352941, 0.768627451, 0.0156862745, 0.8156862745098039, 0.9843137255,
      0.7843137255, 0.0117647059, 0.8196078431372549, 0.9843137255, 0.8, 0.0117647059,
      0.8235294117647058, 0.9843137255, 0.8156862745, 0.0117647059, 0.8274509803921568,
      0.9803921569, 0.831372549, 0.0117647059, 0.8313725490196079, 0.9803921569, 0.8431372549,
      0.0117647059, 0.8352941176470589, 0.9803921569, 0.8588235294, 0.0078431373,
      0.8392156862745098, 0.9803921569, 0.8745098039, 0.0078431373, 0.8431372549019608,
      0.9764705882, 0.8901960784, 0.0078431373, 0.8470588235294118, 0.9764705882, 0.9058823529,
      0.0078431373, 0.8509803921568627, 0.9764705882, 0.9176470588, 0.0078431373,
      0.8549019607843137, 0.9764705882, 0.9333333333, 0.0039215686, 0.8588235294117647,
      0.9725490196, 0.9490196078, 0.0039215686, 0.8627450980392157, 0.9725490196, 0.9647058824,
      0.0039215686, 0.8666666666666667, 0.9725490196, 0.9803921569, 0.0039215686,
      0.8705882352941177, 0.9725490196, 0.9960784314, 0.0039215686, 0.8745098039215686,
      0.9725490196, 0.9960784314, 0.0039215686, 0.8784313725490196, 0.9725490196, 0.9960784314,
      0.0352941176, 0.8823529411764706, 0.9725490196, 0.9960784314, 0.0666666667,
      0.8862745098039215, 0.9725490196, 0.9960784314, 0.0980392157, 0.8901960784313725,
      0.9725490196, 0.9960784314, 0.1294117647, 0.8941176470588236, 0.9725490196, 0.9960784314,
      0.1647058824, 0.8980392156862745, 0.9764705882, 0.9960784314, 0.1960784314,
      0.9019607843137255, 0.9764705882, 0.9960784314, 0.2274509804, 0.9058823529411765,
      0.9764705882, 0.9960784314, 0.2549019608, 0.9098039215686274, 0.9764705882, 0.9960784314,
      0.2901960784, 0.9137254901960784, 0.9764705882, 0.9960784314, 0.3215686275,
      0.9176470588235294, 0.9803921569, 0.9960784314, 0.3529411765, 0.9215686274509803,
      0.9803921569, 0.9960784314, 0.3843137255, 0.9254901960784314, 0.9803921569, 0.9960784314,
      0.4156862745, 0.9294117647058824, 0.9803921569, 0.9960784314, 0.4509803922,
      0.9333333333333333, 0.9803921569, 0.9960784314, 0.4823529412, 0.9372549019607843,
      0.9843137255, 0.9960784314, 0.5137254902, 0.9411764705882354, 0.9843137255, 0.9960784314,
      0.5450980392, 0.9450980392156864, 0.9843137255, 0.9960784314, 0.5803921569,
      0.9490196078431372, 0.9843137255, 0.9960784314, 0.6117647059, 0.9529411764705882,
      0.9843137255, 0.9960784314, 0.6431372549, 0.9568627450980394, 0.9882352941, 0.9960784314,
      0.6745098039, 0.9607843137254903, 0.9882352941, 0.9960784314, 0.7058823529,
      0.9647058823529413, 0.9882352941, 0.9960784314, 0.7411764706, 0.9686274509803922,
      0.9882352941, 0.9960784314, 0.768627451, 0.9725490196078431, 0.9882352941, 0.9960784314, 0.8,
      0.9764705882352941, 0.9921568627, 0.9960784314, 0.831372549, 0.9803921568627451, 0.9921568627,
      0.9960784314, 0.8666666667, 0.984313725490196, 0.9921568627, 0.9960784314, 0.8980392157,
      0.9882352941176471, 0.9921568627, 0.9960784314, 0.9294117647, 0.9921568627450981,
      0.9921568627, 0.9960784314, 0.9607843137, 0.996078431372549, 0.9960784314, 0.9960784314,
      0.9607843137, 1.0, 0.9960784314, 0.9960784314, 0.9607843137,
    ],
    description: 'Siemens',
  },
];

export { colormaps };
