// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-collapse: collapse;
  }
  /* table tr td {
    border: 1px solid #bfccd4;
  } */
  td tr {
     padding: 6.4px;
  }
  th {
     border-collapse: collapse;
     /* border: 1px solid #bfccd4; */
  
  }
  `, "",{"version":3,"sources":["webpack://./../../xylexa/src/Reporting/helpers/template.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;EACA;;KAEG;EAEH;KACG,cAAc;EACjB;EAEA;KACG,yBAAyB;KACzB,+BAA+B;;EAElC","sourcesContent":["table {\n    border-collapse: collapse;\n  }\n  /* table tr td {\n    border: 1px solid #bfccd4;\n  } */\n  \n  td tr {\n     padding: 6.4px;\n  }\n  \n  th {\n     border-collapse: collapse;\n     /* border: 1px solid #bfccd4; */\n  \n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
