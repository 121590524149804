import axios from 'axios';

import { useQuery } from '@tanstack/react-query';
import { API_URL } from './../../constants';
import { patientAiReportQueryKey, patientReportIdsQueryKey } from './queryKeys';

import { useGetRequestOptions } from '../../hooks';
import { useAuthenticationContext } from '../../context';
export type StudyId = string;
export type QueryKey = string;

export const useGetStudyReport = (studyId: StudyId, queryKey: QueryKey) => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: [queryKey, 'patient', 'report'],
    queryFn: async () => await axios.get(`${API_URL}v1/report/?study_id=${studyId}`, options),
  });
};

export const useGetAiReport = (studyId: StudyId) => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientAiReportQueryKey,
    queryFn: async () => await axios.get(`${API_URL}v1/aireport/?study_id=${studyId}`, options),
  });
};

export const useGetReportIds = () => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientReportIdsQueryKey,
    queryFn: async () => await axios.get(`${API_URL}v1/reportIds/`, options),
  });
};
