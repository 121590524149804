// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-in-one-menu-item {
  height: 2rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  line-height: 18px
}

.all-in-one-menu-item-effects {
  cursor: pointer
}

.all-in-one-menu-item-effects:hover {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(15, 18, 21, var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/AllInOneMenu/allInOneMenu.css"],"names":[],"mappings":"AACE;EAAA,YAAkC;EAAlC,WAAkC;EAAlC,oBAAkC;EAAlC,qBAAkC;EAAlC,eAAkC;EAClC,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,yBAAmB;MAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,oBAAc;MAAd,cAAc;EACd;AAJkC;;AAQlC;EAAA;AAAyD;;AAAzD;EAAA,sBAAyD;EAAzD,kBAAyD;EAAzD;AAAyD","sourcesContent":[".all-in-one-menu-item {\n  @apply h-8 px-2 text-[14px] w-full;\n  display: flex;\n  align-items: center;\n  flex-shrink: 0;\n  line-height: 18px;\n}\n\n.all-in-one-menu-item-effects {\n  @apply cursor-pointer hover:bg-primary-dark hover:rounded;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
