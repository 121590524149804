import React, { createContext, useContext, ReactNode, useState } from 'react';
import { UserInfo } from '../api-client';
import secureLocalStorage from 'react-secure-storage';
import {
  AUTH_TOKEN_STORAGE_KEY,
  SERVER_CONFIG_STORAGE_KEY,
  USER_DATA_STORAGE_KEY,
} from '../constants';
import { ServerConfigs } from '../types';

export type SecureLocalStorageContextType = {
  authToken: string | null;
  setAuthToken?: (authToken: string) => void;
  userData: UserInfo | null;
  setUserData?: (userData: UserInfo) => void;
  serverConfigs: ServerConfigs | null;
  setServerConfigs?: (serverConfigs: ServerConfigs) => void;
  clearStorage?: () => void;
};

// Create a context with a default value of undefined
const SecureLocalStorageContext = createContext<SecureLocalStorageContextType>({
  authToken: secureLocalStorage.getItem(AUTH_TOKEN_STORAGE_KEY) as string,
  userData: secureLocalStorage.getItem(USER_DATA_STORAGE_KEY) as UserInfo,
  serverConfigs: secureLocalStorage.getItem(SERVER_CONFIG_STORAGE_KEY) as ServerConfigs,
});

// Create a provider component
export const SecureLocalStorageContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authToken, setAuthToken] = useState<string>(
    secureLocalStorage.getItem(AUTH_TOKEN_STORAGE_KEY) as string
  );

  const handleAuthTokenChange = (authToken: string) => {
    secureLocalStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
    setAuthToken(authToken);
  };

  const [userData, setUserData] = useState<UserInfo>(
    secureLocalStorage.getItem(USER_DATA_STORAGE_KEY) as UserInfo
  );

  const handleUserDataChange = (userData: UserInfo) => {
    secureLocalStorage.setItem(USER_DATA_STORAGE_KEY, userData);
    setUserData(userData);
  };
  const [serverConfigs, setServerConfigs] = useState<ServerConfigs>(
    secureLocalStorage.getItem(SERVER_CONFIG_STORAGE_KEY) as ServerConfigs
  );
  const handleServerConfigsChange = (serverConfigs: ServerConfigs) => {
    secureLocalStorage.setItem(SERVER_CONFIG_STORAGE_KEY, serverConfigs);
    setServerConfigs(serverConfigs);
  };

  const clearStorage = () => {
    secureLocalStorage.clear();
  };

  return (
    <SecureLocalStorageContext.Provider
      value={{
        authToken,
        setAuthToken: handleAuthTokenChange,
        userData,
        setUserData: handleUserDataChange,
        serverConfigs,
        setServerConfigs: handleServerConfigsChange,
        clearStorage,
      }}
    >
      {children}
    </SecureLocalStorageContext.Provider>
  );
};

// Custom hook to use the context
export const useSecureLocalStorageContext = (): SecureLocalStorageContextType => {
  const context = useContext(SecureLocalStorageContext);
  if (!context) {
    throw new Error(
      'useSecureLocalStorageContext must be used within a SecureLocalStorageContextProvider'
    );
  }
  return context;
};
