const getFooter = () => {
  return `<table style="border-collapse: collapse; width: 100%">
    <tbody>
      <tr>
        <td style="width: 17.2414%; vertical-align: top">
          <span style="font-family: Arial, Helvetica, sans-serif"
            ><strong style="font-size: 18px">OPINION:<br /></strong
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="border-collapse: collapse; width: 100%">
    <tbody>
      <tr>
        <td
          style="
            width: 52.5872%;
            vertical-align: top;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
          "
        >
          <ol>
            <li>Opinion</li>
            <li>Opinion<br /></li>
          </ol>
        </td>
      </tr>
    </tbody>
  </table>
  <p><br /></p>
  <table style="border-collapse: collapse; width: 99.8797%">
    <tbody>
      <tr>
        <td style="width: 21.0843%; vertical-align: top">
          <span style="font-family: Arial, Helvetica, sans-serif"
            ><strong style="font-size: 16px">SIGNED BY:</strong></span
          >
        </td>
        <td style="width: 78.9157%; font-family: Arial, Helvetica, sans-serif, font-size: 13px">
          Your Signature
        </td>
      </tr>
    </tbody>
  </table>`;
};

export default getFooter;
