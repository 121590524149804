import { AxiosRequestConfig } from 'axios';
export type AuthToken = string;
export const useGetRequestOptions = (authToken: AuthToken) => {
  const options: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  };
  return options;
};
