import React from 'react';
import { Icon, Tooltip } from '@ohif/ui';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';
import { Button as CustomButton } from '@ohif/ui';
import { useXylexaAppContext } from './../../context';
import { BiPrinter } from 'react-icons/bi';

const Appbar = ({
  handleOpenDialog,
  handleSubmit,
  handleUpdate,
  isAIReportPreview,
  setIsAIReportPreview,
  ViewReportPreview,
  handlePrint,
}) => {
  const { isNewReport, selectedStudy, changeInReportDetected } = useXylexaAppContext();

  return (
    <div className="fixed top-0 z-50 flex h-20 w-full items-center justify-between bg-black px-4 text-white sm:px-0 md:px-4">
      <div
        className={classNames('mr-3 inline-flex cursor-pointer items-center')}
        onClick={handleOpenDialog}
      >
        <Icon
          name="chevron-left"
          className="text-secondary-main w-8"
        />
        <div className="ml-6">
          <Svg
            name="logo-xylexa"
            className="md:w-30 w-36"
          />
        </div>
      </div>
      <div className="flex place-items-center">
        <div className="flex gap-3">
          <CustomButton
            className={selectedStudy?.modalities === 'CT' && ViewReportPreview ? '' : 'hidden'}
            onClick={() => setIsAIReportPreview(!isAIReportPreview)}
          >
            {isAIReportPreview ? 'Generic Report' : 'AI Report'}
          </CustomButton>

          <Tooltip
            content={'Make changes in file to enable update button.'}
            isDisabled={changeInReportDetected}
            position={'bottom-right'}
          >
            <CustomButton
              className={
                !isNewReport && !ViewReportPreview
                  ? ` ${!changeInReportDetected ? 'cursor-not-allowed' : 'cursor-auto'}`
                  : 'hidden'
              }
              onClick={handleUpdate}
              disabled={!changeInReportDetected}
            >
              Update
            </CustomButton>
          </Tooltip>

          <CustomButton
            className={isNewReport && !ViewReportPreview ? '' : 'hidden'}
            onClick={handleSubmit}
          >
            Submit
          </CustomButton>

          <Link to={`/viewer/edit-report/${selectedStudy?.studyInstanceUid}`}>
            <CustomButton className={ViewReportPreview && !isAIReportPreview ? '' : 'hidden'}>
              Edit
            </CustomButton>
          </Link>

          <CustomButton
            className={ViewReportPreview ? '' : 'hidden'}
            onClick={() => handlePrint()}
          >
            <BiPrinter size={'20'} />
            <span>{'Print'}</span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
