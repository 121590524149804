// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
custom overlay panels: top-left, top-right, bottom-left and bottom-right
If any text to be displayed on the overlay is too long to hold on a single
line, it will be truncated with ellipsis in the end.
*/
.viewport-overlay {
  max-width: 40%;
}
.viewport-overlay span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viewport-overlay.left-viewport {
  text-align: left;
}
.viewport-overlay.right-viewport-scrollbar {
  text-align: right;
}
.viewport-overlay.right-viewport-scrollbar .flex.flex-row {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./../../../extensions/cornerstone/src/Viewport/Overlays/CustomizableViewportOverlay.css"],"names":[],"mappings":"AAAA;;;;CAIC;AACD;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;AAEA;EACE,gBAAgB;AAClB;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,qBAAyB;MAAzB,kBAAyB;UAAzB,yBAAyB;AAC3B","sourcesContent":["/*\ncustom overlay panels: top-left, top-right, bottom-left and bottom-right\nIf any text to be displayed on the overlay is too long to hold on a single\nline, it will be truncated with ellipsis in the end.\n*/\n.viewport-overlay {\n  max-width: 40%;\n}\n.viewport-overlay span {\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.viewport-overlay.left-viewport {\n  text-align: left;\n}\n\n.viewport-overlay.right-viewport-scrollbar {\n  text-align: right;\n}\n.viewport-overlay.right-viewport-scrollbar .flex.flex-row {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
