// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dicom-upload-drop-area-border-dash {
  background-image: repeating-linear-gradient(
      to right,
      #7bb2ce 0%,
      #7bb2ce 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(to right, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%),
    repeating-linear-gradient(to bottom, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%),
    repeating-linear-gradient(to bottom, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%);
  background-position:
    left top,
    left bottom,
    left top,
    right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size:
    20px 3px,
    20px 3px,
    3px 20px,
    3px 20px;
}
`, "",{"version":3,"sources":["webpack://./../../../extensions/cornerstone/src/components/DicomUpload/DicomUpload.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;;;oGASkG;EAClG;;;;aAIW;EACX,yDAAyD;EACzD;;;;YAIU;AACZ","sourcesContent":[".dicom-upload-drop-area-border-dash {\n  background-image: repeating-linear-gradient(\n      to right,\n      #7bb2ce 0%,\n      #7bb2ce 50%,\n      transparent 50%,\n      transparent 100%\n    ),\n    repeating-linear-gradient(to right, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%),\n    repeating-linear-gradient(to bottom, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%),\n    repeating-linear-gradient(to bottom, #7bb2ce 0%, #7bb2ce 50%, transparent 50%, transparent 100%);\n  background-position:\n    left top,\n    left bottom,\n    left top,\n    right top;\n  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;\n  background-size:\n    20px 3px,\n    20px 3px,\n    3px 20px,\n    3px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
