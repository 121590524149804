// Key variables used in local storage
export const AUTH_TOKEN_STORAGE_KEY = 'auth-token';
export const USER_DATA_STORAGE_KEY = 'user-data';
export const AUTH_TOkEN_LOCAL_STORAGE_KEY = 'authentication-token';
export const USER_INFO_LOCAL_STORAGE_KEY = 'loggedin-user-info';
export const SERVER_CONFIG_STORAGE_KEY = 'server-configs';
export const CLOUD_SERVER_CONFIG_STORAGE_KEY = 'cloud-server-configs';
export const LOCAL_SERVER_CONFIG_STORAGE_KEY = 'local-server-configs';
export const HAS_SUBMITTED_KEY = 'has-submitted';
export const IS_INSIDE_VIEWER_KEY = 'is-inside-viewer';
export const ORTHANC_TYPE_KEY = 'orthan-type';
export const PATIENT_INFO_KEY = 'patient-info';
export const TYPE_KEY = 'type';
export const REPORT_IDS_KEY = 'report-ids';
export const FILTERED_LIST_KEY = 'filtered-list';
export const FILTER_VALUES_KEY = 'filter-values';
