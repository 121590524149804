import React from 'react';
import { XylexaLoginPage } from './XylexaLoginPage';
import { AuthenticationContextProvider } from '@xylexa/xylexa-app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const XylexaApp = props => {
  return (
    <AuthenticationContextProvider>
      <QueryClientProvider client={queryClient}>
        <XylexaLoginPage
          defaultExtensions={props.defaultExtensions}
          defaultModes={props.defaultModes}
        />
      </QueryClientProvider>
    </AuthenticationContextProvider>
  );
};
