import axios, { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API_URL } from '../../constants';
import { userConfigsQueryKey, userDataQueryKey } from './queryKeys';
import { ServerConfigs } from '../../types';
import { useGetRequestOptions, useSecureLocalStorageContext } from '../../hooks';
export type GetServerConfigsProps = {
  groupId: string | null;
  enabled?: boolean;
};

export type UserInfo = {
  url: string;
  username: string;
  email: string;
  groups: { id: string; name: string; url: string }[];
};

export const useGetUserInfo = (): UseQueryResult<UserInfo> => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: userDataQueryKey,
    queryFn: () => axios.get(`${API_URL}v1/user/`, options).then(res => res.data),
    enabled: !!authToken,
  });
};

export const useGetCloudServerConfigs = ({
  groupId,
  enabled,
}: GetServerConfigsProps): UseQueryResult<AxiosResponse<ServerConfigs>, Error> => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: userConfigsQueryKey,
    queryFn: () =>
      axios.get(`${API_URL}v1/groupprofiles/?group_id=${groupId}&pacs_type=cloud`, options),
    enabled: !!groupId,
  });
};

// export const useGetLocalServerConfigs = ({
//   pacsType,
//   groupId,
// }: GetServerConfigsProps): UseQueryResult<AxiosResponse<ServerConfigs>, Error> => {
//   const a = document.createElement('a');
//   a.href = data['results'][0]['wadoRoot'];
//   const base_url = a.host;
//   const protocol = a.protocol;

//   const requestOptions = {
//     headers: {
//       'Content-Type': 'text/plain',
//       Authorization: 'Basic ' + btoa(data['results'][0].requestOptions.auth),
//     },
//   };

//   return useQuery({
//     queryKey: userConfigsQueryKey,
//     queryFn: () => axios.get(protocol + '//' + base_url + '/tools/now', requestOptions),
//   });
// };
