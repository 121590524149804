import React from 'react';
import App from './App';
import { useSecureLocalStorageContext } from '@xylexa/xylexa-app';
import { Login } from './components/Login';

export const XylexaLoginPage = props => {
  const { serverConfigs } = useSecureLocalStorageContext();
  const config = window.config;
  config.dataSources[0].configuration = serverConfigs;

  return serverConfigs ? (
    <App
      config={config}
      defaultExtensions={props.defaultExtensions}
      defaultModes={props.defaultModes}
    />
  ) : (
    <Login />
  );
};
