import secureLocalStorage from 'react-secure-storage';
import {
  AUTH_TOKEN_STORAGE_KEY,
  USER_DATA_STORAGE_KEY,
  SERVER_CONFIG_STORAGE_KEY,
} from '../constants';
import { useEffect, useState } from 'react';
import { UserInfo } from '../api-client';
import { ServerConfigs } from '../types';

export type SecureLocalStorage = {
  authToken: string | null;
  setAuthToken: (authToken: string) => void;
  userData: UserInfo | null;
  setUserData: (userData: UserInfo) => void;
  serverConfigs: ServerConfigs | null;
  setServerConfigs: (serverConfigs: ServerConfigs) => void;
  clearStorage: () => void;
};

export type AuthToken = string | null;
export type UserData = UserInfo | null;
export type ServerConfig = ServerConfigs | null;

export const useSecureLocalStorage = (): SecureLocalStorage => {
  function getStoredValue<T>(key: string, initialValue: T): T {
    const storedValue = secureLocalStorage.getItem(key);
    if (storedValue) {
      return storedValue as T;
    }
    return initialValue;
  }

  function useDataFromLocalStorage<T extends string | number | boolean | object | null>(
    key: string,
    initialValue: T
  ) {
    const [value, setValue] = useState<T>(() => {
      return getStoredValue<T>(key, initialValue);
    });
    useEffect(() => {
      if (value !== null) {
        secureLocalStorage.setItem(key, value);
      }
    }, [value]);
    return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T>>];
  }

  const [authToken, setAuthToken] = useDataFromLocalStorage<AuthToken>(
    AUTH_TOKEN_STORAGE_KEY,
    null
  );
  const handleSetAuthToken = (newAuthToken: string) => {
    setAuthToken(newAuthToken);
  };

  const [userData, setUserData] = useDataFromLocalStorage<UserData>(USER_DATA_STORAGE_KEY, null);
  const handleSetUserData = (newUserData: UserInfo) => {
    setUserData(newUserData);
  };

  const [serverConfigs, setServerConfigs] = useDataFromLocalStorage<ServerConfig>(
    SERVER_CONFIG_STORAGE_KEY,
    null
  );
  const handleSetServerConfigs = (newServerConfigs: ServerConfigs) => {
    setServerConfigs(newServerConfigs);
  };

  const clearStorage = () => {
    secureLocalStorage.clear();
  };

  return {
    authToken,
    setAuthToken: handleSetAuthToken,
    userData,
    setUserData: handleSetUserData,
    serverConfigs,
    setServerConfigs: handleSetServerConfigs,
    clearStorage,
  };
};
