// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CUSTOM OHIF SCROLLBAR */
.ohif-scrollbar {
  scrollbar-color: #173239 transparent;
  overflow-y: auto;
}
.ohif-scrollbar-stable-gutter {
  scrollbar-gutter: stable;
}
.study-min-height {
  min-height: 450px;
}
.ohif-scrollbar:hover {
  overflow-y: auto;
}
.ohif-scrollbar::-webkit-scrollbar {
  scrollbar-width: thin;
}
.ohif-scrollbar::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}
.ohif-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(4, 28, 74, var(--tw-bg-opacity));
  background-color: #041c4a;
}
.ohif-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 28, 74, var(--tw-bg-opacity));
  background-color: #041c4a;
}
/* INVISIBLE SCROLLBAR */
.invisible-scrollbar {
  scrollbar-width: none;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-track {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/assets/styles/styles.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,oCAAoC;EACpC,gBAAgB;AAClB;AAEA;EACE,wBAAwB;AAC1B;AAEA;EACE,iBAAiB;AACnB;AAEA;EACE,gBAAgB;AAClB;AAEA;EACE,qBAAqB;AACvB;AAGE;EAAA;AAAc;AAId;EAAA,sBAAc;EACd,kBAAwB;EAAxB,uDAAwB;EACxB;AAFc;AAMd;EAAA,kBAAwB;EAAxB,uDAAwB;EACxB;AADwB;AAI1B,wBAAwB;AACxB;EACE,qBAAqB;AACvB;AAGE;EAAA;AAAa;AAIb;EAAA;AAAa;AAIb;EAAA;AAAa;AAIb;EAAA;AAAa","sourcesContent":["/* CUSTOM OHIF SCROLLBAR */\n.ohif-scrollbar {\n  scrollbar-color: #173239 transparent;\n  overflow-y: auto;\n}\n\n.ohif-scrollbar-stable-gutter {\n  scrollbar-gutter: stable;\n}\n\n.study-min-height {\n  min-height: 450px;\n}\n\n.ohif-scrollbar:hover {\n  overflow-y: auto;\n}\n\n.ohif-scrollbar::-webkit-scrollbar {\n  scrollbar-width: thin;\n}\n\n.ohif-scrollbar::-webkit-scrollbar-track {\n  @apply rounded;\n}\n\n.ohif-scrollbar::-webkit-scrollbar-thumb {\n  @apply rounded;\n  @apply bg-secondary-dark;\n  background-color: #041c4a;\n}\n\n.ohif-scrollbar::-webkit-scrollbar-thumb:window-inactive {\n  @apply bg-secondary-dark;\n  background-color: #041c4a;\n}\n\n/* INVISIBLE SCROLLBAR */\n.invisible-scrollbar {\n  scrollbar-width: none;\n}\n\n.invisible-scrollbar::-webkit-scrollbar {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-track {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-thumb {\n  @apply hidden;\n}\n\n.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {\n  @apply hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
