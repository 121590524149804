import axios, { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API_URL } from '../../constants';
import { CloudServerConfigKey, LocalServerConfigKey, userDataQueryKey } from './queryKeys';
import { ServerConfigs } from '../../types';
import { useGetRequestOptions } from '../../hooks';
import { useAuthenticationContext } from '../../context';

export type GetCloudServerConfigsProps = {
  groupId: string | null;
  enabled?: boolean;
};

export type GetLocalServerConfigsProps = {
  groupId: string | null;
  enabled?: boolean;
};

export type UserInfo = {
  url: string;
  username: string;
  email: string;
  groups: { id: string; name: string; url: string }[];
};

export const useGetUserInfo = (): UseQueryResult<UserInfo> => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: userDataQueryKey,
    queryFn: () => axios.get(`${API_URL}v1/user/`, options).then(res => res.data),
    enabled: !!authToken,
  });
};

export const useGetCloudServerConfigs = ({
  groupId,
  enabled = false,
}: GetCloudServerConfigsProps): UseQueryResult<AxiosResponse<ServerConfigs>, Error> => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  const pacs_type = 'cloud';

  return useQuery({
    queryKey: CloudServerConfigKey,
    queryFn: () =>
      axios.get(`${API_URL}v1/groupprofiles/?group_id=${groupId}&pacs_type=${pacs_type}`, options),
    enabled: enabled,
    retry: 0,
  });
};

export const useGetLocalServerConfigs = ({
  groupId,
  enabled = false,
}: GetLocalServerConfigsProps): UseQueryResult<AxiosResponse<ServerConfigs>, Error> => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  const pacs_type = 'local';

  return useQuery({
    queryKey: LocalServerConfigKey,
    queryFn: () =>
      axios.get(`${API_URL}v1/groupprofiles/?group_id=${groupId}&pacs_type=${pacs_type}`, options),
    enabled: enabled,
    retry: 0,
  });
};
