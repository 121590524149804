import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useSecureLocalStorageContext } from '../../hooks';
import { StudyId } from '../queries';
import { useGetRequestOptions } from '../../hooks';
export type ReportBody = {
  study_id: string;
  patient_id: string;
  patient_name: string;
  description: string | undefined;
};

export type ReportBodyForPatch = {
  description: string | undefined;
};

export const useSubmitStudyReport = () => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useMutation({
    mutationFn: (reportBody: ReportBody) => axios.post('v1/report/', reportBody, options),
  });
};

export const useUpdateStudyReport = () => {
  const { authToken } = useSecureLocalStorageContext();
  const options = useGetRequestOptions(authToken);
  return useMutation({
    mutationFn: ({ studyId, reportBody }: { studyId: StudyId; reportBody: ReportBodyForPatch }) =>
      axios.patch(`v1/report/?study_id=${studyId}`, reportBody, options),
  });
};
